import React, { useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Header from './Header'
import Drawer from './Drawer'
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { Route, Redirect, Switch } from 'react-router-dom'

import { loadDates, loadNotificationCounter, loadNotificationList, addNotificationList, loadStands } from '../../app/store/actions/persistActions';
import { LocationCity, Message, Today, Contacts, School, LocalMall, EvStation } from '@material-ui/icons';
import VoiceChatIcon from '@material-ui/icons/VoiceChat';
import BookIcon from '@material-ui/icons/Book';

/*************change lang ***************/
import MessageLang from '../../lang/';
//import ConferenceComponent from '../../views/conference/ConferenceComponent';
import NotFound from '../../views/common/NotFound';
/*************change lang ***************/
import Echo from 'laravel-echo';
import io from 'socket.io-client';
import agent from '../../app/api/agent'
import { setExhibition, setExhibitions } from '../../app/store/actions/exhibitionAction';

const ExpoComponent = React.lazy(() => import('../../views/expo/expo'));
const ConferenceComponent = React.lazy(() => import('../../views/conference/ConferenceComponent'));
const EventDashboard = React.lazy(() => import('../../views/events/eventDashboard/EventDashboard'));
const StandDashboard = React.lazy(() => import('../../views/stands/dashboard/StandDashboard'));
const MeetingDashboard = React.lazy(() => import('../../views/meeting/meetingDashboard/MeetingDashboard'));
const ChatList = React.lazy(() => import('../../views/chat/ChatList'));
const ProductList = React.lazy(() => import('../../views/product/dashboard/ProductList'));
const ProductDetails = React.lazy(() => import('../../views/product/details/productDetails'));
const Profile = React.lazy(() => import('../../views/profile/Profile'));
const StandDetail = React.lazy(() => import('../../views/stands/details/StandDetails'));
const StandInfo = React.lazy(() => import('../../views/stands/info/StandInfo'));
const OpportunityDashboard = React.lazy(() => import('../../views/opportunity/dashboard/OpportunityDashboard'));



const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTypography-displayBlock': {
            fontSize: '14px'
        },
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 56,//50,
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: 56,//50,
        },
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
        marginTop: 0
    },
    icon: {
        color: '#475974'
    },
    mask: {
        color: '#021d1d'

    },
    Img: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        marginRight: 8,
        backgroundColor: '#fff',
        [theme.breakpoints.up('sm')]: {
            marginRight: 16,
        },
    },
}));


const DashboardLayout = ({ match, history, location }) => {
    const classes = useStyles();
    const ScreenWidth = window.innerWidth
    // const { drawer } = useSelector((state) => state.async);
    const Responsive_Size = 600;
    const DrawerState = ScreenWidth >= Responsive_Size ? true : false
    const exhibition = useSelector(state => state.exhibition.data);
    const exhibitionTitle = exhibition.title ? exhibition.title : "";
    const { details } = useSelector((state) => state.pavilion);
    const [open, setOpen] = React.useState(DrawerState); //true
    const dispatch = useDispatch();

    const handleDrawerOpen = () => {
        // dispatch(ManageDrawer(true))
        setOpen(true);
    };

    const handleDrawerClose = () => {
        // dispatch(ManageDrawer(false))
        setOpen(false);
    };

    let headerTitle = "";
    let headerIcon = ""
    if ((location.pathname).includes('product')) {
        headerTitle = <MessageLang id="DashboardLayout.header.products" />
        headerIcon = <LocalMall className={classes.icon} />;

    } else if ((location.pathname).includes('meeting')) {
        headerTitle = <MessageLang id="DashboardLayout.header.meetings" />
        headerIcon = <VoiceChatIcon className={classes.icon} />;
    }
    else if ((location.pathname).includes('hall')) {
        headerTitle = <MessageLang id="DashboardLayout.header.exhibition" />
        headerIcon = <LocationCity className={classes.icon} />;
    }
    else if ((location.pathname).includes('events')) {
        headerTitle = <MessageLang id="DashboardLayout.header.events2" />
        headerIcon = <Today className={classes.icon} />;
    }
    else if ((location.pathname).includes('chat')) {
        headerTitle = <MessageLang id="DashboardLayout.header.conversations" />
        headerIcon = <Message className={classes.icon} />;
    }
    else if ((location.pathname).includes('stand')) {
        headerTitle = <MessageLang id="DashboardLayout.header.booths" />
        headerIcon = <Contacts className={classes.icon} />;
    }
    else if ((location.pathname).includes('exhibition')) {
        headerTitle = <>
            <MessageLang id="DashboardLayout.header.booths" /> - <> {details && details.name}</>
            {/* {details && details.media && details.media.logo && <img alt={''} className={classes.Img} src={details.media.logo} />} */}
        </>;
        headerIcon = <Contacts className={classes.icon} />;
    }
    else if ((location.pathname).includes('conference')) {
        headerTitle = <MessageLang id="DashboardLayout.header.conference_hall" />;
        headerIcon = <School className={classes.icon} />;
    }
    else if ((location.pathname).includes('account')) {
        headerTitle = <MessageLang id="DashboardLayout.header.user_page" />;
    }
    else if ((location.pathname).includes('jobopps')) {
        headerTitle = <MessageLang id="DashboardLayout.header.jobs" />;
        headerIcon = <BookIcon className={classes.icon} />;
    }
    else if ((location.pathname).includes('investmentopps')) {
        headerTitle = <MessageLang id="DashboardLayout.header.investment" />;
        headerIcon = <EvStation className={classes.icon} />;
    }
    else if ((location.pathname).includes('home')) {
        if (ScreenWidth <= Responsive_Size) {
            headerTitle = exhibitionTitle.length > 30 ? exhibitionTitle.substring(0, 30) + "..." : exhibitionTitle;
        } else {
            headerTitle = exhibitionTitle;
        }
        headerIcon = <LocationCity className={classes.icon} />;
    }


    const { counter, list } = useSelector(state => state.persist.notification);
    const { token, currentUser } = useSelector(state => state.auth);
    let { list: exlist } = useSelector(state => state.exhibition);

    useEffect(async () => {
        await (async () => {
            const { data } = await agent.Landing.list()
            if (data.status) {
                dispatch(setExhibitions(data.result.data))
                exlist = data.result.data
            }
        })()

        if (exlist.length > 0) {
            let currentEX = exlist.find(ex => ex.short_name === match.params.exhibition)
            dispatch(setExhibition(currentEX))
        }
        /****Notification ******/
        if (token) {
            dispatch(loadNotificationCounter())
            dispatch(loadNotificationList())
        }
        /**end**Notification ******/

        dispatch(loadDates())
    }, [])

    useEffect(() => {
        if (currentUser !== null) {
            let echo = new Echo({
                broadcaster: 'socket.io',
                client: io,
                host: "https://expo.namaline.ir",
                path: "/listener",
                transports: ['websocket'],
                auth: {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: 'application/json',
                    },
                },
            });
            const channelB = echo.private(`App.User.${currentUser?.id}`)
            channelB.notification((response) => {
                const data = {
                    avatar: response.avatar,
                    created_at: response.created_at,
                    message: response.message,
                    seen: false
                }
                dispatch(addNotificationList(data))
            })
            return () => {
                echo.leave(`App.User.${currentUser?.id}`);
            };
        }
    }, [currentUser])

    const accentColor = "#5cb7b7";

    // if (exhibition?.short_name && exhibition.short_name !== match.params.exhibition) {
    //     return <Redirect to={{
    //         pathname: '/auth/login',
    //         state: { from: match.params.exhibition }
    //     }} />
    // }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Route
                path={`/${exhibition.short_name}/(.+)`}
                render={() => <>
                    <Route path={`${match.path}`}
                        component={() =>
                            <Header
                                short_name={exhibition.short_name}
                                location={location.pathname}
                                open={open}
                                onOpenChange={handleDrawerOpen}
                                title={headerTitle}
                                headerIcon={headerIcon}
                                notification={{ list, counter }}
                                route={match.params.exhibition}
                            />} />
                </>} />
            <Drawer short_name={exhibition.short_name} route={match.params.exhibition} open={open} onOpenChange={handleDrawerClose}
                notification={{ meetings: counter.new_meetings, messages: counter.new_messages }}
            />
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content} id='mainpage'>
                        <Route
                            path={`/${exhibition.short_name}/(.+)`}
                            render={() => (
                                <Suspense fallback={<></>}>
                                    <Switch>
                                        <PrivateRoute path={`${match.path}/home`} exact shortname={match.params.exhibition} component={ExpoComponent} />
                                        {/* <PrivateRoute path={`${match.path}/home`} exact shortname={match.params.exhibition}  component={Showexhibitions} /> */}
                                        <PrivateRoute path={`${match.path}/conference`} exact shortname={match.params.exhibition} component={ConferenceComponent} />
                                        <PrivateRoute path={`${match.path}/events`} exact shortname={match.params.exhibition} component={EventDashboard} />
                                        <PrivateRoute path={`${match.path}/stand`} exact shortname={match.params.exhibition} component={StandDashboard} />
                                        <PrivateRoute path={`${match.path}/meeting`} exact shortname={match.params.exhibition} component={MeetingDashboard} />
                                        <PrivateRoute path={`${match.path}/chat`} exact shortname={match.params.exhibition} component={ChatList} />
                                        <PrivateRoute path={`${match.path}/chat/:id`} exact shortname={match.params.exhibition} component={ChatList} />
                                        <PrivateRoute path={`${match.path}/product`} exact shortname={match.params.exhibition} component={ProductList} />
                                        <PrivateRoute path={`${match.path}/product/:id`} exact shortname={match.params.exhibition} component={ProductDetails} />
                                        <PrivateRoute path={`${match.path}/profile/:id`} exact shortname={match.params.exhibition} component={Profile} />
                                        <PrivateRoute path={`${match.path}/exhibition/:id`} exact shortname={match.params.exhibition} component={StandDetail} />
                                        <PrivateRoute path={`${match.path}/exhibition/info/:id`} exact shortname={match.params.exhibition} component={StandInfo} />
                                        <PrivateRoute path={`${match.path}/jobopps`} exact shortname={match.params.exhibition} component={OpportunityDashboard} />
                                        <PrivateRoute path={`${match.path}/investmentopps`} exact shortname={match.params.exhibition} component={OpportunityDashboard} />
                                        <Route component={NotFound} />
                                    </Switch>
                                </Suspense>
                            )} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const PrivateRoute = ({ shortname: ShortName, component: Component, ...rest }) => {
    // const { authenticated } = useSelector((state) => state.auth);
    return (
        <Route
            {...rest}
            render={(props) => ShortName ? <Component {...props} /> :
                <Redirect to={{
                    pathname: '/',
                    state: { from: ShortName }
                }} />}
        />
    )
}

export default React.memo(DashboardLayout)
