import {
    FETCH_PAVILON_DETAILS,
    FETCH_PAVILON_MEMBERS,
    FETCH_PAVILON_COMMENTS,
    FETCH_PAVILON_PRODUCTS
} from '../constants/Constants';

const initialState = {
    details: {},
    statistic: {},
    outlook: {},
    members: [],
    products: [],
    comments: [],
    media: []
};

export default function pavilionReducer(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_PAVILON_DETAILS:
            switch (payload.type) {
                case 'about':
                    return {
                        ...state,
                        details: payload.details,
                        loading: false
                    }
                case 'outlook':
                    return {
                        ...state,
                        outlook: payload.details,
                        loading: false
                    }
                case 'statistics':
                    return {
                        ...state,
                        statistic: payload.details,
                        loading: false
                    }
                case 'media':
                    return {
                        ...state,
                        media: payload.details,
                        loading: false
                    }
                default:
                    return state
            }
        case FETCH_PAVILON_MEMBERS:
            return {
                ...state,
                members: payload,
            };
        case FETCH_PAVILON_PRODUCTS:
            return {
                ...state,
                products: payload,
            };
        case FETCH_PAVILON_COMMENTS:
            return {
                ...state,
                comments: payload,
                loadingComments: false
            };

        default:
            return state;
    }
}