import React from "react";
const Send = ({ color}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            width="9"
            height="9"
            enableBackground="new 0 0 320 320"
            version="1.1"
            viewBox="0 0 320 320"
            xmlSpace="preserve"
        >
            <path d="M106.667 0L106.667 42.667 247.147 42.667 0 289.813 30.187 320 277.333 72.853 277.333 213.333 320 213.333 320 0z"></path>
        </svg>
    );
}

export default Send;