import React from 'react';
import Page from '../../component/Page.jsx';
import {
    Box,
    Container,
    Grid,
    Hidden,
    Button,
    Typography
} from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import RegisterStepOne from './RegisterStepOne'
import RegisterStepTwo from './RegisterStepTwo'
import RegisterStepThree from './RegisterStepThree'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useStyles } from './_auth.js'
import { useDispatch, useSelector } from 'react-redux'

/*************change lang ***************/
import MessageLang from '../../lang/';
/*************change lang ***************/

const Register = ({ match, location }) => {
    const classes = useStyles()
    const { lang } = useSelector((state) => state.lang);

    const dispatch = useDispatch()
    return (
        <Page className={classes.root} title="register" >
            <Grid className={classes.authContainer} >
                <Hidden only={['sm', 'xs']}>
                    <Grid item lg={7} md={7} className={'auth-panel'}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            height="100%"
                            justifyContent="center"
                        >
                            <Container maxWidth="md">
                                <Box textAlign="center">
                                    <img
                                        alt="Register"
                                        className={classes.image}
                                        src="/static/images/backgrounds/SVG_Auth/register.svg"
                                    />
                                </Box>
                                <Typography
                                    align="center"
                                    color="textPrimary"
                                    variant="h1"
                                    className={'title'}
                                >
                                    <MessageLang id="login.panel.head.title" />
                                </Typography>
                                <Typography
                                    align="center"
                                    color="textPrimary"
                                    variant="subtitle2"
                                    className={'description'}
                                >
                                    <Typography>
                                        <MessageLang id="register.panel.head.text" />
                                    </Typography>
                                    <Typography>
                                        <MessageLang id="login.panel.head.welcome" />...
                                    </Typography>
                                </Typography>
                            </Container>
                        </Box>
                    </Grid>
                </Hidden>
                <Grid item lg={5} md={5} xs={12} className={classes.LoginPanel} >
                    <TransitionGroup className="transition-group">
                        <CSSTransition
                            key={location.key}
                            // timeout={{ enter: 900, exit: 900 }}
                            enterAnimation="bounceIn"
                            enterDuration="200ms"
                            enterTimingFunction="ease"
                            exitAnimation="fadeOut"
                            exitDuration="150ms"
                            exitTimingFunction="linear"
                            classNames={'fade'}
                        >
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                <Container className='authbox'>
                                    <section className={classes.routeSection}>
                                        <Route
                                            path={'/(.+)'}
                                            render={() => (
                                                <Switch location={location}>
                                                    <Route path={match.path} exact component={RegisterStepOne} />
                                                    <Route path={`${match.path}/verification`} component={RegisterStepTwo} />
                                                    <Route path={`${match.path}/password`} component={RegisterStepThree} />
                                                </Switch>
                                            )} />
                                    </section>
                                </Container>
                            </Box>
                        </CSSTransition>
                    </TransitionGroup>
                </Grid>
            </Grid >
        </Page >
    )
}

export default Register
