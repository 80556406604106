import React from 'react';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import { messageLang } from '../../lang'

const SelectInput = ({
    options,
    name,
    label,
    onBlur,
    onChange,
    clearValue,
    setValue,
    isClearable,
    isSearchable,
    isDisabled,
    isLoading,
    value }) => {
    const { lang } = useSelector((state) => state.lang);
    return (
        <FormControl variant="outlined" className={'select-input'}>
            <label className="select-input__label">{label}</label>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={lang === "fa" || lang === "ar"}
                isSearchable={true}
                name={name}
                onChange={onChange}
                clearValue={clearValue}
                setValue={setValue}
                options={options}
                placeholder={messageLang('form.label.placeholder.select')}
                value={value}
            />
        </FormControl>
    )
}

export default SelectInput
