import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {Box,TextField,CircularProgress} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './_auth.js'
import { verificateCodeFp } from '../../app/store/actions/authActions.js';
import {Dots,Button} from '../../component'
/*************change lang ***************/
import MessageLang from '../../lang/';
/*************change lang ***************/

const Code = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const currentUser = useSelector((state) => state.auth.guestUser);
    const { loading } = useSelector((state) => state.async);

    function handleKeyUp(e) {
        const value = e.target.value
        const name = e.target.name
        const [fieldName, fieldIndex] = name.split("_");
        if (parseInt(fieldIndex, 10) < 7) {
            // Get the next input field
            const nextSibling = document.querySelector(
                `input[name=ssn_${parseInt(fieldIndex, 10) + 1}]`
            );

            if (nextSibling !== null) {
                nextSibling.focus();
            }
        }
    };

    return (
        <Formik
            initialValues={
                {
                    ssn_1: '', ssn_2: '', ssn_3: '',
                    ssn_4: '', ssn_5: '', ssn_6: '',
                }}
            validationSchema={Yup.object({
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                    await dispatch(verificateCodeFp(values))
                    setSubmitting(false);
                } catch (error) {
                    setErrors({ auth: <MessageLang id="form.verify.register.auth"/> });
                    setSubmitting(false);
                }
            }}
        >
            {({ submitForm,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values, isValid, dirty }) => (
                <Form className='ui form'>
                    <Box mb={3}>
                        <div className="auth__subtitle" >
                        <MessageLang id="form.label.register.code_one"/>
                            {" "} {currentUser && currentUser.phone_number} {" "}
                            <MessageLang id="form.label.register.code_two"/>
                                    </div>
                    </Box>
                    <div my={2}>
                        {errors.auth && <span className={'error-alert'}>{errors.auth}</span>}
                        <div id="wrapper" className="auth__content-step-2">
                            <div id="dialog">
                                <div id="form" className={classes.formBox}>
                                    <input
                                        component={TextField}
                                        onKeyUp={(e) => handleKeyUp(e)}
                                        type="tel"
                                        name="ssn_1"
                                        variant="outlined"
                                        className={`auth__input-code ${classes.codeInput}`}
                                        maxLength="1"
                                        size="1"
                                        min="0"
                                        max="9"
                                        pattern="[0-9]{1}"
                                        value={values.ssn_1}
                                        onChange={handleChange('ssn_1')}
                                        placeholder="*"
                                    />
                                    <input
                                        component={TextField}
                                        onKeyUp={(e) => handleKeyUp(e)}
                                        onChange={handleChange('ssn_2')}
                                        type="tel"
                                        name="ssn_2"
                                        className={`auth__input-code ${classes.codeInput}`}
                                        maxLength="1"
                                        size="1"
                                        min="0"
                                        max="9"
                                        pattern="[0-9]{1}"
                                        value={values.ssn_2}
                                        placeholder="*"
                                    />
                                    <input
                                        component={TextField}
                                        onKeyUp={(e) => handleKeyUp(e)}
                                        onChange={handleChange('ssn_3')}
                                        type="tel"
                                        name="ssn_3"
                                        className={`auth__input-code ${classes.codeInput}`}
                                        maxLength="1"
                                        size="1"
                                        min="0"
                                        max="9"
                                        pattern="[0-9]{1}"
                                        value={values.ssn_3}
                                        placeholder="*"
                                    />
                                    <input
                                        component={TextField}
                                        onKeyUp={(e) => handleKeyUp(e)}
                                        onChange={handleChange('ssn_4')}
                                        type="tel"
                                        name="ssn_4"
                                        className={`auth__input-code ${classes.codeInput}`}
                                        maxLength="1"
                                        size="1"
                                        min="0"
                                        max="9"
                                        pattern="[0-9]{1}"
                                        value={values.ssn_4}
                                        placeholder="*"
                                    />
                                </div>
                            </div>
                        </div>
                        <Box my={2}>
                            <Button
                                fullWidth
                                size="large"
                                variant="contained"
                                onClick={submitForm}
                                disabled={loading}
                                label={loading ? <CircularProgress style={{ color: '#fff' }} size={26} /> :<MessageLang id="form.label.forgetpass.btn_verify_code"/>}
                            />
                        </Box>
                    </div>
                    <Dots stepTwo />
                </Form>
            )}
        </Formik>
    );
};

export default Code;
