import {
    ASYNC_ACTION_START,
    ASYNC_ACTION_FINISH,
    ASYNC_ACTION_ERROR,
    MANAGE_MODAL
} from '../constants/Constants'

export function asyncActionStart() {
    return {
        type: ASYNC_ACTION_START,
    };
}

export function asyncActionFinish() {
    return {
        type: ASYNC_ACTION_FINISH,
    };
}

export function asyncActionError(error) {
    console.log(error);
    return {
        type: ASYNC_ACTION_ERROR,
        payload: error,
    };
}

export const ManageModal = (open) => {
    return async function (dispatch) {
        dispatch({
            type: MANAGE_MODAL,
            payload: open
        });
    };
}