import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const SelectSort= ({
    name,
    label,
    onChange,
    isDisabled,
    checked,
    }) => {
    return (
        <FormControl variant="outlined" className={'select-input'}>
            <label className="select-input__label">{label}</label>
            <Switch name={name} checked={checked} disabled={isDisabled} onChange={onChange} />
            {/* <FormControlLabel
                labelPlacement="start"
                control={<Switch size="small" name={name} checked={checked} disabled={isDisabled} onChange={onChange} />}
                label={label}
            /> */}
        </FormControl>
    )
}

export default SelectSort
