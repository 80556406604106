import {
    makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiInputBase-input': {
            backgroundColor: '#fff'
        },
        '& .fade-enter': {
            opacity: 0.01
        }, '& .fade-enter.fade-enter-active': {
            opacity: 1,
            transition: 'opacity 400ms ease-in'
        },
        '& .fade-exit': {
            opacity: 1,
        },
        '& .fade-exit.fade-exit-active': {
            opacity: 0.01,
            transition: 'opacity 400ms ease -in'
        },
        '& label.Mui-focused': {
            color: 'blue',
        },
        '& label.MuiFormLabel-root': {
            fontFamily: 'IRANSansFa',
        },
        '& .MuiOutlinedInput-adornedEnd': {
            backgroundColor: '#fff'
        },
        '& .MuiInputLabel-outlined': {
            fontFamily: 'IRANSansFa'
        },
        '& .MuiInput-underline:after': {

        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
            },
            '&:hover fieldset': {
            },
            '&.Mui-focused fieldset': {
            },
        },
        backgroundColor: '#9F9F9F',
        height: '100%',
        textAlign: 'center',
        padding: 0,
        overflow: 'hidden'
    },
    image: {
        display: 'inline-block',
        maxWidth: '65%',
        paddingBottom: '30px',
        width: 700
    },
    labelText: {
        fontSize: '30px',
    },
    authContainer: {
        margin: '0',
        height: '100%',
        display: 'flex',
        direction : theme.direction ==="ltr" ? "rtl" : "ltr",
        backgroundColor: '#fafafa'
    },
    LoginPanel: {
        height: '100%',
        // borderRight: '1px solid rgba(0, 0, 0, 0.12);',
        display: 'grid',
        direction : 'ltr',
        [theme.breakpoints.down('sm')]: {
            padding: '0 35px',
            overflowY:'scroll',
        },
    },
     routeSection: {
        [theme.breakpoints.up('sm')]: {
            width: '65%',
            margin: '0 auto'
        }
    },
    codeInput: {
        margin: '0 5px',
        textAlign: 'center',
        lineHeight: '32px',
        fontSize: '20px',
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        boxShadow: '0 0 1px #ccc inset',
        outline: 'none',
        width: '10%',
        transition: 'all 0.2s ease -in -out',
        borderRadius: '5px'
    },
    formBox: {
        direction: theme.direction,
    }
}));
