const ASYNC_ACTION_START = 'ASYNC_ACTION_START';
const ASYNC_ACTION_FINISH = 'ASYNC_ACTION_FINISH';
const ASYNC_ACTION_ERROR = 'ASYNC_ACTION_ERROR';
const MANAGE_MODAL = 'MANAGE_MODAL';
export const APP_LOADED = 'APP_LOADED';


const initialState = {
    loading: false,
    error: null,
    type: null,
    initialized: false,
    modal: {
        state: false,
        type: ''
    }
};

export default function asyncReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ASYNC_ACTION_START:
            return {
                ...state,
                loading: true,
                error: null,
                type: payload
            };
        case ASYNC_ACTION_FINISH:
            return {
                ...state,
                loading: false,
                type: null
            };
        case ASYNC_ACTION_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
                type: null
            };
        case APP_LOADED:
            return {
                ...state,
                initialized: true,
            };
        case MANAGE_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    state: payload.state,
                    type: payload.type,
                }
            }
        default:
            return state;
    }
}
