import React from 'react';
import { Link as RouterLink} from 'react-router-dom';
import { withRouter } from "react-router";

import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Button
} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';



const useStyles = makeStyles(({
  root: {},
  appBar: {
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: 'none',
    color: '#000'
  },
  toolbar: {
    height: 56,
    display : 'flex',
    justifyContent : "space-between",
  },
}));

const Header = ({match,location,history}) => {
  const classes = useStyles();
  const theme = useTheme();
  const handelRedirect = ()=>{
    if (history.length > 1) history.goBack()
    else history.push({ pathname: `/` })
  }
  return (
    <AppBar
      position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          {theme.direction === 'rtl' ? <img alt="Namaline" src="/static/images/logo/logo_full.svg" className='Logo_auth_img_Drawer'/> : <img alt="Namaline" src="/static/images/logo/logo_full_en.svg" className='Logo_auth_img_Drawer'/>}
          {/* <Logo /> */}
        </RouterLink>
        <IconButton onClick={handelRedirect}>
          {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Header);
