import React from 'react';
import faMessages from './locales/fa_IR';
import enMessages from './locales/en_US';
import arMessages from './locales/ar'
import ruMessages from './locales/ru'
import { useSelector } from 'react-redux';
import { store } from '../index'
const AppLocale = {
    fa: faMessages,
    en: enMessages,
    ar: arMessages,
    ru: ruMessages
};

const MessageLang = ({ id }) => {
    const { lang } = useSelector((state) => state.lang)
    return <>{AppLocale[lang][id]}</>
}

export const messageLang = (id) => {
    const { lang } = store.getState().lang;
    return AppLocale[lang][id]
}

export default MessageLang;
