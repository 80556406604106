import { combineReducers } from 'redux'
import testReducer from '../../../views/testarea/testReducer'
import asyncReducer from './asyncReducer'
import authReducer from './authReducer'
import { connectRouter } from 'connected-react-router'
import eventReducer from './eventReducer';
import chatReducer from './chatReducer';
import meetingReducer from './meetingReducer'
import standReducer from './standReducer'
import { persistReducer, migrations } from './persistReducer'
import productReducer from './productReducer'
import pavilionReducer from './pavilionReducer'
import opportunitiesReducer from './opportunitiesReducer'
import exhibitionReducer from './exhibitionReducer'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { persist } from './persist'
import { createMigrate } from "redux-persist";
import modalReducer from './modalReducer'

/***********lang **********/
import changeLanguage from './changelanguage'
/***********lang **********/



const MIGRATION_DEBUG = false;

// const persistConfig = {
//     key: 'root',
//     version: 1,
//     whitelist: ['navigation'],
//     migrate: createMigrate([migrations], { debug: MIGRATION_DEBUG })
// }

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    event: eventReducer,
    stand: standReducer,
    auth: authReducer,
    async: asyncReducer,
    test: testReducer,
    chat: chatReducer,
    meeting: meetingReducer,
    product: productReducer,
    pavilion: pavilionReducer,
    persist: persistReducer,
    opportunities: opportunitiesReducer,
    toastr: toastrReducer,
    lang : changeLanguage,
    exhibition : exhibitionReducer
})

export default rootReducer