import React from "react";
const Recieve = ({ color}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            width="9"
            height="9"
            enableBackground="new 0 0 320 320"
            version="1.1"
            viewBox="0 0 320 320"
            xmlSpace="preserve"
        >
            <path d="M320 30.187L289.813 0 42.667 247.147 42.667 106.667 0 106.667 0 320 213.333 320 213.333 277.333 72.853 277.333z"></path>
        </svg>
    );
}

export default Recieve;