import React from 'react'
import {
    Box,
    Container,
    Typography
} from '@material-ui/core';
import MessageLang from '../../lang/';

const NotFound = () => {
    return (
        <Container maxWidth="md" className={'layout_container'} height={'100%'}>
            <Box textAlign="center">
                <img
                    alt="Register"
                    className={'not_found_img'}
                    src="/static/images/backgrounds/SVG/404.svg"
                />
            </Box>
            <Typography
                align="center"
                className={'not_found_title'}
            >
                <MessageLang id="Common.notFound.title" />
            </Typography>
        </Container>
    )
}

export default NotFound
