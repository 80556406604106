import axios from 'axios';
// import { configureStore } from '../store/configureStore';
import { toast } from 'react-toastify';
import { store } from '../../index';
// import { history } from '../store/configureStore';

axios.defaults.baseURL = 'https://app.namaline.ir/api';

axios.interceptors.request.use(
    config => {
        const reduxStr = store;
        const token = window.localStorage.getItem('jwt');
        if (token) config.headers.Authorization = `Bearer ${token}`;
        config.headers.lang = reduxStr.getState().lang.lang;
        if (undefined != reduxStr.getState().exhibition.data) config.headers['exhibition-id'] = reduxStr.getState().exhibition.data.id
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(undefined, error => {
    if (error) return console.error('@namaline error', error);
    if (!error) return console.error('@namaline noerror', error);
})
/*axios.interceptors.response.use(undefined, error => {
    if (!error) return Promise.reject(error);
    if (error.message === 'Network Error' && !error.response) {
        toast.error('Network error - make sure API is running!')
    }
    if(error.response){
        const { status, data, config } = error.response;

        if (status) {
            if (status === 404) {
                // history.push('/notfound')
            }
            if (status === 400 && config.method === 'get' && data.errors.hasOwnProperty('id')) {
                // history.push('/notfound')
            }
            if (status === 500) {
                toast.error('Server error - check the terminal for more info!')
            }
            if (status === 401) {
                // console.log(JSON.parse((JSON.parse(window.localStorage.getItem('persist:yldasmt')).exhibition)).data.id)
                // if(){

                // }
                // else{}
              //  history.push('/') //conflict in multiple logins
            }
        }
    }


    throw error.response;
})*/

const responseBody = (response) => response?.data;

const sleep = (ms) => (response) =>
    new Promise(resolve => setTimeout(() => resolve(response), ms));

const requests = {

    get: (url, params) => axios.get(url, params).then(sleep(0)).then(responseBody),
    post: (url, body) => axios.post(url, body).then(sleep(0)).then(responseBody),
    put: (url, body) => axios.put(url, body).then(sleep(0)).then(responseBody),
    del: (url) => axios.delete(url).then(sleep(0)).then(responseBody),
    postForm: (url, file) => {
        let formData = new FormData();
        formData.append('image', file);
        return axios
            .post(url, formData, {
                headers: { 'Content-type': 'multipart/form-data' }
            })
            .then(responseBody);
    }
};

const Activities = {
    list: () => requests.get(`/activities`),
    details: (id) => requests.get(`/activities/${id}`),
    create: (activity) => requests.post('/activities', activity),
    update: (activity) => requests.put(`/activities/${activity.id}`, activity),
    delete: (id) => requests.del(`/activities/${id}`),
    attend: (id) => requests.post(`/activities/${id}/attend`, {}),
    unattend: (id) => requests.del(`/activities/${id}/attend`)
}

const Events = {
    list: (params) => axios.get('/events/list', { params: params }).then(responseBody),
    alert: (eventId) => requests.post('/events/alert', { event_id: eventId }),
    dates: () => requests.post('/exhibitions/date_range', {})
}

const Stands = {
    companyList: (params) => axios.get('/pavilions/list', { params: params }).then(responseBody),
    memberList: (params) => axios.get('/exhibitions/members', { params: params }).then(responseBody),
    details: (id) => requests.get(`/pavilions/members/detail/${id}`),
}

const Products = {
    list: (params) => axios.get('/products/list', { params: params }).then(responseBody),
    details: (id) => requests.get(`/products/detail/${id}`),
    Comments: {
        list: (id) => requests.post('/products/comments', { product_id: id }),
        add: (id, comment, point) => requests.post('/products/comments/action/add', { product_id: id, comment: comment, point: point }),
        // point: (id, point) => requests.post('products/point', { product_id: id, point: point })
    }
}


const Companies = {
    list: () => requests.get('/companies/list')
}

const User = {
    current: () => requests.get(`/auth`),
    login: (user) => requests.post(`/auth/login`, user),
    register: (user) => requests.post(`/auth/register`, user),
    requestCode: (phone) => requests.post(`/auth/code/send`, phone),
    checkPhone: (phone) => requests.post(`/auth/register/check_username`, phone),
    verificateCode: (user) => requests.post(`/auth/code/check`, user),
    forgetPassword: (user) => requests.post(`/auth/forgot/password`, user),
}

const Profile = {
    info: () => requests.get('/user/profile'),
    updateInfo: (user) => requests.post('/user/update/profile', user),
    updateExtra: (user) => requests.post('/user/update/basic', user),
    updatePassword: (user) => requests.post('/user/update/password', user),
    uploadAvatar: (pic) => requests.postForm('/user/update/avatar', pic),
    changepassword: (user) => requests.post('/user/update/password', user),
}
const Notification = {
    counter: () => requests.get('/user/signboards/counter'),
    signboardsLast: () => requests.get(`/user/signboards/last10`),
    update: () => requests.get(`/user/signboards/update`),
}

const Chats = {
    list: () => requests.get('/chat/conversations'),
    create: (audience_id) => requests.post('/chat/conversations/create', audience_id),
    messagesList: (id, params) => requests.get(`/chat/messages/${id}`, { params: params }),
    createMessage: (message, conversation_id) => requests.post(`/chat/messages/${conversation_id}/create`, { message: message }),
}

const Meetings = {
    list: () => requests.get('/meetings/list'),
    todaylist: () => requests.get('/meetings/list?today=1'),
    details: (id) => requests.get(`/meetings/detail/${id}`),
    changeStatus: (status, id) => requests.post(`/meetings/status`, { status: status, meeting_id: id }),
    changeTime: (datetime, meeting_id) => requests.post(`/meetings/change`, { datetime: datetime, meeting_id: meeting_id }),
    ranges: () => requests.get('/exhibitions/date_range'),
    create: (data) => requests.post('/meetings/create', data),
    checkcalender: (data) => requests.post('/calendars/check', data)
}

const Filters = {
    categories: () => requests.get('/exhibitions/categories'),
    subCategories: (categoryId) => requests.post('/exhibitions/tags', { category_id: categoryId }),
    countries: () => requests.get('/general/address/countries'),
    companies: () => requests.get('/pavilions/companies'),
    positions: () => requests.get('/pavilions/members/titles'),
    interests: () => requests.get('/general/interests'),
    abilities: () => requests.get('general/expertises'),
    offers: () => requests.get('general/offers')
}

const Expo = {
    pavilions: () => requests.get('/exhibitions/expo'),
    goBack: () => requests.get('/exhibitions/shift'),
}

const Calenders = {
    list: (params) => axios.get('/calendars/list', { params: params }).then(responseBody),
    lockDate: (datetime) => requests.post('/calendars/block', { datetime: datetime }),
}

const Pavilions = {
    products: (filters) => requests.post(`pavilions/products`, filters),
    details: (id, pavilion_id, section) => requests.post(`/pavilions/detail/${id}`, { pavilion_id: pavilion_id, section: section }),
    members: (pavilion_id) => requests.post(`/pavilions/members`, { pavilion_id: pavilion_id }),
    comments: (pavilion_id) => requests.post(`/pavilions/comments`, { pavilion_id: pavilion_id }),
    sendComments: (pavilion_id, comment) => requests.post(`/pavilions/comments/action/add`, { pavilion_id: pavilion_id, comment: comment }),
}

const Opportunities = {
    allOpportunities: (params) => axios.get('/pavilions/opportunities/all', { params: params }).then(responseBody),
    MyOpportunities: (pavilion_id, type) => requests.post('/pavilions/opportunities/my', { type: type, pavilion_id: pavilion_id }),
    // create: (opportunity_id, description) => requests.post('/pavilions/opportunities/request', { opportunity_id: opportunity_id, description: description }),
    create: (formData) => requests.post('/pavilions/opportunities/request', formData),
    delete: (opportunity_id) => requests.post('/pavilions/opportunities/request/cancel', { request_id: opportunity_id }),
    // pavilionOpportunities: (pavilion_id, type) => requests.post('/pavilions/opportunities', { type: type, pavilion_id: pavilion_id }),
    pavilionOpportunities: (params) => axios.get('/pavilions/opportunities', { params: params }).then(responseBody),
}

const Landing = {
    list: () => axios.get('/exhibitions/list'),
}

const TourGuide = {
    set: (params) => axios.get('/user/tourGuide', { params: params }).then(responseBody),
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Events,
    Stands,
    Activities,
    User,
    Notification,
    Companies,
    Chats,
    Meetings,
    Products,
    Filters,
    Calenders,
    Pavilions,
    Opportunities,
    Profile,
    Expo,
    Landing,
    TourGuide
}