import React, { useState } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom'
import { createStyles, makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Box, Button, Typography } from '@material-ui/core';
import { Contacts, LocationCity, Today, School, LocalMall, EvStation } from '@material-ui/icons';
import BookIcon from '@material-ui/icons/Book';
import { useDispatch, useSelector } from 'react-redux';
import ReactFlagsSelect from 'react-flags-select';
// Modal
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import LoginOverlay from '../../component/LoginOverlay/LoginOverlay';
import Fade from '@material-ui/core/Fade';
import { useHistory } from "react-router-dom";
// Modal
/*************change lang ***************/
import MessageLang from '../../lang/';
import { changeLanguage } from '../../app/store/actions/changelanguage.js';
import { setExhibitions } from '../../app/store/actions/exhibitionAction';
import agent from '../../app/api/agent'
/*************change lang ***************/

const Transition = React.forwardRef(function Transition(props, ref) {
    // return <Slide direction="up" ref={ref} {...props} />;
    return <Fade in={true} timeout={1000} ref={ref} {...props} />;
});

const drawerWidth = 342;
const drawerWidth_xs = 320;
const drawerWidth1 = 1;

const theme = makeStyles({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});
const styles = theme => ({
    root: {
        width: "100%",
        maxWidth: 360,

    },

    item: {
        padding: 0
    }
});

const StyledListItemIcon = withStyles({
    root: {
        color: '#fff',
        minWidth: '40px',
        '& .MuiTab-wrapper': {
            color: 'white'
        }
    },
})(ListItemIcon);

const getCode = (lang) => {
    if (lang === "fa") {
        return "IR";
    }
    else if (lang === "en") {
        return "US";
    }
    else if (lang === "ar") {
        return "AE";
    }
    else if (lang === "ru") {
        return "RU";
    }
}

const getLang = (code) => {
    if (code === "IR") {
        return "fa";
    }
    else if (code === "US") {
        return "en";
    }
    else if (code === "AE") {
        return "ar";
    }
    else if (code === "RU") {
        return "ru";
    }
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
                backgroundColor: theme.palette.primary.blue,

            },
            '& .MuiSvgIcon-root': {
                color: 'white'
            },
            display: 'flex',

        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            // marginRight: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            // width: drawerWidth,
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
            },
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            // width: drawerWidth,
            [theme.breakpoints.up('xs')]: {
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
            },
            zIndex: 9999,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            backgroundColor: theme.palette.primary.main,
            color: '#fff',

        },
        drawerClose: {
            [theme.breakpoints.up('xs')]: {
                width: drawerWidth1,
            },
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
            },
            // [theme.breakpoints.up('lg')]: {
            //     width: drawerWidth_xs,
            // },
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(0) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(7) + 1,
            },
            backgroundColor: theme.palette.primary.main,
            color: '#fff'
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            minHeight: '56px'
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    }),
);


const MiniDrawer = ({ open, onOpenChange, notification, route, short_name }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { lang } = useSelector((state) => state.lang);
    const historyPush = useHistory()
    var conference = false;
    const [openDialog, setOpenDialog] = useState(false);
    const [routeName, setRouteName] = useState('');
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const setDialogConfig = (routeName) => {
        if(authenticated){
            historyPush.push(`/${route}/${routeName}`)
        } else {
            setOpenDialog(true);
            setRouteName(routeName);
        }
    }
    const handleListItemClick = (event, index, url) => {
        if (window.innerWidth < 960) {
            onOpenChange()
        }
    };
    const handleChange = async (code) => {
        const selectedLng = getLang(code);
        if (lang && selectedLng && lang === selectedLng) {
            return;
        }
        updateAfterLng(selectedLng);
    };
    async function updateAfterLng(lang) {
        await dispatch(changeLanguage(lang))
        updatedata();
    }
    async function updatedata() {
        const { data } = await agent.Landing.list()
        if (data.status) {
            await dispatch(setExhibitions(data.result.data))
            window.location.reload();
        }

    }
    const { authenticated } = useSelector((state) => state.auth)

    return (
        <Drawer
            variant="permanent"
            className={`${clsx(classes.drawer, classes.root, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })} dashboard-drawer`}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar}>
                {short_name === 'wevex' ? <img alt="Wevex" src="/static/images/logo/wevex.png" className='Logo_img_Drawer' /> :
                    (theme.direction === 'rtl' ?
                        <img alt="Namaline" src="/static/images/logo/logo.svg" className='Logo_img_Drawer' /> :
                        <img alt="Namaline" src="/static/images/logo/logo_en.svg" className='Logo_img_Drawer' />)
                }
                <IconButton onClick={() => onOpenChange()} style={{ color: '#fff' }}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </div>
            <Divider />
            <List className="sidebar-list" component="nav" aria-label="main mailbox folders">
                <ListItem button className="maindrawer_title" onClick={handleListItemClick}>
                    <NavLink to={`/${route}/home`} >
                        <StyledListItemIcon>
                            <LocationCity />
                        </StyledListItemIcon>
                        <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
                            <ListItemText primary={<MessageLang id="DashboardLayout.header.exhibition" />} />
                        </Box>

                    </NavLink>
                </ListItem>
                <ListItem button className="maindrawer_title" onClick={handleListItemClick}>
                    <NavLink to={`/${route}/conference`}>
                        <StyledListItemIcon>
                            <School />
                        </StyledListItemIcon>
                        <Box className='maindrawer_title__row'>
                            <ListItemText primary={<MessageLang id="DashboardLayout.header.conference_hall" />} />
                            {conference ? <img src={'/static/dostupnost.gif'} className='conferencealert' /> : null}
                        </Box>
                    </NavLink>
                </ListItem>
                <ListItem button className="maindrawer_title" onClick={handleListItemClick}>
                    <NavLink to={`/${route}/investmentopps`}>
                        <StyledListItemIcon>
                            <EvStation />
                        </StyledListItemIcon>
                        <ListItemText primary={<MessageLang id="DashboardLayout.header.investment.opportunities" />} />
                    </NavLink>
                </ListItem>
                <ListItem button className="maindrawer_title" onClick={handleListItemClick}>
                    <NavLink to={`/${route}/jobopps`}>
                        <StyledListItemIcon>
                            <BookIcon />
                        </StyledListItemIcon>
                        <ListItemText primary={<MessageLang id="DashboardLayout.header.job.opportunities" />} />
                    </NavLink>
                </ListItem>
                <ListItem button className="maindrawer_title" onClick={handleListItemClick}>
                    <NavLink to={`/${route}/events`}>
                        <StyledListItemIcon>
                            <Today />
                        </StyledListItemIcon>
                        <ListItemText primary={<MessageLang id="DashboardLayout.header.events" />} />
                    </NavLink>
                </ListItem>
                <ListItem button className="maindrawer_title" onClick={handleListItemClick}>
                    <NavLink to={`/${route}/stand`}>
                        <StyledListItemIcon>
                            <Contacts />
                        </StyledListItemIcon>
                        <ListItemText primary={<MessageLang id="DashboardLayout.header.booths" />} />
                    </NavLink>
                </ListItem>
                <ListItem button className="maindrawer_title" onClick={handleListItemClick}>
                    <NavLink to={`/${route}/product`}>
                        <StyledListItemIcon>
                            <LocalMall />
                        </StyledListItemIcon>
                        <ListItemText primary={<MessageLang id="DashboardLayout.header.products" />} />
                    </NavLink>
                </ListItem>
            </List>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialog}
                className={'video-modal'}
            >
                <MuiDialogTitle disableTypography className='modal-title'>
                    <Typography variant="h6">{ }</Typography>
                    <IconButton aria-label="close" onClick={handleCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle >
                <DialogContent>
                    <LoginOverlay routeNameFromDrawers={routeName} closeDialog={() => setOpenDialog(false)}/>
                </DialogContent>
            </Dialog>
            {open ?
                <Box className="lang">
                    <Box className='changelang'>
                        {/* <Button
                            className={`${lang === "fa" && "active"}`}
                            onClick={() => dispatch(changeLanguage("fa"))}
                        >فارسی</Button>
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="span"
                        >|</Typography>
                        <Button
                            className={`${lang === "en" && "active"}`}
                            onClick={() => dispatch(changeLanguage("en"))}
                        >English</Button> */}

                        <ReactFlagsSelect
                            selected={getCode(lang)}
                            onSelect={code => { handleChange(code) }}
                            countries={["IR", "US", "AE", "RU"]}
                            customLabels={{ "IR": "FA-IR", "US": "EN-US", "AE": "AE", "RU": "RU" }}
                            placeholder={<MessageLang id="general.select.lang" />}
                            className="menu-lang"
                            optionsSize={14} />
                    </Box>
                    <Typography onClick={() => window.location.replace('https://namaline.ir')} className='namalinelink'>Powered By: Namaline</Typography>
                </Box>
                :
                lang === "fa" ?
                    <Box my={1} width={1} className="lang">
                        <Button
                            fullWidth
                            onClick={() => updateAfterLng("en")}
                        >En</Button>
                    </Box>
                    :
                    <Box my={1} width={1} className="lang">
                        <Button
                            fullWidth
                            onClick={() => updateAfterLng("fa")}
                        >فا</Button>
                    </Box>
            }
        </Drawer>
    );
}

export default MiniDrawer
