import React, { useEffect, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme'
import GlobalStyles from './app/styles/styles'
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { history } from './app/store/configureStore'
// import GlobalFonts from './assets/fonts/font'
import { Route, Switch } from 'react-router-dom'
import DashboardLayout from './layouts/DashboardLayout/DashboardLayout'
import MainLayout from './layouts/MainLayout/MainLayout';
import './styles/App.scss'
import './styles/jquery-jvectormap.css'
import ReduxToastr from 'react-redux-toastr'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import NotFound from './views/common/NotFound';
// Configure JSS
const Landing = React.lazy(() => import('./views/Landing/Landing'));
const Faq = React.lazy(() => import('./views/FAQ/'));
const Terms = React.lazy(() => import('./views/terms/'));
const ContactUs = React.lazy(() => import('./views/contactUs/'));
const AboutUs = React.lazy(() => import('./views/aboutUs'));

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./sw.js', { scop: '/' }).then(regestreation => {
    console.log('service worker is regestreation!🎉' + regestreation);
  })
}

function RTL(props) {
  const { lang } = useSelector((state) => state.lang);
  const jss = create({ plugins: [...jssPreset().plugins, rtl({ enabled: lang === "fa" || lang === "ar" })] });
  return (
    <StylesProvider jss={jss}>
      {props.children}
    </StylesProvider>
  );
}

const App = () => {
  const reduxData = useSelector((state) => ({
    lang: state.lang.lang,
    auth: state.auth.authenticated
  }));
  const { lang, auth } = reduxData;
  useEffect(() => {
    document.body.dir = (lang === "fa" || lang === "ar") ? "rtl" : "ltr";
  }, [lang])

  return (
    <ConnectedRouter history={history}>
      <RTL>
        <ThemeProvider theme={{ ...theme, direction: ((lang === "fa" || lang === "ar") ? 'rtl' : 'ltr') }}>
          <ReduxToastr
            timeOut={7000}
            position='bottom-right'
            transitionIn='fadeIn'
            transitionOut='fadeOut'
            progressBar
            closeOnToastrClick={false}
          />
          <GlobalStyles />
          <Suspense fallback={null}>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/contactUs" component={ContactUs} />
              <Route exact path="/aboutUs" component={AboutUs} />
              <Route path="/auth" component={MainLayout} />
              <Route path="/:exhibition" component={DashboardLayout} />
              <Route exact path="/404" component={NotFound} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </ThemeProvider>
      </RTL>
    </ConnectedRouter>
  );
};

export default React.memo(App);
