// ASYNC CONSTANTS
export const ASYNC_ACTION_START = 'ASYNC_ACTION_START';
export const ASYNC_ACTION_FINISH = 'ASYNC_ACTION_FINISH';
export const ASYNC_ACTION_ERROR = 'ASYNC_ACTION_ERROR';
export const APP_LOADED = 'APP_LOADED';

// RANGE CONSTANTS
export const FETCH_RANGE = 'FETCH_RANGE'

// PERSIST CONSTANTS
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_COMPANIES = 'SET_COMPANIES'
export const SET_ABILITIES = 'SET_ABILITIES'
export const SET_POSITIONS = 'SET_POSITIONS'
export const SET_INTERESTS = 'SET_INTERESTS'
export const SET_DATES = 'SET_DATES'
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const SET_OFFERS = 'SET_OFFERS'
export const SET_EXPOS = 'SET_EXPOS'
export const SET_STANDS = 'SET_STANDS'

// AUTH CONSTANTS
export const SIGN_IN_USER = 'SIGN_IN_USER';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const REGISTER_STEP_ONE = 'REGISTER_STEP_ONE';
export const REGISTER_STEP_TWO = 'REGISTER_STEP_TWO';
export const REGISTER_STEP_THREE = 'REGISTER_STEP_THREE';
export const SET_TOKEN = 'SET_TOKEN';
export const MANAGE_MODAL = 'MANAGE_MODAL';


// EVENT CONSTANTS
export const SET_LECTURES = 'SET_LECTURES';
export const SET_WORKSHOPS = 'SET_WORKSHOPS';
export const RETAIN_STATE = 'RETAIN_STATE';
export const FETCH_MEETINGS = 'FETCH_MEETINGS';
export const FETCH_SELECTED_MEETING = 'FETCH_SELECTED_MEETING';
export const FETCH_TODAY_MEETINGS = 'FETCH_TODAY_MEETINGS';
export const FETCH_CALENDERS = 'FETCH_CALENDERS';
export const FETCH_EVENT_DATES = 'FETCH_EVENT_DATES'
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';
export const CLEAR_SELECTED_EVENT = 'CLEAR_SELECTED_EVENT';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const SET_FILTER = 'SET_FILTER';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_LECTURE_ALERT = 'SET_LECTURE_ALERT'
export const SET_WORKSHOP_ALERT = 'SET_WORKSHOP_ALERT'


// CHAT CONSTANTS
export const FETCH_CONVERSATION = 'FETCH_CONVERSATION';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';
export const UPDATE_CONVERSATIONS = 'UPDATE_CONVERSATIONS';
export const LOADING_MESSAGE = 'LOADING_MESSAGE';


// STAND CONSTANTS
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_MEMBERS = 'FETCH_MEMBERS';
export const FETCH_MEMBER_DETAILS = 'FETCH_MEMBER_DETAILS';
export const SET_COMPANY_FILTER = 'SET_COMPANY_FILTER';
export const SET_MEMBER_FILTER = 'SET_MEMBER_FILTER';
export const CLEAR_COMPANY_FILTER = 'CLEAR_COMPANY_FILTER'
export const CLEAR_MEMBER_FILTER = 'CLEAR_MEMBER_FILTER'
export const MEMBER_DETAIL_LOADING = 'MEMBER_DETAIL_LOADING'
export const SETTING_MODAL = 'SETTING_MODAL'


// PRODUCT CONSTANTS
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const CLEAR_PRODUCT_COMMENTS = 'CLEAR_PRODUCT_COMMENTS';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const SET_PRODUCT_FILTER = 'SET_PRODUCT_FILTER';
export const CLEAR_PRODUCT_FILTER = 'CLEAR_PRODUCT_FILTER';
export const CLEAR_SELECTED_PRODUCT = 'CLEAR_SELECTED_PRODUCT';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT'
export const SET_SELECTED_PRODUCT_COMMENTS = 'SET_SELECTED_PRODUCT_COMMENTS'
export const ADD_PRODUCT = 'ADD_PRODUCT'


// PAVILON CONSTANTS
export const FETCH_PAVILON_DETAILS = 'FETCH_PAVILON_DETAILS';
export const FETCH_PAVILON_MEMBERS = 'FETCH_PAVILON_MEMBERS';
export const FETCH_PAVILON_COMMENTS = 'FETCH_PAVILON_COMMENTS'
export const FETCH_PAVILON_PRODUCTS = 'FETCH_PAVILON_PRODUCTS'

// OPPORTUNITIES CONSTANTS
export const FETCH_ALL_OPPORTUNITIES = 'FETCH_ALL_OPPORTUNITIES';
export const FETCH_MY_OPPORTUNITIES = 'FETCH_MY_OPPORTUNITIES';
export const FETCH_PAVILION_OPPORTUNITIES = 'FETCH_PAVILION_OPPORTUNITIES';
export const SET_LOADING = 'SET_LOADING';
export const CLEAR_OPPORTUNITIES_FILTER = 'CLEAR_OPPORTUNITIES_FILTER';
export const SET_OPPORTUNITY_FILTER = 'SET_OPPORTUNITY_FILTER';

// PROFILE CONSTANTS
export const SET_PROFILE_INFO = 'SET_PROFILE_INFO'
export const SET_AVATAR = 'SET_AVATAR'

// PROFILE CHANGElanguage

export const SET_LANGUAGE_TEXT = 'SET_LANGUAGE_TEXT'

export const SET_NotificationCounter = 'SET_NotificationCounter'
export const SET_NotificationList = 'SET_NotificationList'
export const SET_AddNotificationList = 'SET_AddNotificationList'


// SET EXHIBITION CONSTANTS
export const SET_EXHIBITION = 'SET_EXHIBITION'
export const SET_EXHIBITIONS = 'SET_EXHIBITIONS'

// TOUR GUIDE CONSTANTS
export const SET_TOUR_GUIDE = 'SET_TOUR_GUIDE'

