////////// general //////////////////

////////// company //////////////////

//////////form //////////////////
//////////label //////////////////
//////////verify //////////////////

////////// login //////////////////

////////// register //////////////////


export default {

  ////////// Генеральная /////////////////

  "general.copyright": "Gogo React © 2018 Все права защищены.",



  ///////// / компания /////////////////

  "company.name": "имя",



  ////////// форма /////////////////



  ////////// метка /////////////////

  "form.label.login.withPhone": "Телефонный номер",

  "form.label.login.codenumber": "Код страны",

  "form.label.login.phone": "Телефонный номер",

  "form.label.login.password": "Пароль",

  "form.label.login.login": "Войти в namaline",

  "form.label.login.forgetpass": "Забыли пароль",

  "form.label.login.signup": "Новый пользователь? Зарегистрироваться",

  "form.label.register.name": "Имя",

  "form.label.register.family": "Фамилия",

  "form.label.register.company": "Название компании",

  "form.label.register.btn_signup": "Зарегистрироваться",

  "form.label.register.btn_verify_phone": "Подтвердить номер мобильного телефона",

  "form.label.register.code_one": "Код отправлен",

  "form.label.register.code_two": "Введите сюда",

  "form.label.register.trycode": "Отправить код повторно",

  "form.label.register.stepThreePassword": "Введите пароль",

  "form.label.register.lastpassword": "Текущий пароль",

  "form.label.register.password": "Новый пароль",

  "form.label.register.password_replay": "Повторите новый пароль",

  "form.label.register.terms": "Я прочитал",

  "form.label.register.terms2": "я принимаю",

  "form.label.forgetpass.btn": "Отправить код",

  "form.label.forgetpass.btn_verify_code": "Подтвердить код",

  "form.label.placeholder.enterText": "Напишите здесь свое сообщение",

  "form.label.placeholder.enterMeetingText": "Описание изобретения",

  "form.label.placeholder.search": "Искать...",

  "form.label.placeholder.select": "Выбрать...",



  "form.pagenavigation": "Строк на странице",

  "notification.head": "Уведомления",



  ////////// проверять /////////////////

  "form.verify.login.phone": "Введите свой контактный номер",

  "form.verify.login.password": "Введите пароль",

  "form.verify.login.two_password": "Повторите свой пароль",

  "form.verify.login. authpassword": "Повторить неверный пароль",

  "form.verify.login.auth": "Неверный номер мобильного телефона или адрес электронной почты.",

  "form.verify.register.name": "Введите свое имя",

  "form.verify.register.lastname": "Введите свою фамилию",

  "form.verify.register.phone": "Введите номер мобильного телефона",

  "form.verify.register.code": "Введите код страны",

  "form.verify.register.email": "Введите адрес электронной почты",

  "form.verify.register.auth": "Неверный код! Повторите попытку",

  "form.verify.register.authpassword": "Пароль не соответствует второму полю",

  "form.verify.register.authStepThree": "При регистрации возникла проблема, попробуйте еще раз",

  "form.verify.register.email-valid": "Введенный адрес электронной почты недействителен",



  ////////// авторизоваться /////////////////

  "login.message": 'Уважаемый, добро пожаловать в Намалаин ',

  "login.panel.head.title": "Множество возможностей для посетителей!",

  "login.panel.head.text": "Смотрите новейшие продукты, посещайте специализированные конференции, устраивайте видеоконференции, присоединяйтесь к групповым обсуждениям и наслаждайтесь пространством кабины.",

  "login.panel.head.welcome": "Добро пожаловать в Намалин",



  ////////// регистр /////////////////

  "register.panel.head.title": "Представьтесь всем!",

  "register.panel.head.text": "Смотрите новейшие продукты, посещайте специализированные конференции, устраивайте видеоконференции, присоединяйтесь к групповым обсуждениям и наслаждайтесь пространством стенда.",

  "register.panel.head.form_head": "Зарегистрируйтесь менее чем за минуту",

  "register.panel.btn.loginHall": "Зайти на выставку",



  ///////// забыть ////////////////

  "Forgotpass.panel.head.title": "Забыли пароль?",

  "Forgotpass.panel.head.subtext1": "Введите номер мобильного телефона, на который вы зарегистрировались!",

  "Forgotpass.panel.head.subtext2": "Введите адрес электронной почты, на который вы зарегистрировались!",

  "Forgotpass.sendcode.alert": "Новый код успешно отправлен.",



  //////// DashboardLayout ////////////////

  // заголовок

  "DashboardLayout.header.logout.confirm": 'Вы хотите выйти?',

  "DashboardLayout.header.logout.agree": `Да, я ухожу`,

  "DashboardLayout.header.logout.disagree": 'Нет',

  "DashboardLayout.header.logout.alert": "Вы успешно вышли из системы",



  "DashboardLayout.header.products": "Продукты и услуги",

  "DashboardLayout.header.meetings": "Встречи",

  "DashboardLayout.header.exhibition": "Выставка",

  "DashboardLayout.header.products": "Продукты и услуги",

  "DashboardLayout.header.events": "События",

  "DashboardLayout.header.events2": "Список выставок",

  "DashboardLayout.header.events2.btn": "Подробнее",

  "DashboardLayout.header.conversations": "Беседы",

  "DashboardLayout.header.booths": "Кабинки",

  "DashboardLayout.header.conference_hall": "Конференц-зал",

  "DashboardLayout.header.user_page": "Страница пользователя",

  "DashboardLayout.header.pearl_petrochemical": "Pearl Petrochemical",

  "DashboardLayout.header.jobs": "Вакансии",

  "DashboardLayout.header.profile": "Профиль",

  "DashboardLayout.header.logout": "Выйти",
  "DashboardLayout.header.investment.opportunities": "инвестиционные возможности",
  "DashboardLayout.header.job.opportunities": "Предложения работы",

  //////// События ////////////////

  // EventDashboard

  "events.EventDashboard.tabnav.lectures": "Лекции",

  "events.EventDashboard.tabnav.workshops": "Мастерские",

  // LectureList

  "events.LectureList.nothing.lectures": "В настоящее время выступлений нет",

  "events.LectureList.table.clock": "Часы",

  "events.LectureList.table.speaker": "Спикер",

  "events.LectureList.table.topic": "Тема",

  // WorkshopList

  "events.LectureList.nothing.WorkshopList": "В настоящее время семинаров нет",

  "events.LectureList.table.teacher": "Учитель",

  //////// стенды /////////////////

  // StandDashboard

  "stands.StandDashboard.text.Companies": "Компании",

  "stands.StandDashboard.text.people": "Экспонент",

  "stands.StandDashboard.Modal.invitation": "Приглашение на встречу",

  "stands.StandDashboard.Modal.invitation_sub": "После отправки приглашения вы должны дождаться утверждения собрания другой стороной",

  "stands.StandDashboard.add-meeting.setting": "Настройка Meeting",

  "stands.StandDashboard.add-meeting.conversation": "Начать разговор",

  "stands.StandDashboard.filter.company": "Фильтрация между выставочными компаниями",

  "stands.StandDashboard.filter.parts": "Фильтр между участниками выставки",

  // CompanyList

  "stands.CompanyList.Table.company": "Профиль компании",

  "stands.CompanyList.Table.activity": "Сфера деятельности",

  "stands.CompanyList.Table.country": "Country",

  "stands.CompanyList.Table.count": "Количество сотрудников",

  "stands.CompanyList.TableCell.people": "Люди",

  "stands.CompanyList.TableCell.rate": "Рейтинг посетителей",

  // MemberFilter

  "stands.MemberFilter.form.company": "Название фирмы",

  "stands.MemberFilter.form.position": "Положение",

  "stands.MemberFilter.form.country": "Country",

  "stands.MemberFilter.form.ability": "Способность",

  "stands.MemberFilter.form.interest": "Интерес",

  "stands.MemberFilter.form.family": "Фамилия",

  "stands.MemberFilter.form.spec": "Технические характеристики",

  "stands.MemberFilter.form.categories": "Категории",

  //стоять

  "Stand.getfile": "Загрузка файла",

  //подробности

  "stands.details.StandDetails. morebtn": "Еще",

  "stands.details.phonenumber-title": "Контактные телефоны",

  // Outlook

  "stands.details.Outlook.title": "Перспектива кабины",

  "stands.details.Outlook.hall": "Зал",

  "stands.details.Outlook.opportunity": "Возможность",

  "stands.details.Outlook.member": "Участник",

  "stands.details.Outlook.product": "Продукт",
  "stands.details.Tab.1": "Бут",
  "stands.details.Tab.2": "Участники",
  "stands.details.Tab.3": "Новости",
  //Статистика

  "stands.details.Statistic.title": "Посетители",

  "stands.details.Statistic.visit": "Посетить",

  "stands.details.Statistic.score": "Оценка",

  "stands.details.Statistic.conversation": "Разговор",

  "stands.details.Statistic.meeting": "Встреча",

  // В сети

  "stands.details.Online.title": "экспоненты",

  // StandDetails

  "stands.details.StandDetails.introduction": "Введение",

  "stands.details.StandDetails.comments": "Комментарии",

  "stands.details.StandDetails.people": "Экспоненты",

  "stands.details.StandDetails.products": "Товары и услуги",

  "stands.details.StandDetails.conversations": "Беседы",

  "stands.details.StandDetails.cooperations": "Сотрудничество",

  //Информация

  "stands.info.introduction.call.tooltip": "Щелкните для просмотра",

  "stands.info.introduction.call": "Позвонить",

  "stands.info.introduction.site": "Интернет сайт",

  "stands.info.introduction.instagram": "Instagram",

  "stands.info.introduction.linkedin": "LinkedIn",

  "stands.info.comment.nothings1": "В настоящее время диалога нет!",

  "stands.info.comment.nothings2": "Вы начинаете разговор",

  "stands.info.comment.placeholder.enterText": "Чтобы присоединиться к беседе, напишите здесь свой комментарий",

  "stands.info.comment.placeholder.enterText-minsize": "Присоединяйтесь к беседе",

  "stands.info.products.nothings": "На данный момент товаров нет!",

  "stands.info.cooperation.nothings": "В настоящее время сотрудничества нет!",

  "stands.info.cooperation.SpecificationsUser": "Профиль сотрудничества",

  "stands.info.cooperation.SpecificationsColleague": "Вид сотрудничества",

  //продукт

  "product.productList.filter.products": "Фильтрация между выставочными продуктами",

  "product.productList.page": "Страница",

  "product.productList.page.of": "Из",

  "product.productList.infoprice": "",

  "product.detailedcomments.vote.title": "Также оцените этот товар",

  "product.detailedcomments.vote.subtitle": "Ваш рейтинг и мнение могут увидеть все",

  "product.detailedcomments.alert.nothing": "Комментариев пока нет!",

  "product.filter.alphabetical": "По алфавиту :",

  "product.filter.mostvisited": "Сортировать по наивысшему баллу:",

  "product.comment.sendmsg": "Ваша оценка и комментарии успешно отправлены!",



  //Чат

  "chat.chatHeader.setVideoMeeting": "Установить видеовстречу",

  "chat.sendBox.placeholder": "Напишите здесь свое сообщение",

  "chat.chattitle_subtitle.lastVisit": "Последний визит",

  //Выставка

  "exhibition.coments.title": "Общественный Диалог",

  "exhibition.comments.subtitle": "Это место , чтобы поговорить об этой компании Присоединяйтесь к обсуждению сейчас",

  "exhibition.comments.subtitle-малым": "Присоединяйтесь к общению сейчас",

  "exhibition.comments.vote.comment1": "На основании голосов",

  //"exhibition.comments.vote.comment2":"Проголосовать",

  "exhibition.comments.toastr": "Ваше сообщение было успешно отправлено",

  "exhibition.coopeationItem.opportunities": "Возможности работы",

  "exhibition.coopeationItem.opportunitiestwo": "Инвестиционная возможность",

  "exhibition.coopeationItem.create": "Чтобы отправить предложение, напишите здесь описание",

  "exhibition.cooperationItem.loading": "Вы отправили заявку на сотрудничество",

  "exhibition.cooperationItem.loadingtwo": "Оставить заявку на сотрудничество",

  "exhibition.cooperationItem.loadingThere": "Вы хотите отменить это предложение?",

  "exhibition.cooperationItem.loadingYes": "Да, отменяю",

  "exhibition.cooperationItem.loadingNo": "Нет",

  "exhibition.cooperationItem.tehrann": "Тегеран",

  "exhibition.exhibitionlist.title": "Список выставок",

  "exhibition.exhibition.tab1": "Введение",

  "exhibition.exhibition.tab2": "Пользователи",

  "exhibition.exhibition.tab3": "Продукты",

  "exhibition.exhibition.tab4": "Диалоги",

  "exhibition.exhibition.tab5": "Кооперация",

  "exhibition.exhibitionlist.title": "Список выставок",

  "exhibition.members.TableCell1": "Имя и фамилия",

  "exhibition.members.TableCell2": "Работа позиция",

  "exhibition.members.TableCell3": "Номер телефона",

  "exhibition.members.TableCell4": "Страна",

  "exhibition.members.TableCell6": "Профиль Профиль пользователя",

  "exhibition.members.TableCell5": "",

  "exhibition.rightSide.lable1": "Все",

  "exhibition.rightSide.lable2": "возможность работы",

  "exhibition.rightSide.lable3": "инвестиционная возможность",

  "exhibition.rightSide.lable3": "инвестиционная возможность",

  "exhibition.rightSide.switchLable": "Сортировать по большинству запросов",

  "exhibition.rightSide.selectInput": "Тип сотрудничества",

  "exhibition.sendBox.placeHolder": "Напишите здесь свое сообщение",



  //встреча

  "Meeting.meetingDashboard.meetingDashboard.tabLable1": "Приглашения",

  "Meeting.meetingDashboard.meetingDashboard.tabLable2": "Календарь",

  "Meeting.meetingDashboard.meetingDashboard.tabPanel": "Приглашения",

  "Meeting.meetingDashboard.meetingDashboard.tabPanel23": "Абонент",

  "Meeting.meetingDashboard.meetingDashboard.noInventation": "Приглашение не отправлено",

  "Meeting.meetingDashboard.meetingDashboard.Today": "Сегодня",

  "Meeting.meetingDashboard.meetingDashboard.noInventationtoday": "Сегодня вы не получали приглашений",

  "Meeting.meetingDashboard.meetingDashboard.tabLable11": "Раздел участников",

  "Meeting.meetingDashboard.meetingDashboard.setMeetingText": "Чтобы назначить встречу, выберите участника в разделе «Участники» и нажмите «Запросить встречу",

  "Meeting.meetingDashboard.meetingInventation.month1": "Январь",

  "Meeting.meetingDashboard.meetingInventation.month2": "Февраль",

  "Meeting.meetingDashboard.meetingInventation.month3": "Март",

  "Meeting.meetingDashboard.meetingInventation.month4": "Апрель",

  "Meeting.meetingDashboard.meetingInventation.month5": "Май",

  "Meeting.meetingDashboard.meetingInventation.month6": "июнь",

  "Meeting.meetingDashboard.meetingInventation.month7": "июль",

  "Meeting.meetingDashboard.meetingInventation.month8": "август",

  "Meeting.meetingDashboard.meetingInventation.month9": "сентябрь",

  "Meeting.meetingDashboard.meetingInventation.month10": "Октябрь",

  "Meeting.meetingDashboard.meetingInventation.month11": "ноябрь",

  "Meeting.meetingDashboard.meetingInventation.month12": "Декабрь",

  "Meeting.meetingDashboard.meetingInventation.status": "Принято",

  "Meeting.meetingDashboard.meetingInventation.selectedPending": "В стадии расследования",

  "Meeting.meetingDashboard.meetingInventation.selectedRejected": "Отклонено",

  "Meeting.meetingDashboard.meetingInventation.selectedAccepted": "Принято",

  "Meeting.meetingDashboard.meetingInventation.inventationText": "Приглашение:",

  "Meeting.meetingDashboard.meetingInventation.boxPlace": "Место проведения:",

  "Meeting.meetingDashboard.meetingInventation.boxContent": "Видео",

  "Meeting.meetingDashboard.meetingInventation.boxHeading": "Участники",

  "Meeting.meetingDashboard.meetingInventation.selectedMeeting": "Не указано",

  "Meeting.meetingDashboard.meetingInventation.selectedMeeting": "Не указано",

  "Meeting.meetingDashboard.meetingInventation.selectedDate": "Дата",

  "Meeting.meetingDashboard.meetingInventation.loadingAccept": "Установить новое время",

  "Meeting.meetingDashboard.meetingInventation.loadingAccepttwo": "Принять",

  "Meeting.meetingDashboard.meetingInventation.sen_confirm.title": "Вы хотите отправить это приглашение?",

  "Meeting.meetingDashboard.meetingInventation.send_confirm.agree": "Да, буду",

  "Meeting.meetingDashboard.meetingInventation.send_confirm.disagree": "Нет",

  "Meeting.meetingDashboard.meetingInventation.accept_confirm.title": "Вы хотите принять этот сеанс?",

  "Meeting.meetingDashboard.meetingInventation.accept_confirm.agree": "Да, я принимаю",

  "Meeting.meetingDashboard.meetingInventation.accept_confirm.disagree": "Нет",

  "Meeting.meetingDashboard.meetingInventation.reject_confirm.title": "Вы действительно хотите удалить этот сеанс?",

  "Meeting.meetingDashboard.meetingInventation.reject_confirm.agree": "Да, я отклоняю это",

  "Meeting.meetingDashboard.meetingInventation.reject_confirm.disagree": "Нет",

  "Meeting.meetingDashboard.meetingInventation.reject_confirm.alert": "Вы пропустили эту встречу!",

  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.title": "Вы хотите предложить это новое время для встречи?",

  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.agree": "Да, подтверждаю",

  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.disagree": "Нет",

  "Meeting.meetingDashboard.meetingInventation.accept_confirm.alert": "Этот сеанс настроен для вас! Мы сообщим вам об этом за 15 минут до начала совещания.",

  "Meeting.meetingDashboard.meetingInventation.sendInvitation.emptymsg.alert": "Текст приглашения забыт! Введите текст приглашения",

  "Meeting.meetingDashboard.meetingInventation.sendInvitation": "Отправить приглашение на встречу",

  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.alert": "Ваше предложение о новом времени встречи успешно отправлено.",

  "Meeting.meetingDashboard.meetingInventation.changeTime": "Изменение времени",

  "Meeting.meetingDashboard.meetingInventation.changeTimetwo": "Отменить изменения",

  "Meeting.meetingDashboard.meetingInventation.cancellRequest": "Отменить запрос",

  "Meeting.meetingDashboard.meetingInventation.alertOne": "Вы уже отправили заявку на сотрудничество",

  "Meeting.meetingDashboard.meetingInventation.changetimeReject": "Отклонить",

  "Meeting.meetingDashboard.meetingInventation.statusInvitor": "Звонящий",

  "Meeting.meetingDashboard.meetingInventation.cancelInvitation": "Отменить публикацию",

  "Meeting.meetingDashboard.meetingInventation.statusPendingReceive": "Ожидание получения",

  "Meeting.meetingDashboard.meetingInventation.timeNotSet": "Не указано",

  "Meeting.meetingDashboard.meetingList.tableTitle": "Приглашение на встречу",

  "Meeting.meetingDashboard.meetingList.subtitle": "После отправки приглашения вы должны дождаться утверждения собрания другой стороной",

  "Meeting.meetingDashboard.workSheet.item": "Видеосеанс с",

  "Meeting.meetingDashboard.meetingInventation.link": "Ссылка для входа в видеосеанс",

  "Meeting.meetingDashboard.meetingInventationCreate.send-alert": "Это приглашение было успешно отправлено! Подождите, пока ответит другая сторона",

  "Meeting.meetingDashboard.meetingInventationCreate.checkcalender.currentUser": "Выбранное время заблокировано для вас! Выберите другое время",

  "Meeting.meetingDashboard.meetingInventationCreate.checkcalender": "Аудитория не может провести встречу в назначенное время",



  //возможность

  "Oppotunity.OppotunityDashboard.searchtitle.job": "Фильтрация вакансий",

  "Oppotunity.OppotunityDashboard.searchtitle.investment": "Фильтр между инвестиционными возможностями",

  "Oppotunity.allOportunities.jobPosition": "Должность",

  "Oppotunity.allOportunities.investment": "Инвестиционные предложения",

  "Oppotunity.allOportunities.company": "Компания",

  "Oppotunity.allOportunities.City": "Город",

  "Oppotunity.allOportunities.Category": "Категория",

  "Oppotunity.companyFilter.category": "Сфера деятельности",

  "Oppotunity.companyFilter.alphabetical": "Сортировать по алфавиту",

  "Oppotunity.companyFilter.subcategory": "Категории",

  "Oppotunity.companyFilter.country": "Страна",

  "Oppotunity.memberFilter.subcategory": "Категории",

  "Oppotunity.companyFilter.country": "Страна",

  "Oppotunity.memberFilter.subcategory": "Категории",

  "Oppotunity.memberFilter.country": "Страна",

  "Oppotunity.memberFilter.ability": "Способность",

  "Oppotunity.memberFilter.interest": "Специализированный интерес",

  "Oppotunity.opportunityDashboard.tab1": "Все возможности",

  "Oppotunity.opportunityDashboard.tab2": "Мои предложения",

  "Oppotunity.allOportunities.sendrequest_investment": 'Возможности для международных инвестиций',

  "Oppotunity.allOportunities.sendrequest_job": "Маркетинговый партнер",

  "Oppotunity.opportunityItem.description": "Здесь приводится более подробное описание инвестиционной возможности и отображаются различные аспекты запрошенной инвестиционной возможности.",

  "Oppotunity.opportunityItem.createDescription": "Напишите здесь свое описание, чтобы отправлять предложения",

  "Oppotunity.opportunityDashboard.tabLable1": "Все возможности",

  "Oppotunity.opportunityDashboard.tabLable2": "Мои предложения",

  "Oppotunity.opportunityDashboard.company": "Компания",

  "Oppotunity.opportunityDashboard.location": "Местоположение",

  "Oppotunity.opportunityDashboard.location.tehran": "Тегеран",

  "Oppotunity.opportunityDashboard.switch": "Сортировать по большинству предложений",

  "Oppotunity.allOportunities.alert": "У вас сейчас нет предложений!",

  "Oppotunity.sendrequest.requestbox": "Чтобы отправить предложение, напишите здесь описание",

  "Oppotunity.item.btn-cancel": "отменить запрос",

  "Oppotunity.item.btn-sendrequest": "Запрос отправлен",

  "Oppotunity.item.delete-btn.alert": "Ваше предложение успешно отменено!",

  "Oppotunity.item.btn-sendrequest-alert_title": "",

  "Oppotunity.item.btn-sendrequest-alert_desc_job": "Чтобы создать раздел« Возможности работы »на стенде, загрузите файл напротив, заполните его полностью и загрузите окончательный файл в следующем разделе.",

  "Oppotunity.item.btn-SendRequest-alert_desc_invesment": "Для того, чтобы создать раздел„Инвестиционные возможности“на выставке стенд, пожалуйста , загрузите файл напротив, подготовить его в полном объеме, и загрузить конечный файл в следующем section.",

  "Oppotunity.item.btn-sendrequest-alert_download": "Загрузить файл",

  "Oppotunity.item.btn-sendrequest Sends of upload": "Нажмите здесь и загрузите файл",

  "Oppotunity.item.btn-sendrequest-upload_alertformat": "Формат загруженного файла не одобрен.",

  "Oppotunity.sendrequest.confirm": "Вы хотите отправить это предложение?",

  "Oppotunity.sendrequest.agree": "Да, буду",

  "Oppotunity.sendrequest.disagree": "Нет",





  //профиль

  "Profile.profileAvatar.title1": "Изображение профиля",

  "Profile.profileDetails.profileInfo.h2": "Основная информация",

  "Profile.profileDetails.profileInfo.h2.hint": "Эта информация отображается для всех",

  "Profile.profileDetails.profileInfo.general.offer.h3": "Что я могу предложить",

  "Profile.profileDetails.profileInfo.general.need.h3": "Что я ищу",

  "Profile.profileDetails.profileInfo.general.h3": "Что я могу предложить",

  "Profile.profileDetails.profileInfo.companyName": "Название компании",

  "Profile.profileDetails.profileInfo.jobPosition": "Должность",

  "Profile.profileEditExtra.textInput.lable1": "Город",

  "Profile.profileEditExtra.textInput.lable2": "Электронная почта",

  "Profile.profileEditExtra.textInput.lable3": "Linkedin",

  "Profile.profileEditExtra.textInput.lable4": "Instagram",

  "Profile.profileEditExtra.textInput.loading": "Сохранить информацию",

  "Profile.profileEditExtra.modal.title": "Основная информация",

  "Profile.profileEditExtra.modal.subTitle": "Другие могут видеть эту информацию",

  "Profile.profileEditExtra.generalDrawer.title": "",

  "Profile.profileEditExtra.generalDrawer.subTitle": "Другие могут видеть эту информацию",

  "Profile.profileEditExtra.addalert": "Ваша основная информация успешно обновлена.",

  "Profile.profileChangePassword.Modal.title": 'Изменить страницу пользователя',

  "Profile.profileChangePassword.Modal.subtitle": 'Если вы забыли свой текущий пароль, забудьте его',

  "Profile.profileChangePassword.length": 'Действительный пароль состоит не менее чем из 6 символов',

  "Profile.profileChangePassword.alert.lastpassword": 'Старый пароль неверен',

  "Profile.profileEditInfo.loading": "Сохранить информацию",

  "Profile.profileEditInfo.Modal.title": "Редактировать информацию о пользователе",

  "Profile.profileEditInfo.addalert": "Информация о вашем пользователе была успешно обновлена.",

  "Profile.profileHeader.button1": "Редактировать информацию",

  "Profile.profileHeader.button2": "Изменить пароль",

  "Profile.profilePassword.Modal.subtitle": "Если вы забыли свой текущий пароль, введите его в разделе Забыли логин",

  "Profile.profileuploader.title": "Перетащите изображение в эту область",

  "Profile.profileuploader.upload": 'Загрузить',

  "Profile.profileuploader.delete": 'Удалить',

  "Profile.profileuploader.uploadimg.alert": 'Фотография пользователя успешно обновлена.',

  "Profile.profileuploader.uploadimg.deletealert": 'Фотография пользователя успешно удалена',

  "Profile.profileuploader.confirm.deletavatar": 'Вы хотите удалить фотографию своего пользователя?',

  "Profile.profileuploader.confirm.deletavatar.agree": 'Да, я удалю',

  "Profile.profileuploader.confirm": 'Вы хотите выбрать эту фотографию в качестве фотографии пользователя?',

  "Profile.profileuploader.agree": 'Да, подтверждаю',

  "Profile.profileuploader.disagree": "Нет",

  "Profile.profileuploader.save": 'Сохранить',



  //голосование

  "vote.btn-submit": "Отправлять комментарии и баллы",

  "vote.btn-cancel": "Отменить",



  // Общие страницы

  "Common.notFound.title": "страница, которую вы ищете, не найдена",





  // Посадка

  "Landing.firstsection.bar.btn-Register": "Зарегистрироваться",

  "Landing.firstsection.bar.btn-login": "Вход",

  "Landing.firstsection.bar.btn-exhibition": "Посмотреть список выставок",
  "Landing.firstsection.bar.btn-online-demo": "Запрос на демонстрацию",

  "Landing.firstsection.bar.btn-lang": "فا",

  "Landing.firstsection.rightside.title": "Namaline, Платформа онлайн-выставки",
  "Landing.firstsection.rightside1.desc":"Добро пожаловать на выставки нового поколения.",
  "Landing.firstsection.rightside2.desc":"С помощью Namaline вы можете грамотно проводить свои физические и виртуальные выставки или участвовать в проводимых умных выставках.",

  "Landing.firstsection.rightside.desc": "Добро пожаловать на выставки нового поколения. С помощью Namaline вы можете грамотно проводить свои физические и виртуальные выставки или участвовать в проводимых умных выставках.",



  "Landing.protocol.title": "Как они работают?",

  "Landing.protocol.btngroup.sec1": "посетители",

  "Landing.protocol.btngroup.sec2": "Организаторы",

  "Landing.protocol.organizer.sec1.title": "Постройте выставку",

  "Landing.protocol.organizer.sec1.desc": "Для начала организатор должен создать выставку. Он намечает выставку, а также время начала и окончания",

  "Landing.protocol.organizer.sec2.title": "Оформление киосков",

  "Landing.protocol.organizer.sec2.desc": "Экспонент регистрируется и вводит образец. Затем он определяет желаемый тариф для участия в выставке.",

  "Landing.protocol.organizer.sec3.title": "Подтверждение киосков",

  "Landing.protocol.organizer.sec3.desc": "Затем он выбирает внешний вид своей будки, вводит сведения о кабине, членах и продуктах и ​​оплачивает счет.",

  "Landing.protocol.organizer.sec4.title": "Открытие выставки",

  "Landing.protocol.organizer.sec4.desc": "После открытия выставка будет доступна всем желающим, и посетители смогут посетить выставку",

  "Landing.protocol.view.sec1.title": "Поиск выставки",

  "Landing.protocol.view.sec1.desc": "Для начала организатор должен создать выставку. Он намечает выставку, а также время начала и окончания",

  "Landing.protocol.view.sec2.title": "Регистрация и вход",

  "Landing.protocol.view.sec2.desc": "Экспонент регистрируется и вводит образец. Затем он определяет желаемый тариф для участия в выставке.",

  "Landing.protocol.view.sec3.title": "Поиск будки",

  "Landing.protocol.view.sec3.desc": "Затем он выбирает внешний вид своего стенда, вводит сведения о стендах, членах и продуктах и ​​оплачивает счет.",

  "Landing.protocol.view.sec4.title": "Взаимодействие и общение",

  "Landing.protocol.view.sec4.desc": "После открытия выставка будет доступна всем желающим, и посетители смогут посетить выставку",

  "Landing.protocol.footer.title-view": "Если вы посетитель, зарегистрируйтесь здесь",

  "Landing.protocol.footer.title-organazer": "Если вы организатор, зарегистрируйтесь здесь",





  "Landing.Pavilions.title": 'Список выставок',
  "Landing.Pavilions.title.login": 'Выберите желаемую выставку.',
  "Landing.Pavilions.more": 'Посмотреть все',

  "Landing.Pavilions.moree": 'Видеть',

  "Landing.Pavilions.cardbtn": 'Посмотреть выставку',

  "Landing.pavilioncard.title.oncoming": 'Скоро',

  "Landing.pavilioncard.title.onregister": 'Регистрация',
  "Landing.Pavilions.cardbtn.onregister": 'Регистрация стенда',

  "Landing.pavilioncard.title.onperforming": 'На исполнении',

  "Landing.pavilioncard.title.onpast": 'Выставка окончена',





  "Landing.about.title": 'Технические характеристики',

  "Landing.about.sec1.title": 'Визуальная идентификация',

  "Landing.about.sec1.desc": "Вы можете спроектировать свой стенд, холл и даже выставочный город с точки зрения цвета, формы и размера по своему желанию.",

  "Landing.about.sec2.title": 'Возможности для совместной работы',

  "Landing.about.sec2.desc": "В киоске рынка труда отображаются вакансии на киоске, чтобы вам было проще нанять или найти работу, которую вы ищете.",

  "Landing.about.sec3.title": 'Мастер-класс и лекция',

  "Landing.about.sec3.desc": "В Намалине легко найти место для видеоконференций, мастер- классов , семинаров и любых других онлайн-мероприятий.",





  "Landing.appdescription.title": 'Новый опыт участия в виртуальной выставке',

  "Landing.appdescription.desc": 'Вы получите новый опыт участия в выставке с Namaline, использование этого программного обеспечения на всех устройствах может быть легко принято и завершено.',

  "Landing.appdescription.btn": 'установка программного обеспечения',

  "Landing.video-help.title": "Участвуйте в выставке, умнее, чем когда-либо!",
  "Landing..video-help.desc": "Вы знаете, как выглядят будущие выставки и как они изменят опыт участия в выставке? Посмотрите это видео, чтобы познакомиться с выставками нового поколения.",

  "Landing.statistics.title": 'Выставочная статистика',

  "Landing.statistics.sec1.title": 'посетители',

  "Landing.statistics.sec2.title": 'Выставка',

  "Landing.statistics.sec3.title": 'Экспонент',

  "Landing.statistics.sec4.title": 'встреча',



  "Landing.Customers.title": 'Наши клиенты',



  "Landing.footer.logo": 'Иранская онлайн-выставка',

  "Landing.footer.help": 'руководство пользователя',

  "Landing.footer.about": 'о нас',

  "Landing.footer.contact": 'свяжитесь с нами',

  "Landing.footer.term": "Положения и условия",

  "Landing.footer.namaline": 'Все материальные и интеллектуальные права на эту работу принадлежат Namaline',



  "Landing.registerform.sec1": 'Регистрация посетителей',

  "Landing.registerform.sec2": 'Регистрация экспонента',

  "Landing.registerform.sec3": 'Регистрация организатора',

  "Landing.registerform.sec4": 'Регистрация спонсора',

  "Landing.loginform.sec1": 'Логин посетителя/экспонента',

  "Landing.loginform.sec2": 'Логин менеджера стенда',

  "Landing.loginform.sec3": 'Логин организатора',

  "Landing.loginform.sec4": 'Запись спонсора',



  //встреча

  "Meeting.alert.pending": 'pending',

  "Meeting.alert.rejected": 'rejected',

  "Meeting.alert.accepted": 'принято',

  "Meeting.toast.success": "Это приглашение было успешно отправлено! Подождите, пока ответит другая сторона",

  //Генеральная

  "General.person": 'Человек',

  "General.page": 'Страница',

  "General.from": 'От',

  "General.alert.Toast.errorServer": 'Проблема с сервером',

  "General.loading.component": 'Чтение информации с сервера...',



  //о

  "About.header.title": 'О нас',

  "About.header.button": 'Присоединяйтесь к нам',

  "About.header.text": 'Все, что вам нужно знать о нашей команде!',

  "About.tabBox.label1": 'User Manual',

  "About.tabBox.label2": 'О нас',

  "About.tabBox.label3": 'Свяжитесь с нами',

  "About.tabBox.label4": 'Правила и условия',

  "About.sectionOne.title1": "Выставка и не только с выставки!",

  "About.sectionOne.title2": "Сеть и организация деловых встреч",

  "About.sectionOne.title3": "и получать отчеты о производительности",

  "About.sectionOne.text": "Namaline - это платформа для онлайн-выставок, где помимо  показ вашего бизнеса и продуктов соответствующим людям в вашей области,  вы можете организовать деловую встречу с посетителями, в разделе «Вакансии»   ищите нужных людей, чтобы присоединиться к команде и сообщать, в конце получите интеллектуальный отчет о посетителях и ваша производительность во время онлайна exhibition.",

  "About.sectionOne.signup.btn": 'Зарегистрироваться',



  "About.jobBackground.title": 'Все началось здесь!',

  "About.jobBackground.text": `Все началось в апреле 1999 года; Когда посреди вспышки
  вируса короны и получение информации о многих повседневных делах , идеях
  Создание инфраструктуры для проведения выставок страны онлайн собрало пару человек.
  вместе, чтобы начать новый стартап в сентябре 99 г.
  установить. Цель создания этого - нечто, выходящее за рамки удержания
  выставка онлайн; Что энтузиасты по всей стране тоже
  люди, живущие за пределами Ирана, могут испытать лучшее
  международных выставок страны и всех, вне зависимости от
  Географические границы, есть возможность посещения выставки и проведения деловых встреч.
  с настоящими организациями. ` ,

  "About.team.title": 'Встречайте нашу команду!',

  "About.team.text": 'Люди, которые работают вместе, чтобы проводить онлайн-выставки, а также достигают своих целей:',

  "About.team.name.navid": 'Navid Omidian',

  "About.team.name.milad": 'Milad jafaro',

  "About.team.name.mohammadreza": ' MohammadReza moradi ',

  "About.team.name.venus": ' Venous Jalaly ',

  "About.team.name.majid": 'Majd taghadosinia ',

  "About.team.name.mohammad": 'Mohammad Gervei ',



  "About.team.position.navid": 'Соучредитель и генеральный директор',

  "About.team.position.milad": 'Соучредитель и технический старший',

  "About.team.position.mohammadreza": 'Соучредитель и главный продукт',

  "About.team.position.venus": 'Соучредитель и старший графический дизайнер',

  "About.team.position.majid": 'Старший операционный',

  "About.team.position.mohammad": 'Соучредитель и руководитель бизнеса',



  //часто задаваемые вопросы

  "Faq.header.title": 'У вас есть вопрос?',

  "Faq.header.text": 'Или посмотрите руководство по использованию продукта',

  "Faq.header.input": 'Введите ключевое слово здесь',

  "Faq.body.title": 'Часто задаваемые вопросы',

  "Faq.body.subtitle": 'Ваш ответ может быть здесь!',









  //контакт

  "Contact.header.title": 'Свяжитесь с нами',

  "Contact.header.text": 'Быстрое общение со службой поддержки',

  "Contact.header.chatbtn": 'Онлайн-чат',

  "Contact.header.callbtn": 'Позвонить',

  "contact.body.address": 'Тегеран - ул. Северный Каргар - Уголок шоссе Джалал Аль-Ахмад - Инженерный факультет Тегеранского университета',



  //термины

  "Terms.header.title": 'Ищете конкретную тему?',

  "Terms.header.text": 'Или выполните поиск по правилам ниже',

  "Terms.header.input": 'Введите ключевое слово здесь',

  "Terms.body.title": 'Положения и условия',

  "Terms.body.subtitle": 'Использовать строку имени',







}
