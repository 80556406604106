import React, { useState } from 'react';
import { fade, makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
//modal
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import {
    Link,
    InputAdornment,
    TextField,
    CircularProgress,
    Container,
} from '@material-ui/core';
import { singInWithPhone } from '../../app/store/actions/authActions.js';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { TextInput } from '../../component'
import { Visibility, VisibilityOff, Fingerprint } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import SelectLanguage from '../../views/user/SelectLanguage'
/*************change lang ***************/
import MessageLang, { messageLang } from '../../lang';
/*************change lang ***************/
import Fade from '@material-ui/core/Fade';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) =>
    createStyles({
        routeSection: {
            [theme.breakpoints.up('sm')]: {
                width: '100%',
                margin: '0 auto'
            }
        },
        LoginPanel: {
            height: '100%',
            // borderRight: '1px solid rgba(0, 0, 0, 0.12);',
            display: 'grid',
            direction: 'ltr',
            [theme.breakpoints.down('sm')]: {
                padding: '0 35px',
                overflowY: 'scroll',
            },
        },
        labelText: {
            fontSize: '30px',
        },
    }),
);

const Transition = React.forwardRef(function Transition(props, ref) {
    // return <Slide direction="up" ref={ref} {...props} />;
    return <Fade in={true} timeout={1000} ref={ref} {...props} />;
});

const StyledListItemIcon = withStyles({
    root: {
        color: '#rgba(0, 0, 0, 0.87)',
        minWidth: '40px'
    },
})(ListItemIcon);

const LoginOverlay = ({ location, routeNameFromDrawers, closeDialog }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const { lang } = useSelector((state) => state.lang);
    const { list: exlist, data } = useSelector(state => state.exhibition);
    const [label, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });
    const classes = useStyles();
    const { loading } = useSelector((state) => state.async);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleClickShowPassword = () => {
        setValues({ ...label, showPassword: !label.showPassword });
    };
    const dispatch = useDispatch()
    const history = useHistory()

    return (
        <>
            <Grid item xs={12} className={classes.LoginPanel} >
                <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                    <Container className='countrycode authbox' >
                        <section className={classes.routeSection}>
                            <Formik
                                initialValues={
                                    {
                                        phone_number: '',
                                        password: '',
                                        email: '',
                                        state: lang
                                    }}
                                validationSchema={lang === 'fa' ?
                                    Yup.object({
                                        phone_number: Yup.string().required(<MessageLang id="form.verify.login.phone" />),
                                        password: Yup.string().required(<MessageLang id="form.verify.login.password" />),
                                    })
                                    :
                                    Yup.object({
                                        email: Yup.string().required(<MessageLang id="form.verify.register.email" />).email(<MessageLang id="form.verify.register.email-valid" />),
                                        password: Yup.string().required(<MessageLang id="form.verify.login.password" />),
                                    })
                                }
                                onSubmit={async (values, { setSubmitting, setErrors }) => {
                                    // console.log(values.phone_number.slice(code.length)+"-"+code+" <<<<");
                                    try {

                                        await dispatch(singInWithPhone(values))
                                        setSubmitting(false);
                                        if (-1 == ['chat','meeting'].indexOf(routeNameFromDrawers)) {
                                            closeDialog();
                                        }
                                        else {
                                            history.push({
                                                pathname: `/${data.short_name}/${routeNameFromDrawers}`
                                            })
                                            closeDialog();
                                        }

                                    } catch (error) {
                                        console.log(error);
                                        setErrors({ auth: <MessageLang id="form.verify.login.auth" /> });
                                        setSubmitting(false);
                                    }
                                }}
                            >
                                {({ submitForm,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values, isValid, dirty }) => (
                                    <Form className='ui form '>
                                        <Box mb={5}>
                                            <div className="auth__title" >
                                                <MessageLang id="login.panel.head.welcome" />
                                            </div>
                                        </Box>

                                        {errors.auth && <span className={'error-alert'}>{errors.auth}</span>}
                                        {lang === 'fa' ?
                                            <Field
                                                component={PhoneInput}
                                                error={Boolean(touched.phone_number && errors.phone_number)}
                                                fullWidth
                                                helperText={touched.phone_number && errors.phone_number}
                                                className={classes.labelText}
                                                margin="normal"
                                                name="phone_number"
                                                onBlur={handleBlur}
                                                onChange={handleChange('phone_number')}
                                                type="number"
                                                value={values.phone_number}
                                                variant="outlined"
                                                country='ir'
                                                autoFormat={true}
                                                enableSearch={true}
                                                disableSearchIcon={false}
                                                autocompleteSearch={true}
                                                countryCodeEditable={false}
                                                specialLabel={<MessageLang id="form.label.login.phone" />}
                                                inputProps={{
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                            // isValid={(inputNumber, country, countries) => {
                                            //     setcode(country.dialCode)
                                            // }}
                                            />
                                            :
                                            <TextInput
                                                error={Boolean(touched.email && errors.email)}
                                                helperText={touched.email && errors.email}
                                                className={classes.labelText}
                                                label={<MessageLang id="Profile.profileEditExtra.textInput.lable2" />}
                                                margin="normal"
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange('email')}
                                                type="text"
                                                value={values.email}
                                            />
                                        }
                                        <Field
                                            component={TextField}
                                            id="outlined-adornment-password"
                                            error={Boolean(touched.password && errors.password)}
                                            fullWidth
                                            helperText={touched.password && errors.password}
                                            label={<MessageLang id="form.label.login.password" />}
                                            margin="normal"
                                            name="password"
                                            onBlur={handleBlur}
                                            onChange={handleChange('password')}
                                            // className={classes.textField}
                                            variant="outlined"
                                            type={label.showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword}>
                                                            {label.showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Box my={2}>
                                            <Button
                                                className={'bluebtn'}
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                onClick={submitForm}
                                            >
                                                {loading && <CircularProgress style={{ color: '#fff' }} size={26} />}
                                                {!loading && <MessageLang id="form.label.login.login" />} </Button>
                                        </Box>
                                        <Typography
                                            color="textSecondary"
                                            variant="body1"
                                            className={'forgetpass'}
                                        >
                                            <Link
                                                component={RouterLink}
                                                to='/auth/forgetpass/phone'
                                                variant="h6"
                                                className={'forgetpass'}
                                            >
                                                <Fingerprint />
                                                <MessageLang id="form.label.login.forgetpass" />
                                            </Link>
                                        </Typography>
                                        <Box Box my={4}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                className={'darkbtn'}
                                                onClick={() => { history.push('/auth/register') }}
                                            >
                                                <MessageLang id="form.label.login.signup" />
                                            </Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                            <SelectLanguage />
                        </section>
                    </Container>
                </Box>
            </Grid>
        </>
    );
}

export default LoginOverlay
