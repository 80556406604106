import {
    SIGN_IN_USER,
    SIGN_OUT_USER,
    REGISTER_STEP_ONE,
    REGISTER_STEP_TWO,
    REGISTER_STEP_THREE,
    SET_TOKEN,
    SET_TOUR_GUIDE
} from '../constants/Constants';
import agent from '../../api/agent.js';
import { asyncActionStart, asyncActionFinish } from './asyncActions';
import { history } from '../configureStore';
import { toastr } from 'react-redux-toastr';
import { store } from '../../../index';
import { messageLang } from '../../../lang';

export function loginUser(user) {
    return {
        type: SIGN_IN_USER,
        payload: user
    }
}

export function logoutUser() {
    return {
        type: SIGN_OUT_USER,
        payload: null
    }
}

export function setToken(token) {
    window.localStorage.setItem('jwt', token);
    return {
        type: SET_TOKEN,
        payload: token
    }
}

export function registerStepOne(user) {
    return {
        type: REGISTER_STEP_ONE,
        payload: user
    }
}

export function registerStepTwo(code) {
    return {
        type: REGISTER_STEP_TWO,
        payload: code
    }
}

export function registerStepThree(user) {
    return {
        type: REGISTER_STEP_THREE,
        payload: user
    }
}

export function setTour(section) {
    return {
        type: SET_TOUR_GUIDE,
        payload: section
    }
}

// login
export const singInWithPhone = (values) => {
    return async function (dispatch) {
        try {
            dispatch(asyncActionStart())
            const { phone_number, password, email, state } = values
            var response = {};
            /*-----------------*/
            if (state === 'fa') {
                response = await agent.User.login({
                    "username": '+' + phone_number.toString(),
                    "password": password.toString()
                });
            }
            else {
                response = await agent.User.login({
                    "username": email.toString(),
                    "password": password.toString()
                });
            }
            /*-----------------*/
            if (response.status) {
                dispatch(loginUser(response.result.user))
                dispatch(setToken(response.result.access_token))
                dispatch(asyncActionFinish())

                toastr.success('', `${response.result.user.first_name + ' ' + response.result.user.last_name} ` + messageLang("login.message"))
            }
        } catch (error) {
            dispatch(asyncActionFinish())
            throw error
        }
    }
}

// logout
export const signoutUser = () => {
    return async function (dispatch) {
        try {
            dispatch(setToken(null))
            dispatch(logoutUser())
            toastr.success(messageLang("DashboardLayout.header.logout.alert"))
            history.push('/auth/login')
        } catch (error) {
            throw error
        }
    }
}

// register step one
export const requestCode = (user) => {
    return async function (dispatch) {
        try {
            dispatch(asyncActionStart())

            /*---------*/
            var result = {};
            if (user.state === "fa") {
                await agent.User.checkPhone({ "username": '+' + user.phone_number });
                result = await agent.User.requestCode({ "username": '+' + user.phone_number });
            }
            else {
                await agent.User.checkPhone({ "username": user.email });
                result = await agent.User.requestCode({ "username": user.email });
            }
            /*---------*/

            if (result.state) {
                dispatch(registerStepOne(user))
                dispatch(asyncActionFinish())
                history.push('/auth/register/verification');
            }
        } catch (error) {
            dispatch(asyncActionFinish())
            throw (error)
        }
    }
}

// register step two
export const verificateCode = (user) => {
    return async (dispatch, getState) => {
        try {
            dispatch(asyncActionStart())

            var code = '';
            Object.keys(user).map((key) => code += user[key]);

            /*------------------*/
            var result = {}
            if (getState().auth.guestUser.state === 'fa') {
                result = await agent.User.verificateCode({
                    "username": getState().auth.guestUser.phone_number,
                    "code": code
                });
            }
            else {
                result = await agent.User.verificateCode({
                    "username": getState().auth.guestUser.email,
                    "code": code
                });
            }
            /*------------------*/
            if (result.state) {
                dispatch(registerStepTwo(code))
                history.push('/auth/register/password')
            } else {

            }
            dispatch(asyncActionFinish())

        } catch (error) {
            dispatch(asyncActionFinish())
            throw (error)
        }
    }
}

// register step three
export const registerUser = (values) => {
    return async (dispatch, getState) => {
        try {
            dispatch(asyncActionStart())

            /*--------------*/
            const { first_name, last_name, phone_number, email, state, code } = getState().auth.guestUser
            var response = {};
            if (state === 'fa') {
                if (email === '') {
                    response = await agent.User.register({
                        first_name: first_name,
                        last_name: last_name,
                        username: '+' + phone_number,
                        mobile: '+' + phone_number,
                        code: code,
                        password: values.password,
                        password_confirmation: values.password_confirmation
                    })
                }
                else {
                    response = await agent.User.register({
                        first_name: first_name,
                        last_name: last_name,
                        username: '+' + phone_number,
                        mobile: '+' + phone_number,
                        email: values.email,
                        code: code,
                        password: values.password,
                        password_confirmation: values.password_confirmation
                    })
                }
            }
            else {
                response = await agent.User.register({
                    first_name: first_name,
                    last_name: last_name,
                    username: email,
                    mobile: '+' + values.phone_number,
                    email: email,
                    code: code,
                    password: values.password,
                    password_confirmation: values.password_confirmation
                });
                console.log({
                    first_name: first_name,
                    last_name: last_name,
                    username: email,
                    mobile: '+' + values.phone_number,
                    email: email,
                    code: code,
                    password: values.password,
                    password_confirmation: values.password_confirmation
                });

            }
            /*--------------*/

            if (response.status) {
                dispatch(loginUser(response.result.user))
                dispatch(setToken(response.result.access.access_token))
                dispatch(asyncActionFinish())
                // history.push({
                //     pathname: `/${store.getState().exhibition.data.short_name}/home`
                // })
                history.push('/');
            }
            dispatch(asyncActionFinish())
        } catch (error) {
            dispatch(asyncActionFinish())
            throw error
        }
    }
}


// forget pass - step one
export const requestCodeFp = (user) => {
    return async function (dispatch) {
        try {
            dispatch(asyncActionStart())

            /*------------*/
            var result = {};
            if (user.state === 'fa') {
                result = await agent.User.requestCode({
                    "username": '+' + user.phone_number,
                    "type": "forgot"
                });
            }
            else {
                result = await agent.User.requestCode({
                    "username": user.email,
                    "type": "forgot"
                });
            }
            /*------------*/

            if (result.state) {
                dispatch(registerStepOne(user))
                dispatch(asyncActionFinish())
                toastr.success(messageLang("forgetpass.sendcode.alert"))
                history.push('/auth/forgetpass/code');
            }
        } catch (error) {
            dispatch(asyncActionFinish())
            throw (error)
        }
    }
}

// forget pass - step two
export const verificateCodeFp = (user) => {
    return async (dispatch, getState) => {
        try {
            dispatch(asyncActionStart())
            var code = '';
            Object.keys(user).map((key) => code += user[key]);

            // const result = await agent.User.verificateCode({
            //     "username": getState().auth.guestUser.phone_number,
            //     "code": code
            // });
            /*------------------*/
            var result = {}
            if (getState().auth.guestUser.state === 'fa') {
                result = await agent.User.verificateCode({
                    "username": getState().auth.guestUser.phone_number,
                    "code": code
                });
            }
            else {
                result = await agent.User.verificateCode({
                    "username": getState().auth.guestUser.email,
                    "code": code
                });
            }
            /*------------------*/
            if (result.state) {
                dispatch(registerStepTwo(code))
                history.push('/auth/forgetpass/pass')
            } else {

            }
            dispatch(asyncActionFinish())

        } catch (error) {
            dispatch(asyncActionFinish())
            throw (error)
        }
    }
}

// forget pass - step three
export const updatePassFp = (values) => {
    return async (dispatch, getState) => {
        try {
            dispatch(asyncActionStart())
            const { phone_number, code, email } = getState().auth.guestUser
            var response = {}
            if (values.state === 'fa') {
                response = await agent.User.forgetPassword({
                    username: '+' + phone_number,
                    code: code,
                    password: values.password,
                    password_confirmation: values.password_confirmation
                });
            }
            else {
                response = await agent.User.forgetPassword({
                    username: email,
                    code: code,
                    password: values.password,
                    password_confirmation: values.password_confirmation
                });
            }
            if (response.status) {
                dispatch(loginUser(response.result.user))
                dispatch(setToken(response.result.access.access_token))
                dispatch(asyncActionFinish())
                history.push({
                    pathname: `/${store.getState().exhibition.data.short_name}/home`
                })
                // history.push('/expo/home');
            }
            dispatch(asyncActionFinish())
        } catch (error) {
            dispatch(asyncActionFinish())
            console.log(error);
            throw error
        }
    }
}

export const codeExpire = () => {
    return async function (dispatch, getState) {
        try {
            dispatch(asyncActionStart())
            const { phone_number } = getState().auth.guestUser
            await agent.User.requestCode({ "username": phone_number });
            dispatch(asyncActionFinish())
        } catch (error) {
            dispatch(asyncActionFinish())
            throw (error)
        }
    }
}

//changepassword
export const ChangePasswordUser = (values) => {
    return async (dispatch, getState) => {
        try {
            dispatch(asyncActionStart())
            const { first_name, last_name, phone_number, code } = getState().auth.currentUser
            const response = await agent.User.changepassword({
                first_name: first_name,
                last_name: last_name,
                username: phone_number,
                code: code,
                old_password: values.last_password,
                password: values.password,
                password_confirmation: values.password_confirmation
            });
            if (response.status) {
                console.log(response.result)
                dispatch(setToken(response.result.access.access_token))
            }
            dispatch(asyncActionFinish())
        } catch (error) {
            dispatch(asyncActionFinish())
            throw error
        }
    }
}

export const setTourGuide = (section) => {
    return async function (dispatch, getState) {
        try {
            const params = new URLSearchParams()
            params.append('section', section)
            const res = await agent.TourGuide.set(params);
            dispatch(setTour(res))
        } catch (error) {
            throw (error)
        }
    }
}