import React from 'react'
import {
    Button,
    CircularProgress
} from '@material-ui/core';
const MainButton = ({ onClick, loading, label, primary }) => {
    return (
        <div 
        // className={primary ? 'button-primary' : ''}
        >
            <Button
                fullWidth
                className='darkbtn'
                size="large"
                variant="contained"
                onClick={onClick}
                disabled={loading}  
            >
                {loading && <CircularProgress color='secondary' size={26} />}
                {!loading && label}
            </Button>
        </div>
   
    )
}

export default MainButton
