import {
    FETCH_COMPANIES,
    FETCH_MEMBERS,
    SET_COMPANY_FILTER,
    SET_MEMBER_FILTER,
    CLEAR_COMPANY_FILTER,
    CLEAR_MEMBER_FILTER,
    FETCH_MEMBER_DETAILS,
    MEMBER_DETAIL_LOADING,
    SETTING_MODAL
} from '../constants/Constants';

// REFACTOR this code again : 
// https://zendev.com/2018/05/09/understanding-spread-operator-in-javascript.html#:~:text=Spread%20Operators%20for%20Arrays&text=This%20is%20the%20spread%20operator,if%20you%20have%20the%20code%3A&text=array%2C%203%2C%204%5D%3B,2%2C%203%2C%204%5D%20.
// https://github.com/WordPress/gutenberg/blob/823dcf2ac446d21d76bf43a797347ce5a33ece3d/core-blocks/gallery/edit.js
// and some "advanced tutorial" about spread operator
const initialState = {
    companies: [],
    members: [],
    moreCompanies: true,
    selectedStand: null,
    lastVisible: null,

    companyFilters: {
        category: 0,
        subCategory: 0,
        country: 0,
        q: '',
        limit: 20,
        page: 1,
        total: null,
    },
    memberFilters: {
        company: 0,
        interest: 0,
        ability: 0,
        country: 0,
        position: '',
        q: '',
        limit: 20,
        page: 1,
        total: null,
    },
    details: {},
    loadDetails: true,
    openModal: false
};

export default function standReducer(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_COMPANIES:
            return {
                ...state,
                companies: [...payload.companies],
                companyFilters: {
                    ...state.companyFilters,
                    total: payload.total,
                }
            }
        case SETTING_MODAL:
            return {
                ...state,
                openModal: payload
            }
        case FETCH_MEMBER_DETAILS:
            return {
                ...state,
                details: payload,
                loadDetails: false
            }
        case FETCH_MEMBERS:
            return {
                ...state,
                members: [...payload.members],
                memberFilters: {
                    ...state.memberFilters,
                    total: payload.total,
                }
            }
        case SET_COMPANY_FILTER:
            if (payload.filter_type === 'category')
                return {
                    ...state, companyFilters: {
                        ...state.companyFilters, category: payload.value, subCategory: 0, page: 1
                    }
                }
            else if (payload.filter_type === 'sub_ategory')
                return {
                    ...state, companyFilters: {
                        ...state.companyFilters, subCategory: payload.value, page: 1
                    }
                }
            else if (payload.filter_type === 'country')
                return {
                    ...state, companyFilters: {
                        ...state.companyFilters, country: payload.value, page: 1
                    }
                }
            else if (payload.filter_type === 'search')
                return {
                    ...state, companyFilters: {
                        ...state.companyFilters, q: payload.value, page: 1
                    }
                }
            else if (payload.filter_type === 'limit')
                return {
                    ...state, companyFilters: {
                        ...state.companyFilters, limit: payload.value, page: 1
                    }
                }
            else if (payload.filter_type === 'page')
                return {
                    ...state, companyFilters: {
                        ...state.companyFilters, page: payload.value
                    }
                }
            else return state
        case CLEAR_COMPANY_FILTER:
            return {
                ...state,
                companyFilters: {
                    ...state.companyFilters,
                    category: 0,
                    subCategory: 0,
                    q: '',
                    page: 1,
                    limit: 20,
                    total: null,
                }
            }
        case SET_MEMBER_FILTER:
            switch (payload.filter_type) {
                case 'company':
                    return {
                        ...state, memberFilters: {
                            ...state.memberFilters, company: payload.value, page: 1
                        }
                    }
                case 'interest':
                    return {
                        ...state, memberFilters: {
                            ...state.memberFilters, interest: payload.value, page: 1
                        }
                    }
                case 'ability':
                    return {
                        ...state, memberFilters: {
                            ...state.memberFilters, ability: payload.value, page: 1
                        }
                    }
                case 'country':
                    return {
                        ...state, memberFilters: {
                            ...state.memberFilters, country: payload.value, page: 1
                        }
                    }
                case 'position':
                    return {
                        ...state, memberFilters: {
                            ...state.memberFilters, position: payload.value, page: 1
                        }
                    }
                case 'search':
                    return {
                        ...state, memberFilters: {
                            ...state.memberFilters, q: payload.value, page: 1
                        }
                    }
                case 'page':
                    return {
                        ...state, memberFilters: {
                            ...state.memberFilters, page: payload.value
                        }
                    }
                case 'limit':
                    return {
                        ...state, memberFilters: {
                            ...state.memberFilters, limit: payload.value, page: 1
                        }
                    }
                default: return state
            }
        default:
            return state;
    }
}