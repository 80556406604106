import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withRouter } from "react-router";

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import Page from '../../component/Page.jsx';
import agent from '../../app/api/agent'
import {
    Box,
    Button,
    Link,
    Typography,
    IconButton,
    InputAdornment,
    TextField,
    CircularProgress,
    Container,
    Grid,
    Hidden,
} from '@material-ui/core';
import { TextInput } from '../../component'
import { Visibility, VisibilityOff, Fingerprint } from '@material-ui/icons';
import { singInWithPhone } from '../../app/store/actions/authActions.js';
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './_auth.js'
import SelectLanguage from './SelectLanguage'
import { setExhibition, setExhibitions } from '../../app/store/actions/exhibitionAction';
//phone
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
/*************change lang ***************/
import MessageLang from '../../lang/';
/*************change lang ***************/

const LoginView = ({ match, history, location }) => {
    const [code, setcode] = useState('')
    const classes = useStyles()
    const dispatch = useDispatch()
    const { loading } = useSelector((state) => state.async);
    const { lang } = useSelector((state) => state.lang);
    const { list: exlist, data } = useSelector(state => state.exhibition);

    const [label, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({ ...label, showPassword: !label.showPassword });
    };

    useEffect(() => {
        async function fetchExhibitions() {
            let response = await agent.Landing.list()
            dispatch(setExhibitions(response.data.result.data))
        }

        fetchExhibitions()
    }, [])

    return (
        <Page className={classes.root} title="Login" >
            <Grid className={classes.authContainer} >
                <Hidden only={['sm', 'xs']}>
                    <Grid item lg={7} md={7} className={'auth-panel'} >
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent='center'
                            height="100%"
                        >
                            <Container maxWidth="md" >
                                <Box textAlign="center" >
                                    <img
                                        alt="Login"
                                        className={classes.image}
                                        src="/static/images/backgrounds/SVG_Auth/login.svg"
                                    />
                                </Box>
                                <Typography
                                    align="center"
                                    color="textPrimary"
                                    variant="h1"
                                    className={'title'}
                                >
                                    <MessageLang id="login.panel.head.title" />

                                </Typography>
                                <Typography
                                    align="center"
                                    color="textPrimary"
                                    variant="subtitle2"
                                    className={'description'}
                                >
                                    <MessageLang id="login.panel.head.text" />
                                </Typography>
                                <Typography
                                    align="center"
                                    color="textPrimary"
                                    variant="subtitle2"
                                    className={'description'}
                                >
                                    <MessageLang id="login.panel.head.welcome" />...
                                </Typography>
                            </Container>
                        </Box>
                    </Grid>
                </Hidden>
                <Grid item lg={5} md={5} xs={12} className={classes.LoginPanel} >
                    <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                        <Container className='countrycode authbox' >
                            <section className={classes.routeSection}>
                                <Formik
                                    initialValues={
                                        {
                                            phone_number: '',
                                            password: '',
                                            email: '',
                                            state: lang
                                        }}
                                    validationSchema={lang === 'fa' ?
                                        Yup.object({
                                            phone_number: Yup.string().required(<MessageLang id="form.verify.login.phone" />),
                                            password: Yup.string().required(<MessageLang id="form.verify.login.password" />),
                                        })
                                        :
                                        Yup.object({
                                            email: Yup.string().required(<MessageLang id="form.verify.register.email" />).email(<MessageLang id="form.verify.register.email-valid" />),
                                            password: Yup.string().required(<MessageLang id="form.verify.login.password" />),
                                        })
                                    }
                                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                                        // console.log(values.phone_number.slice(code.length)+"-"+code+" <<<<");
                                        try {
                                            
                                            await dispatch(singInWithPhone(values))
                                            setSubmitting(false);
                                            if (history.length > 1) history.goBack()
                                            else history.push({ pathname: `/${data.short_name}/home` })
                                        } catch (error) {
                                            console.log(error);
                                            setErrors({ auth: <MessageLang id="form.verify.login.auth" /> });
                                            setSubmitting(false);
                                        }
                                    }}
                                >
                                    {({ submitForm,
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        touched,
                                        values, isValid, dirty }) => (
                                        <Form className='ui form '>
                                            <Box mb={5}>
                                                <div className="auth__title" >
                                                    <MessageLang id="login.panel.head.welcome" />
                                                </div>
                                            </Box>

                                            {errors.auth && <span className={'error-alert'}>{errors.auth}</span>}
                                            {lang === 'fa' ?
                                                <Field
                                                    component={PhoneInput}
                                                    error={Boolean(touched.phone_number && errors.phone_number)}
                                                    fullWidth
                                                    helperText={touched.phone_number && errors.phone_number}
                                                    className={classes.labelText}
                                                    margin="normal"
                                                    name="phone_number"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange('phone_number')}
                                                    type="number"
                                                    value={values.phone_number}
                                                    variant="outlined"
                                                    country='ir'
                                                    autoFormat={true}
                                                    enableSearch={true}
                                                    disableSearchIcon={false}
                                                    autocompleteSearch={true}
                                                    countryCodeEditable={false}
                                                    specialLabel={<MessageLang id="form.label.login.phone" />}
                                                    inputProps={{
                                                        required: true,
                                                        autoFocus: true,
                                                    }}
                                                // isValid={(inputNumber, country, countries) => {
                                                //     setcode(country.dialCode)
                                                // }}
                                                />
                                                :
                                                <TextInput
                                                    error={Boolean(touched.email && errors.email)}
                                                    helperText={touched.email && errors.email}
                                                    className={classes.labelText}
                                                    label={<MessageLang id="Profile.profileEditExtra.textInput.lable2" />}
                                                    margin="normal"
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange('email')}
                                                    type="text"
                                                    value={values.email}
                                                />
                                            }
                                            <Field
                                                component={TextField}
                                                id="outlined-adornment-password"
                                                error={Boolean(touched.password && errors.password)}
                                                fullWidth
                                                helperText={touched.password && errors.password}
                                                label={<MessageLang id="form.label.login.password" />}
                                                margin="normal"
                                                name="password"
                                                onBlur={handleBlur}
                                                onChange={handleChange('password')}
                                                // className={classes.textField}
                                                variant="outlined"
                                                type={label.showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword}>
                                                                {label.showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Box my={2}>
                                                <Button
                                                    className={'bluebtn'}
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    onClick={submitForm}
                                                >
                                                    {loading && <CircularProgress style={{ color: '#fff' }} size={26} />}
                                                    {!loading && <MessageLang id="form.label.login.login" />} </Button>
                                            </Box>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                                className={'forgetpass'}
                                            >
                                                <Link
                                                    component={RouterLink}
                                                    to='/auth/forgetpass/phone'
                                                    variant="h6"
                                                    className={'forgetpass'}
                                                >
                                                    <Fingerprint />
                                                    <MessageLang id="form.label.login.forgetpass" />
                                                </Link>
                                            </Typography>
                                            <Box Box my={4}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    className={'darkbtn'}
                                                    onClick={() => { history.push('/auth/register') }}
                                                >
                                                    <MessageLang id="form.label.login.signup" />
                                                </Button>
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                                <SelectLanguage />
                            </section>
                        </Container>
                    </Box>
                </Grid>
            </Grid >
        </Page >
    );
};

export default LoginView
