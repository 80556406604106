import {
    FETCH_ALL_OPPORTUNITIES,
    FETCH_MY_OPPORTUNITIES,
    FETCH_PAVILION_OPPORTUNITIES,
    SET_OPPORTUNITY_FILTER,
    CLEAR_OPPORTUNITIES_FILTER,
    SET_LOADING
} from '../constants/Constants';

const initialState = {
    opportunities: [],
    loading: true,
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: null,
    myOpportunities: [],
    current_page_myOpportunities: 1,
    last_page_myOpportunities: 1,
    per_page_myOpportunities: 10,
    total_myOpportunities: null,
    pavilionOpportunities: [],
    current_pagepavilionOpportunities: 1,
    last_page_pavilionOpportunities: 1,
    per_page_pavilionOpportunities: 10,
    total_pavilionOpportunities: null,
    pavilionOpportunitiesLoading: true,
    opportuntiesFilters: {
        company_id: 0,
        position_id: 0,
        location_id:0,
        q: '',
        page: 1,
        limit: 5,
    },
};

export default function opportunitiesReducer(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_ALL_OPPORTUNITIES:
            return {
                ...state,
                opportunities: payload.opportunities,
                current_page: payload.current_page,
                last_page: payload.last_page,
                per_page: payload.per_page,
                total: payload.total,
                loading: false
            };
        case FETCH_MY_OPPORTUNITIES:
            return {
                ...state,
                myOpportunities: payload.opportunities,
                current_page_myOpportunities: payload.current_page,
                last_page_myOpportunities: payload.last_page,
                per_page_myOpportunities: payload.per_page,
                total_myOpportunities: payload.total,
                loading: false
            };
        case FETCH_PAVILION_OPPORTUNITIES:
            return {
                ...state,
                pavilionOpportunities: payload.opportunities,
                current_page_pavilionOpportunities: payload.current_page,
                last_page_pavilionOpportunities: payload.last_page,
                per_page_pavilionOpportunities: payload.per_page,
                total_pavilionOpportunities: payload.total,
                pavilionOpportunitiesLoading: false
            };
        case SET_LOADING:
            return {
                ...state,
                pavilionOpportunitiesLoading: true,
                loading: true
            };
            
        case SET_OPPORTUNITY_FILTER:
            if (payload.filter_type === 'company')
                return {
                    ...state, opportuntiesFilters: {
                        ...state.opportuntiesFilters, company_id: payload.value
                    }
                }
            else if (payload.filter_type === 'position')
                return {
                    ...state, opportuntiesFilters: {
                        ...state.opportuntiesFilters, position_id: payload.value
                    }
                }
            else if (payload.filter_type === 'location')
                return {
                    ...state, opportuntiesFilters: {
                        ...state.opportuntiesFilters, location_id: payload.value
                    }
                }
            else if (payload.filter_type === 'search')
                return {
                    ...state, opportuntiesFilters: {
                        ...state.opportuntiesFilters, q: payload.value
                    }
                }
            else return state
        case CLEAR_OPPORTUNITIES_FILTER:
            return {
                ...state,
                opportuntiesFilters: {
                    ...state.opportuntiesFilters,
                    company_id: 0,
                    position_id: 0,
                    location_id:0,
                    q: ''
                }
            }
        default:
            return state;
    }
}