import {
    SET_LECTURES,
    SET_WORKSHOPS,
    SET_FILTER,
    RETAIN_STATE,
    FETCH_EVENT_DATES,
    SET_LECTURE_ALERT,
    SET_WORKSHOP_ALERT
} from '../constants/Constants';

const initialState = {
    lectures: [],
    workshops: [],
    comments: [],
    load_events: false,
    load_workshops: false,
    moreEvents: true,
    selectedEvent: null,
    lastVisible: null,

    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: null,

    filter: {
        date: '',
        category: [],
        subCategory: []
    },

    dates: []
};

export default function eventReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_LECTURES:
            return {
                ...state,
                // events: [...state.events, ...payload.events],
                lectures: [...payload.events],
                current_page: payload.current_page,
                last_page: payload.last_page,
                per_page: payload.per_page,
                total: payload.total,
            };
        case SET_WORKSHOPS:
            return {
                ...state,
                // events: [...state.events, ...payload.events],
                workshops: [...payload.events],
                current_page: payload.current_page,
                last_page: payload.last_page,
                per_page: payload.per_page,
                total: payload.total,
            };
        case SET_FILTER:
            return {
                ...state,
                filter: { ...state.filter, date: payload.date },
            };
        case SET_LECTURE_ALERT:
            return {
                ...state,
                lectures: state.lectures.map(lecture => {
                    if (lecture.id === payload) {
                        lecture.alert = !lecture.alert
                        return lecture
                    } else return lecture
                })
            }
        case SET_WORKSHOP_ALERT:
            return {
                ...state,
                workshops: state.workshops.map(workshop => {
                    if (workshop.id === payload) {
                        workshop.alert = !workshop.alert
                        return workshop
                    } else return workshop
                })
            }
        case RETAIN_STATE:
            return {
                ...state,
                retainState: true,
            };
        case FETCH_EVENT_DATES:
            return {
                ...state,
                dates: [...payload.dates]
            }
        default:
            return state;
    }
}