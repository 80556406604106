////////// general //////////////////

////////// company //////////////////

//////////form //////////////////
//////////label //////////////////
//////////verify //////////////////

////////// login //////////////////

////////// register //////////////////


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ////////// general //////////////////
  "general.copyright": "Gogo React © 2018 All Rights Reserved.",

  //////////  company //////////////////
  "company.name": "namaline",
  "general.select.lang": "Select Language",
  ////////// form //////////////////

  ////////// label //////////////////
  "form.label.login.withPhone": "Phone number",
  "form.label.login.codenumber": "Country code",
  "form.label.login.phone": "Phone number",
  "form.label.login.password": "Password",
  "form.label.login.login": "Login to namaline",
  "form.label.login.forgetpass": "Forgot my password",
  "form.label.login.signup": "New user? register",
  "form.label.register.name": "Name",
  "form.label.register.family": "Last Name",
  "form.label.register.company": "Company Name",
  "form.label.register.btn_signup": "Register",
  "form.label.register.btn_verify_phone": "Confirm mobile number",
  "form.label.register.code_one": "Code sent to ",
  "form.label.register.code_two": "Enter here",
  "form.label.register.trycode": "Resubmit the code",
  "form.label.register.stepThreePassword": "Enter your password",
  "form.label.register.lastpassword": "Current password",
  "form.label.register.password": "New password",
  "form.label.register.password_replay": "Repeat the new password",
  "form.label.register.terms": "I have read the",
  "form.label.register.terms2": ",I accept it",
  "form.label.forgetpass.btn": "Send the code",
  "form.label.forgetpass.btn_verify_code": "Confirm code",
  "form.label.placeholder.enterText": "Write your message here",
  "form.label.placeholder.enterMeetingText": "Inventation description",
  "form.label.placeholder.search": "Search...",
  "form.label.placeholder.select": "Select...",

  "form.pagenavigation": "Rows per page",
  "notification.head": "Notifications",

  ////////// verify //////////////////
  "form.verify.login.phone": "Enter your contact number",
  "form.verify.login.password": "Enter your password",
  "form.verify.login.two_password": "Repeat your password",
  "form.verify.login.authpassword": "Repeat password incorrect",
  "form.verify.login.auth": "Incorrect mobile number or email.",
  "form.verify.register.name": "Enter your name",
  "form.verify.register.lastname": "Enter your last name",
  "form.verify.register.phone": "Enter your mobile number",
  "form.verify.register.code": "Enter your country code",
  "form.verify.register.email": "Enter your email",
  "form.verify.register.auth": "The code is incorrect! Try again",
  "form.verify.register.authpassword": "The password does not match the second field",
  "form.verify.register.authStepThree": "Registration encountered a problem, please try again",
  "form.verify.register.email-valid": "The entered email is not valid",

  ////////// login //////////////////
  "login.message": ' Dear, welcome to Namalain',
  "login.panel.head.title": "Lots of features for visitors!",
  "login.panel.head.text": "See the latest products, attend specialized conferences, arrange video conferences, join group conversations and enjoy the booth space.",
  "login.panel.head.welcome": "Welcome to the Namaline",

  ////////// register //////////////////
  "register.panel.head.title": "Introduce yourself to everyone!",
  "register.panel.head.text": "See the latest products, attend specialized conferences, arrange video conferences, join group conversations and enjoy the booth space.",
  "register.panel.head.form_head": "Register in less than a minute",
  "register.panel.btn.loginHall": "Enter the exhibition",

  ///////// forgetpass /////////////////
  "forgetpass.panel.head.title": "Forgot your password?",
  "forgetpass.panel.head.subtext1": "Enter the mobile number you registered with!",
  "forgetpass.panel.head.subtext2": "Enter the email you registered with!",
  "forgetpass.sendcode.alert": "New code sent successfully.",

  //////// DashboardLayout /////////////////
  //header
  "Expo.header.next": "Next",
  "Expo.header.previous": "Previous",
  "DashboardLayout.header.logout.confirm": 'Do you want to log out?',
  "DashboardLayout.header.logout.agree": `Yes, I'm leaving`,
  "DashboardLayout.header.logout.disagree": 'No',
  "DashboardLayout.header.logout.alert": "You have successfully logged out",

  "DashboardLayout.header.products": "Products & Services",
  "DashboardLayout.header.meetings": "Meetings",
  "DashboardLayout.header.exhibition": "Exhibition",
  "DashboardLayout.header.events": "Events",
  "DashboardLayout.header.events2": "List of exhibitions",
  "DashboardLayout.header.events2.btn": "See more",
  "DashboardLayout.header.conversations": "Conversations",
  "DashboardLayout.header.booths": "Booths",
  "DashboardLayout.header.conference_hall": "Conference Hall",
  "DashboardLayout.header.user_page": "User Page",
  "DashboardLayout.header.pearl_petrochemical": "Pearl Petrochemical",
  "DashboardLayout.header.jobs": "Jobs",
  "DashboardLayout.header.profile": "Profile",
  "DashboardLayout.header.logout": "Logout",
  "DashboardLayout.header.investment.opportunities": "Investment opportunities",
  "DashboardLayout.header.job.opportunities": "Job opportunities",
  //////// events /////////////////
  //EventDashboard
  "events.EventDashboard.tabnav.lectures": "Lectures",
  "events.EventDashboard.tabnav.workshops": "Workshops",
  //LectureList
  "events.LectureList.nothing.lectures": "There are currently no speeches",
  "events.LectureList.table.clock": "Clock",
  "events.LectureList.table.speaker": "Speaker",
  "events.LectureList.table.topic": "Topic",
  //WorkshopList
  "events.LectureList.nothing.WorkshopList": "There are currently no workshops",
  "events.LectureList.table.teacher": "Teacher",
  //////// stands /////////////////
  //StandDashboard
  "stands.StandDashboard.text.company": "Companies",
  "stands.StandDashboard.text.people": "Exhibitor",
  "stands.StandDashboard.Modal.invitation": "Meeting invitation",
  "stands.StandDashboard.Modal.invitation_sub": "After sending the invitation, you must wait for the meeting to be approved by the other party",
  "stands.StandDashboard.add-meeting.setting": "Meeting setting",
  "stands.StandDashboard.add-meeting.conversation": "Start a conversation",
  "stands.StandDashboard.filter.company": "Filtering between exhibition companies",
  "stands.StandDashboard.filter.parts": "Filter between exhibition members",
  //CompanyList
  "stands.CompanyList.Table.company": "Company Profile",
  "stands.CompanyList.Table.activity": "Field of Activity",
  "stands.CompanyList.Table.country": "Country",
  "stands.CompanyList.Table.count": "Number of Employees",
  "stands.CompanyList.TableCell.people": "People",
  "stands.CompanyList.TableCell.rate": "Visitors rating",
  //MemberFilter
  "stands.MemberFilter.form.company": "Company name",
  "stands.MemberFilter.form.position": "Position",
  "stands.MemberFilter.form.country": "Country",
  "stands.MemberFilter.form.ability": "Ability",
  "stands.MemberFilter.form.interest": "Interest",
  "stands.MemberFilter.form.family": "Last name",
  "stands.MemberFilter.form.spec": "Specifications",
  "stands.MemberFilter.form.categories": "Categories",
  //stand
  "stand.getfile": 'ّFile Download',
  //details
  "stands.details.StandDetails.morebtn": "More",
  "stands.details.phonenumber-title": "Contact phones",
  //Outlook
  "stands.details.Outlook.title": "Booth perspective",
  "stands.details.Outlook.hall": "Hall",
  "stands.details.Outlook.opportunity": "Opportunity",
  "stands.details.Outlook.member": "Member",
  "stands.details.Outlook.product": "Product",
  "stands.details.Tab.1": "Booth",
  "stands.details.Tab.2": "Attendees",
  "stands.details.Tab.3": "News",
  //Statistic
  "stands.details.Statistic.title": "Visitors",
  "stands.details.Statistic.visit": "Visit",
  "stands.details.Statistic.score": "Score",
  "stands.details.Statistic.conversation": "Conversation",
  "stands.details.Statistic.meeting": "Meeting",
  // Online
  "stands.details.Online.title": "exhibitors ",
  // StandDetails
  "stands.details.StandDetails.introduction": "Introduction",
  "stands.details.StandDetails.comments": "Comments",
  "stands.details.StandDetails.people": "Exhibitors",
  "stands.details.StandDetails.products": "Products & Services",
  "stands.details.StandDetails.conversations": "Conversations",
  "stands.details.StandDetails.cooperations": "Cooperations",
  //info
  "stands.info.introduction.call.tooltip": "Click to view",
  "stands.info.introduction.call": "Call",
  "stands.info.introduction.site": "WebSite",
  "stands.info.introduction.instagram": "Instagram",
  "stands.info.introduction.linkedin": "LinkedIn",
  "stands.info.comment.nothings1": "There is currently no dialogue!",
  "stands.info.comment.nothings2": "You start a conversation",
  "stands.info.comment.placeholder.enterText": "To join the conversation, write your comment here",
  "stands.info.comment.placeholder.enterText-minsize": "Join the conversation",
  "stands.info.products.nothings": "There are currently no products!",
  "stands.info.cooperation.nothings": "There is currently no collaboration!",
  "stands.info.cooperation.SpecificationsUser": "Cooperation profile",
  "stands.info.cooperation.SpecificationsColleague": "Type of cooperation",
  //product
  "product.productList.filter.products": "Filtering between exhibition products",
  "product.productList.page": "Page ",
  "product.productList.page.of": " Of ",
  "product.productList.infoprice": "",
  "product.detailedcomments.vote.title": "Rate this product as well",
  "product.detailedcomments.vote.subtitle": "Your rating and opinion can be seen by everyone",
  "product.detailedcomments.alert.nothing": "No comments have been posted yet!",
  "product.filter.alphabetical": "Alphabetical sort:",
  "product.filter.mostvisited": "Sort by highest score:",
  "product.comment.sendmsg": "Your rating and comments were sent successfully!",

  //Chat
  "chat.chatHeader.setVideoMeeting": "Set Video Meeting",
  "chat.sendBox.placeholder": "Write your message here",
  "chat.chattitle__subtitle.lastVisit": "Last Visit",
  //Exhibition
  "exhibition.coments.title": "Public Conversation",
  "exhibition.comments.subtitle": "This is a place to talk about this company. Join the conversation now",
  "exhibition.comments.subtitle-small": "Join the conversation now",
  "exhibition.comments.vote.comment1": "Based on Votes",
  // "exhibition.comments.vote.comment2": "Vote",
  "exhibition.comments.toastr": "Your message has been successfully sent",
  "exhibition.coopeationItem.opportunities": "Job Opportunity ",
  "exhibition.coopeationItem.opportunitiestwo": "Investment Opportunity",
  "exhibition.coopeationItem.create": "To submit a proposal, write a description here",
  "exhibition.cooperationItem.loading": "You have submitted a request for cooperation",
  "exhibition.cooperationItem.loadingtwo": "Submit a request for cooperation",
  "exhibition.cooperationItem.loadingThere": "Do you want to cancel this offer?",
  "exhibition.cooperationItem.loadingYes": "Yes, I cancel",
  "exhibition.cooperationItem.loadingNo": "No",
  "exhibition.cooperationItem.tehrann": "Tehran",
  "exhibition.exhibitionlist.title": "List of exhibitions",
  "exhibition.exhibition.tab1": "Introduction",
  "exhibition.exhibition.tab2": "Members",
  "exhibition.exhibition.tab3": "Products",
  "exhibition.exhibition.tab4": "Conversations",
  "exhibition.exhibition.tab5": "Cooperations",
  "exhibition.members.TableCell1": "First name and last name",
  "exhibition.members.TableCell2": "Job position",
  "exhibition.members.TableCell3": "Phone number",
  "exhibition.members.TableCell4": " Country",
  "exhibition.members.TableCell6": "Member Profile",
  "exhibition.members.TableCell5": "",
  "exhibition.rightSide.lable1": "All",
  "exhibition.rightSide.lable2": "job opportunity",
  "exhibition.rightSide.lable3": "investment opportunity",
  "exhibition.rightSide.switchLable": "Sort by most requests",
  "exhibition.rightSide.selectInput": "Type of cooperation",
  "exhibition.sendBox.placeHolder": "Write your message here",

  //Meeting
  "Meeting.meetingDashboard.meetingDashboard.tabLable1": "Invitations",
  "Meeting.meetingDashboard.meetingDashboard.tabLable2": "Calendar",
  "Meeting.meetingDashboard.meetingDashboard.tabPanel": "Invitations",
  "Meeting.meetingDashboard.meetingDashboard.tabPanel23": "Caller",
  "Meeting.meetingDashboard.meetingDashboard.noInventation": "No invitation has been sent",
  "Meeting.meetingDashboard.meetingDashboard.Today": "Today",
  "Meeting.meetingDashboard.meetingDashboard.noInventationtoday": "You have not received any invitations today",
  "Meeting.meetingDashboard.meetingDashboard.tabLable11": "Members section",
  "Meeting.meetingDashboard.meetingDashboard.setMeetingText": 'To set an appointment, select a member in the "Members" section and click "Request a meeting"',
  "Meeting.meetingDashboard.meetingInventation.month1": "January",
  "Meeting.meetingDashboard.meetingInventation.month2": "February",
  "Meeting.meetingDashboard.meetingInventation.month3": "March",
  "Meeting.meetingDashboard.meetingInventation.month4": "April",
  "Meeting.meetingDashboard.meetingInventation.month5": "May",
  "Meeting.meetingDashboard.meetingInventation.month6": "June",
  "Meeting.meetingDashboard.meetingInventation.month7": "July",
  "Meeting.meetingDashboard.meetingInventation.month8": "August",
  "Meeting.meetingDashboard.meetingInventation.month9": "September",
  "Meeting.meetingDashboard.meetingInventation.month10": "October",
  "Meeting.meetingDashboard.meetingInventation.month11": "November",
  "Meeting.meetingDashboard.meetingInventation.month12": "December",
  "Meeting.meetingDashboard.meetingInventation.status": "Accepted",
  "Meeting.meetingDashboard.meetingInventation.selectedPending": "Under investigation",
  "Meeting.meetingDashboard.meetingInventation.selectedRejected": "Rejected",
  "Meeting.meetingDashboard.meetingInventation.selectedAccepted": "Accepted",
  "Meeting.meetingDashboard.meetingInventation.inventationText": "Invitation :",
  "Meeting.meetingDashboard.meetingInventation.boxPlace": "Venue:",
  "Meeting.meetingDashboard.meetingInventation.boxContent": "Video",
  "Meeting.meetingDashboard.meetingInventation.boxHeading": "Participants",
  "Meeting.meetingDashboard.meetingInventation.selectedMeeting": "Not specified",
  "Meeting.meetingDashboard.meetingInventation.selectedDate": "Date",
  "Meeting.meetingDashboard.meetingInventation.loadingAccept": "Set new time",
  "Meeting.meetingDashboard.meetingInventation.loadingAccepttwo": "Accept",
  "Meeting.meetingDashboard.meetingInventation.send_confirm.title": "Do you want to send this invitation?",
  "Meeting.meetingDashboard.meetingInventation.send_confirm.agree": "Yes, I will",
  "Meeting.meetingDashboard.meetingInventation.send_confirm.disagree": "No",
  "Meeting.meetingDashboard.meetingInventation.accept_confirm.title": "Do you want to accept this session?",
  "Meeting.meetingDashboard.meetingInventation.accept_confirm.agree": "Yes, I accept",
  "Meeting.meetingDashboard.meetingInventation.accept_confirm.disagree": "No",
  "Meeting.meetingDashboard.meetingInventation.reject_confirm.title": "Are you sure you want to delete this session?",
  "Meeting.meetingDashboard.meetingInventation.reject_confirm.agree": "Yes, I reject it",
  "Meeting.meetingDashboard.meetingInventation.reject_confirm.disagree": "No",
  "Meeting.meetingDashboard.meetingInventation.reject_confirm.alert": "You missed this meeting!",
  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.title": "Do you want to suggest this new time for the meeting?",
  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.agree": "Yes, I confirm",
  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.disagree": "No",
  "Meeting.meetingDashboard.meetingInventation.accept_confirm.alert": "This session is set for you! We will let you know 15 minutes before the meeting starts.",
  "Meeting.meetingDashboard.meetingInventation.sendInvitation.emptymsg.alert": "The text of the invitation has been forgotten! Please fill in the invitation text",
  "Meeting.meetingDashboard.meetingInventation.sendInvitation": "Send invitation for a meeting",
  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.alert": "Your suggestion for a new meeting time has been successfully submitted.",
  "Meeting.meetingDashboard.meetingInventation.changeTime": "Time change",
  "Meeting.meetingDashboard.meetingInventation.changeTimetwo": "Cancel changes",
  "Meeting.meetingDashboard.meetingInventation.cancellRequest": "Cancel request",
  "Meeting.meetingDashboard.meetingInventation.alertOne": "You have already submitted a request for cooperation",
  "Meeting.meetingDashboard.meetingInventation.changetimeReject": "Reject",
  "Meeting.meetingDashboard.meetingInventation.statusInvitor": "Caller",
  "Meeting.meetingDashboard.meetingInventation.cancelInvitation": "Cancel post",
  "Meeting.meetingDashboard.meetingInventation.statusPendingReceive": "Waiting to receive",
  "Meeting.meetingDashboard.meetingInventation.timeNotSet": "Not specified",
  "Meeting.meetingDashboard.meetingList.tableTitle": "Meeting invitation",
  "Meeting.meetingDashboard.meetingList.subtitle": "After sending the invitation, you must wait for the meeting to be approved by the other party",
  "Meeting.meetingDashboard.workSheet.item": "Video session with",
  "Meeting.meetingDashboard.meetingInventation.link": "Video session login link",
  "Meeting.meetingDashboard.meetingInventationCreate.send-alert": "This invitation was sent successfully! Please wait for the other party to respond",
  "Meeting.meetingDashboard.meetingInventationCreate.checkcalender.currentUser": "The selected time is blocked for you! Choose another time",
  "Meeting.meetingDashboard.meetingInventationCreate.checkcalender": "The audience is not able to hold the meeting at the appointed time",

  //Opportunity
  "Oppotunity.OppotunityDashboard.searchtitle.job": "Filtering between job opportunities",
  "Oppotunity.OppotunityDashboard.searchtitle.investment": "Filter between investment opportunities",
  "Oppotunity.allOportunities.jobPosition": "Job position",
  "Oppotunity.allOportunities.investment": "Investment offers",
  "Oppotunity.allOportunities.company": "Company",
  "Oppotunity.allOportunities.City": "City",
  "Oppotunity.allOportunities.Category": "Category",
  "Oppotunity.companyFilter.category": "Field of activity",
  "Oppotunity.companyFilter.alphabetical": "Sort by alphabetical",
  "Oppotunity.companyFilter.subcategory": "Categories",
  "Oppotunity.companyFilter.country": "Country",
  "Oppotunity.memberFilter.subcategory": "Categories",
  "Oppotunity.companyFilter.country": "Country",
  "Oppotunity.memberFilter.subcategory": "Categories",
  "Oppotunity.memberFilter.country": "Country",
  "Oppotunity.memberFilter.ability": "Ability",
  "Oppotunity.memberFilter.interest": "Specialized interest",
  "Oppotunity.opportunityDashboard.tab1": "All opportunities",
  "Oppotunity.opportunityDashboard.tab2": "My suggestions",
  "Oppotunity.allOportunities.sendrequest_investment": 'International investment opportunity',
  "Oppotunity.allOportunities.sendrequest_job": 'Marketing Partner',
  "Oppotunity.opportunityItem.description": "Here is a more detailed description of an investment opportunity and various aspects of the requested investment opportunity are displayed.",
  "Oppotunity.opportunityItem.createDescription": "Write your description here to send suggestions",
  "Oppotunity.opportunityDashboard.tabLable1": "All opportunities",
  "Oppotunity.opportunityDashboard.tabLable2": "My suggestions",
  "Oppotunity.opportunityDashboard.company": "Company",
  "Oppotunity.opportunityDashboard.location": "Location",
  "Oppotunity.opportunityDashboard.location.tehran": "Tehran",
  "Oppotunity.opportunityDashboard.switch": "Sort by most suggestions",
  "Oppotunity.allOportunities.alert": "You have no suggestions right now!",
  "Oppotunity.sendrequest.requestbox": "To submit a proposal, write a description here",
  "Oppotunity.item.btn-cancele": "cancel request",
  "Oppotunity.item.btn-sendrequest": "Request sent",
  "Oppotunity.item.delete-btn.alert": "Your offer was successfully canceled!",
  "Oppotunity.item.btn-sendrequest-alert_title": "",
  "Oppotunity.item.btn-sendrequest-alert_desc_job": `To create a "Job Opportunities" section at the booth, please download the file opposite, fill it out in full, and upload the final file in the next section.`,
  "Oppotunity.item.btn-sendrequest-alert_desc_invesment": `To create the "Investment Opportunity" section in the booth exhibition, please download the file opposite, prepare it in full, and upload the final file in the next section.`,
  "Oppotunity.item.btn-sendrequest-alert_download": "file Download",
  "Oppotunity.item.btn-sendrequest-upload": "Touch here and upload the file",
  "Oppotunity.item.btn-sendrequest-upload_alertformat": "Uploaded file format is not approved.",
  "Oppotunity.sendrequest.confirm": "Do you want to submit this offer?",
  "Oppotunity.sendrequest.agree": "Yes, I will",
  "Oppotunity.sendrequest.disagree": "No",


  //Profile
  "Profile.profileAvatar.title1": "Profile image",
  "Profile.profileDetails.profileInfo.h2": "Basic Information",
  "Profile.profileDetails.profileInfo.h2.hint": "This information is displayed to everyone ",
  "Profile.profileDetails.profileInfo.general.offer.h3": "What can I suggest",
  "Profile.profileDetails.profileInfo.general.need.h3": "What am I looking for",
  "Profile.profileDetails.profileInfo.general.h3": "What can I suggest",
  "Profile.profileDetails.profileInfo.companyName": "Company Name",
  "Profile.profileDetails.profileInfo.jobPosition": "Job position",
  "Profile.profileEditExtra.textInput.lable1": "City",
  "Profile.profileEditExtra.textInput.lable2": "Email",
  "Profile.profileEditExtra.textInput.lable3": "Linkedin",
  "Profile.profileEditExtra.textInput.lable4": "Instagram",
  "Profile.profileEditExtra.textInput.loading": "Save information",
  "Profile.profileEditExtra.modal.title": "Basic Information",
  "Profile.profileEditExtra.modal.subTitle": "Others can see this information",
  "Profile.profileEditExtra.generalDrawer.title": "",
  "Profile.profileEditExtra.generalDrawer.subTitle": "Others can see this information",
  "Profile.profileEditExtra.addalert": "Your basic information has been successfully updated.",
  "Profile.profileChangePassword.Modal.title": 'Modify the user page',
  "Profile.profileChangePassword.Modal.subtitle": 'If you forget your current password, forget the password',
  "Profile.profileChangePassword.length": 'A valid password is at least 6 characters long',
  "Profile.profileChangePassword.alert.lastpassword": 'The old password is incorrect',
  "Profile.profileEditInfo.loading": "Save information",
  "Profile.profileEditInfo.Modal.title": "Edit user information",
  "Profile.profileEditInfo.addalert": "Your user information was successfully updated.",
  "Profile.profileHeader.button1": "Edit information",
  "Profile.profileHeader.button2": "Change password",
  "Profile.profilePassword.Modal.subtitle": "If you have forgotten your current password, enter it in the Forgotten Login section",
  "Profile.profileuploader.title": "Drag and drop the image to this area",
  "Profile.profileuploader.upload": 'Upload',
  "Profile.profileuploader.delete": 'Delete',
  "Profile.profileuploader.uploadimg.alert": 'User photo updated successfully.',
  "Profile.profileuploader.uploadimg.deletealert": 'User photo successfully deleted',
  "Profile.profileuploader.confirm.deletavatar": 'Do you want to delete your user`s photo?',
  "Profile.profileuploader.confirm.deletavatar.agree": 'Yes, I will delete',
  "Profile.profileuploader.confirm": 'Do you want to select this photo as your user photo?',
  "Profile.profileuploader.agree": 'Yes, I confirm',
  "Profile.profileuploader.disagree": 'No',
  "Profile.profileuploader.save": 'Save',

  //Vote
  "vote.btn-submit": "Submit comments and points",
  "vote.btn-cancel": "Cancel",

  // Common Pages
  "Common.notFound.title": "the page you are looking for cannot be found",


  //Landing
  "Landing.firstsection.bar.btn-Register": "Register",
  "Landing.firstsection.bar.btn-login": "Login",
  "Landing.firstsection.bar.btn-exhibition": "exhibitions list",
  "Landing.firstsection.bar.btn-online-demo": "Demo request",
  "Landing.firstsection.bar.btn-lang": "فا",
  "Landing.firstsection.rightside.title": "Namaline,Online exhibition platform",
  "Landing.firstsection.rightside.desc":"Welcome to the new generation of exhibitions. With the help of Namaline, you can hold your physical and virtual exhibitions smartly or participate in the smart exhibitions that are being held.",
  "Landing.firstsection.rightside1.desc":"Welcome to the new generation of exhibitions.",
  "Landing.firstsection.rightside2.desc":"With the help of Namalain, you can hold your physical and virtual exhibitions smartly or participate in the smart exhibitions that are being held.",


  "Landing.protocol.title": 'How do they work?',
  "Landing.protocol.btngroup.sec1": "visitors",
  "Landing.protocol.btngroup.sec2": "Organizers",
  "Landing.protocol.organizer.sec1.title": "Build an exhibition",
  "Landing.protocol.organizer.sec1.desc": "To begin, the organizer must create the exhibition. He outlines the exhibition and the start and end times",
  "Landing.protocol.organizer.sec2.title": "Registration of booths",
  "Landing.protocol.organizer.sec2.desc": "The exhibitor registers and enters the sample. It then determines the desired tariff for participating in the exhibition.",
  "Landing.protocol.organizer.sec3.title": "Confirmation of booths",
  "Landing.protocol.organizer.sec3.desc": "It then selects the appearance of its booth, enters the booth details, members and products, and pays the bill.",
  "Landing.protocol.organizer.sec4.title": "Opening of the exhibition",
  "Landing.protocol.organizer.sec4.desc": "Upon the opening time, the exhibition will be available to everyone and visitors can visit the exhibition",
  "Landing.protocol.view.sec1.title": "Exhibition Search",
  "Landing.protocol.view.sec1.desc": "To begin, the organizer must create the exhibition. He outlines the exhibition and the start and end times",
  "Landing.protocol.view.sec2.title": "Registration and login",
  "Landing.protocol.view.sec2.desc": "The exhibitor registers and enters the sample. It then determines the desired tariff for participating in the exhibition.",
  "Landing.protocol.view.sec3.title": "Booth Search",
  "Landing.protocol.view.sec3.desc": "It then selects the appearance of its booth, enters the booth details, members and products, and pays the bill.",
  "Landing.protocol.view.sec4.title": "Interaction and communication",
  "Landing.protocol.view.sec4.desc": "Upon the opening time, the exhibition will be available to everyone and visitors can visit the exhibition",
  "Landing.protocol.footer.title-view": 'If you are a visitor, register here',
  "Landing.protocol.footer.title-organazer": 'If you are an organizer, register here',


  "Landing.Pavilions.title": 'List of exhibitions',
  "Landing.Pavilions.title.login": 'Select your desired exhibition.',
  "Landing.Pavilions.more": 'See all',
  "Landing.Pavilions.moree": 'See',
  "Landing.Pavilions.cardbtn": 'View the exhibition',
  "Landing.pavilioncard.title.oncoming": 'Soon',
  "Landing.pavilioncard.title.onregister": 'Registering',
  "Landing.Pavilions.cardbtn.onregister": 'Booth registration',
  "Landing.pavilioncard.title.onperforming": 'On performing',
  "Landing.pavilioncard.title.onpast": 'The exhibition is over',


  "Landing.about.title": 'Product Specifications',
  "Landing.about.sec1.title": 'Visual identification',
  "Landing.about.sec1.desc": "You can design your booth, hall and even the exhibition city in terms of color, shape and size, as you wish.",
  "Landing.about.sec2.title": 'Collaboration opportunities',
  "Landing.about.sec2.desc": "In the job market booth, booth job opportunities are displayed so that you can more easily hire or find the job you are looking for.",
  "Landing.about.sec3.title": 'Workshop and lecture',
  "Landing.about.sec3.desc": "The venue for video conferences, workshops, seminars and any other online event is easily provided in Namaline.",


  "Landing.appdescription.title": 'A new experience of participating in a virtual exhibition',
  "Landing.appdescription.desc": 'You will have a new experience of participating in the exhibition with Namaline, using this software on all devices may easily be accepted and finalized.',
  "Landing.appdescription.btn": 'software installation',

  "Landing.video-help.title": "Participate in the exhibition, smarter than ever!",
  "Landing..video-help.desc": "Do you know what future exhibitions look like and how they will change the experience of participating in the exhibition? Watch this video to get acquainted with the new generation of exhibitions.",

  "Landing.statistics.title": 'Exhibition statistics',
  "Landing.statistics.sec1.title": 'visitors',
  "Landing.statistics.sec2.title": 'Exhibition',
  "Landing.statistics.sec3.title": 'Exhibitor',
  "Landing.statistics.sec4.title": 'meeting',

  "Landing.Customers.title": 'Our Customers',

  "Landing.footer.logo": 'Iran Online Exhibition',
  "Landing.footer.help": 'user manual',
  "Landing.footer.about": 'about us',
  "Landing.footer.contact": 'contact us',
  "Landing.footer.term": 'Terms and Conditions',
  "Landing.footer.namaline": 'All material and intellectual rights of this work are reserved for Namaline',

  "Landing.registerform.sec1": 'Visitor registration',
  "Landing.registerform.sec2": 'Exhibitor registration',
  "Landing.registerform.sec3": 'Organizer registration',
  "Landing.registerform.sec4": 'Sponsor registration',
  "Landing.loginform.sec1": 'Visitor/exhibitor login',
  "Landing.loginform.sec2": 'Booth manager login',
  "Landing.loginform.sec3": 'Organizer login',
  "Landing.loginform.sec4": 'Sponsor entry',

  //Meeting
  "Meeting.alert.pending": 'pending',
  "Meeting.alert.rejected": 'rejected',
  "Meeting.alert.accepted": 'accepted',
  "Meeting.toast.success": 'This invitation was sent successfully! Please wait for the other party to respond ',
  //general
  "General.person": 'Person',
  "General.page": 'Page',
  "General.from": 'From',
  "General.alert.toast.errorServer": 'Problem with server',
  "General.loading.component": 'Reading information from the server ...',

  //about
  "About.header.title": 'About Us',
  "About.header.button": 'Join us',
  "About.header.text": 'Everything you need to know about our team!',
  "About.tabBox.label1": 'User Manual',
  "About.tabBox.label2": 'About us',
  "About.tabBox.label3": 'Contact us',
  "About.tabBox.label4": 'Rules and Terms',
  "About.sectionOne.title1": 'Exhibition and more from the exhibition!',
  "About.sectionOne.title2": 'Network and arrange business meetings',
  "About.sectionOne.title3": 'and get performance reports',
  "About.sectionOne.text": `Namaline is a platform for online exhibitions where in addition to
  showing your business and products to relevant people in your field,
  you can arrange a business meeting with visitors, in the Job Opportunities section
  look for the right people to join the team and report in the end get an
  intelligent report about visitors and your performance during online exhibition.`,
  "About.sectionOne.signup.btn": 'Register',

  "About.jobBackground.title": 'It all started here!',
  "About.jobBackground.text": `It all started in April of 1999; When in the midst of an outbreak
  of the corona virus and getting online of many of evertday activities, ideas
  Creating the infrastructure for holding the country's exhibitions online, brought couple of people
  together to start a new startup in September 99
  to establish. The purpose of creating this is something beyond holding
  the exhibition online; That enthusiasts across the country as well
  people living outside of Iran can experience  the best
  the country's international exhibitions and everyone, regardless of
  Geographical borders, have the possibility of visiting the exhibition and holding business meetings
  with the present organizations.`,
  "About.team.title": 'Meet our team!',
  "About.team.text": 'People who work together to hold online exhibitions as well as achieve their goals:',
  "About.team.name.navid": 'Navid Omidian',
  "About.team.name.milad": 'Milad Jafary',
  "About.team.name.mohammadreza": 'MohammadReza',
  "About.team.name.venus": 'Venous Jalaly',
  "About.team.name.majid": 'Majid Taghadosinia',
  "About.team.name.mohammad": 'Mohammad Gervei',

  "About.team.position.navid": 'Co-founder and CEO',
  "About.team.position.milad": 'Co-founder and technical senior',
  "About.team.position.mohammadreza": 'Co-founder and chief product',
  "About.team.position.venus": 'Co-founder and senior graphic designer',
  "About.team.position.majid": 'Senior of Operations',
  "About.team.position.mohammad": 'Co-founder and senior of the business',

  //faq
  "Faq.header.title": 'Do you have a question?',
  "Faq.header.text": 'Or watch the product usage guide',
  "Faq.header.input": 'Enter the keyword here',
  "Faq.body.title": 'Frequently Asked Questions',
  "Faq.body.subtitle": 'Your answer may be here!',




  //contact
  "Contact.header.title": 'Contact us',
  "Contact.header.text": 'Quick communication with the support team',
  "Contact.header.chatbtn": 'Online chat',
  "Contact.header.callbtn": 'Call',
  "Contact.body.address": 'Tehran - North Kargar St. - Corner of Jalal Al-Ahmad Highway - Faculty of Engineering, University of Tehran ',

  //terms
  "Terms.header.title": 'Looking for a specific topic?',
  "Terms.header.text": 'Or search the rules below',
  "Terms.header.input": 'Enter the keyword here',
  "Terms.body.title": 'Terms and Conditions',
  "Terms.body.subtitle": 'To use Namaline',


  //tour guide
  "TourGuide.halls.title": '',
  "TourGuide.halls.desc": '',
  "TourGuide.pavillions.title": '',
  "TourGuide.pavillions.desc": '',
  "TourGuide.products.title": '',
  "TourGuide.products.desc": '',
  "TourGuide.seminar.title": '',
  "TourGuide.seminar.desc": '',
  "TourGuide.meeting.title": '',
  "TourGuide.meeting.desc": '',
  "TourGuide.Button.Ok": ''
}
