import React from 'react'
import { Field } from 'formik';
import {
    TextField,
} from '@material-ui/core';
const TextInput = ({ error, label, onBlur, onChange, value, helperText, className, name, type }) => {
    return (
        <Field
            component={TextField}
            error={error}
            fullWidth
            helperText={helperText}
            className={`text-input ${className}`}
            label={label}
            margin="normal"
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            type={type}
            value={value}
            variant="outlined"
        />
    )
}

export default TextInput
