import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './_auth.js'
import {
    Box,
    IconButton,
    InputAdornment,
    TextField,
    CircularProgress,
    Link,
    Button,
    Button as MaterialButton
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { registerUser } from '../../app/store/actions/authActions.js';
import { Dots, CheckInput, TextInput } from '../../component'
//phone
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
/*************change lang ***************/
import MessageLang from '../../lang/';
/*************change lang ***************/

const RegisterStepThree = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.async);
    const lang = useSelector((state) => state.lang.lang);
    const [label, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });
    const [is_terms, set_is_terms] = React.useState(false)
    const handleClickShowPassword = () => {
        setValues({ ...label, showPassword: !label.showPassword });
    };
    return (
        <Formik
            initialValues={
                {
                    password: '',
                    password_confirmation: '',
                    phone_number: '',
                    email: '',
                    state: lang,
                }}
            validationSchema={lang === 'fa' ?
                Yup.object({
                    password: Yup.string().required(<MessageLang id="form.verify.login.password" />).min(6, <MessageLang id="Profile.profileChangePassword.length" />),
                    password_confirmation: Yup.string().required(<MessageLang id="form.verify.login.two_password" />).min(6, <MessageLang id="Profile.profileChangePassword.length" />)
                        .oneOf([Yup.ref('password'), null], <MessageLang id="form.verify.login.authpassword" />),
                    email: Yup.string().email(<MessageLang id="form.verify.register.email-valid" />),
                })
                :
                Yup.object({
                    phone_number: Yup.string().required(<MessageLang id="form.verify.register.phone" />),
                    password: Yup.string().required(<MessageLang id="form.verify.login.password" />).min(6, <MessageLang id="Profile.profileChangePassword.length" />),
                    password_confirmation: Yup.string().required(<MessageLang id="form.verify.login.two_password" />).min(6, <MessageLang id="Profile.profileChangePassword.length" />)
                        .oneOf([Yup.ref('password'), null], <MessageLang id="form.verify.login.authpassword" />)
                })
            }
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                    await dispatch(registerUser(values))
                    setSubmitting(false);
                } catch (error) {
                    setErrors({ auth: <MessageLang id="form.verify.login.authpassword" /> });
                    setSubmitting(false);
                }
            }}
        >
            {({
                submitForm,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values, isValid, dirty
            }) => (
                <Form className='ui form countrycode termmood'>
                    <div className="auth__subtitle" >
                        <MessageLang id="form.label.register.stepThreePassword" />
                    </div>
                    {errors.auth && <span className={'error-alert'}>{errors.auth}</span>}
                    {lang === "en" &&
                        <PhoneInput
                            error={Boolean(touched.phone_number && errors.phone_number)}
                            helperText={touched.phone_number && errors.phone_number}
                            className={classes.labelText}
                            margin="normal"
                            name="phone_number"
                            onBlur={handleBlur}
                            onChange={handleChange('phone_number')}
                            type="number"
                            value={values.phone_number}
                            variant="outlined"
                            country='ir'
                            autoFormat={true}
                            enableSearch={true}
                            disableSearchIcon={false}
                            autocompleteSearch={true}
                            countryCodeEditable={false}
                            specialLabel={<MessageLang id="form.label.login.phone" />}
                            inputProps={{
                                required: true,
                                autoFocus: true,
                            }}
                            isValid={(inputNumber, country, countries) => {
                                // setcode(country.dialCode)
                            }}
                        />
                    }
                    <Field
                        component={TextField}
                        id="outlined-adornment-password"
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label={<MessageLang id="form.label.login.password" />}
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange('password')}
                        // className={classes.textField}
                        variant="outlined"
                        type={label.showPassword ? 'text' : 'password'}
                        value={values.password}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword}>
                                        {label.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Field
                        component={TextField}
                        id="outlined-adornment-password"
                        error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                        fullWidth
                        helperText={touched.password_confirmation && errors.password_confirmation}
                        label={<MessageLang id="form.label.register.password_replay" />}
                        margin="normal"
                        name="password_confirmation"
                        onBlur={handleBlur}
                        onChange={handleChange('password_confirmation')}
                        // className={classes.textField}
                        variant="outlined"
                        type={label.showPassword ? 'text' : 'password'}
                        value={values.password_confirmation}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword}>
                                        {label.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {lang === "fa" &&
                        <TextInput
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            className={classes.labelText}
                            label={<MessageLang id="Profile.profileEditExtra.textInput.lable2" />}
                            margin="normal"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange('email')}
                            type="text"
                            value={values.email}
                        />
                    }
                    <CheckInput
                        label={<LabelTerms />}
                        checked={is_terms}
                        onChange={() => set_is_terms(val => !val)}
                    />
                    <Box my={2}>
                        {is_terms ?
                            <Button
                                fullWidth
                                className='bluebtn'
                                size="large"
                                onClick={submitForm}
                            >
                                {loading ? <CircularProgress style={{ color: '#fff' }} size={26} /> : <MessageLang id="register.panel.btn.loginHall" />}
                            </Button>
                            :
                            <MaterialButton
                                disabled={true}
                                className='disabledbtn'
                                primary
                                fullWidth
                                size="large"
                                variant="contained"
                                label={<MessageLang id="register.panel.btn.loginHall" />}
                            >
                                <MessageLang id="register.panel.btn.loginHall" />
                            </MaterialButton>
                        }
                    </Box>
                    <Dots stepThree />
                </Form>
            )
            }
        </Formik >
    );
};

const LabelTerms = () => {
    const { lang } = useSelector((state) => state.lang);
    if (lang === "en") return (
        <>
            <MessageLang id="form.label.register.terms" />
            {" "}
            <Link href="/terms" color="secondary">
                <MessageLang id="Landing.footer.term" />
            </Link>
            {" "}
            <MessageLang id="form.label.register.terms2" />
        </>
    )
    return (
        <>
            <Link href="/terms" color="secondary">
                <MessageLang id="Landing.footer.term" />
            </Link>
            {" "}
            <MessageLang id="form.label.register.terms" />
        </>
    )
}

export default RegisterStepThree;
