import {
    FETCH_MEETINGS,
    FETCH_TODAY_MEETINGS,
    FETCH_CALENDERS,
    FETCH_SELECTED_MEETING,
    FETCH_RANGE
} from '../constants/Constants';

const initialState = {
    meetings: [],
    loading: true,
    todayMeetings: [],
    todayLoading: true,
    calenders: [],
    calenderLoading: true,
    selectedMeeting: {},
    loadingSelectedMeeting: true,
    ranges: []
};

export default function meetingReducer(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_MEETINGS:
            return {
                ...state,
                meetings: payload,
                loading: false
            };
        case FETCH_TODAY_MEETINGS:
            return {
                ...state,
                todayMeetings: payload,
                todayLoading: false
            };
        case FETCH_RANGE:
            return {
                ...state,
                ranges: payload
            };
        case FETCH_SELECTED_MEETING:
            return {
                ...state,
                selectedMeeting: payload,
                loadingSelectedMeeting: false
            };
        case FETCH_CALENDERS:
            return {
                ...state,
                calenders: payload
            };

        default:
            return state;
    }
}