import {
    ADD_PRODUCT,
    CLEAR_PRODUCT_FILTER,
    FETCH_PRODUCTS,
    SET_PRODUCT_FILTER,
    SET_SELECTED_PRODUCT,
    SET_SELECTED_PRODUCT_COMMENTS
} from '../constants/Constants';

const initialState = {
    companies: [],
    comments: [],
    loadedProducts: [],
    moreProducts: true,
    selectedProduct: null,
    selectedComments: [],
    lastVisible: null,

    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: null,

    filter: {
        company: 0,
        category: 0,
        subcategory: 0,
        q: '',
        page: 1,
        limit: 5,
        alphabetical: 0,
        mostVisited: 0
    },
};

export default function productReducer(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_PRODUCTS:
            return {
                ...state,
                companies: [...payload.companies],
                total: payload.total,
            };
        case SET_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: payload
            }
        case SET_SELECTED_PRODUCT_COMMENTS:
            return {
                ...state,
                selectedComments: payload
            }
        case ADD_PRODUCT:
            return {
                ...state,
                loadedProducts: [...state.loadedProducts, payload]
            }
        case SET_PRODUCT_FILTER:
            if (payload.filter_type === 'category')
                return {
                    ...state, filter: {
                        ...state.filter, category: payload.value, subcategory: 0, page: 0
                    }
                }
            else if (payload.filter_type === 'sub_ategory')
                return {
                    ...state, filter: {
                        ...state.filter, subcategory: payload.value, page: 0
                    }
                }
            else if (payload.filter_type === 'company')
                return {
                    ...state, filter: {
                        ...state.filter, company: payload.value, page: 0
                    }
                }
            else if (payload.filter_type === 'search')
                return {
                    ...state, filter: {
                        ...state.filter, q: payload.value, page: 0
                    }
                }
            else if (payload.filter_type === 'page')
                return {
                    ...state, filter: {
                        ...state.filter, page: payload.value
                    }
                }
            else if (payload.filter_type === 'limit')
                return {
                    ...state, filter: {
                        ...state.filter, limit: payload.value, page: 0
                    }
                }
            else if (payload.filter_type === 'alphabetical')
                return {
                    ...state, filter: {
                        ...state.filter, alphabetical: payload.value, page: 0
                    }
                }
            else if (payload.filter_type === 'mostVisited')
                return {
                    ...state, filter: {
                        ...state.filter, mostVisited: payload.value, page: 0
                    }
                }
            else return state
        case CLEAR_PRODUCT_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    category: 0,
                    subCategory: 0,
                    company: 0,
                    page: 1,
                    limit: 5,
                }
            }
        default:
            return state;
    }
}