////////// general //////////////////

////////// company //////////////////

//////////form //////////////////
//////////label //////////////////
//////////verify //////////////////

////////// login //////////////////

////////// register //////////////////




// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ////////// general //////////////////
  "general.copyright": "تمامی حقوق برای این قالب صبا محفوط است © 2018",

  //////////  company //////////////////
  "company.name": "نمالاین",

  "general.select.lang": "انتخاب زبان",
  ////////// form //////////////////

  /////////// header /////////////
  "Expo.header.next": "بعدی",
  "Expo.header.previous": "قبلی",

  //////////////////////////////////
  "stands.actions.link-copied": "لینک کپی شد.",
  ////////// label //////////////////
  "form.label.login.withPhoneAndEmail": "شماره موبایل یا ایمیل",
  "form.label.login.phone": "شماره موبایل",
  "form.label.login.password": "رمز عبور",
  "form.label.login.login": "ورود به نمالاین",
  "form.label.login.forgetpass": "رمز عبورم را فراموش کرده ام",
  "form.label.login.signup": "کاربر جدید هستید ؟ ثبت نام کنید",
  "form.label.register.name": "نام",
  "form.label.register.family": "نام خانوادگی",
  "form.label.register.company": "نام شرکت",
  "form.label.register.btn_signup": "ثبت نام کنید",
  "form.label.register.btn_verify_phone": "تایید شماره موبایل",
  "form.label.register.code_one": "کد ارسالی به ",
  "form.label.register.code_two": "را اینجا وارد کنید",
  "form.label.register.trycode": "ارسال دوباره کد",
  "form.label.register.stepThreePassword": "حالا فقط یک رمز عبور لازم دارید",
  "form.label.register.lastpassword": " رمز عبور فعلی",
  "form.label.register.password": " رمز عبور جدید",
  "form.label.register.password_replay": "تکرار رمز عبور جدید",
  "form.label.register.terms": "را مطالعه کردم،آن را می‌پذیرم",
  "form.label.forgetpass.btn": "کد را ارسال کن",
  "form.label.forgetpass.btn_verify_code": "تایید کد",
  "form.label.placeholder.enterText": "پیام خود را اینجا بنویسید",
  "form.label.placeholder.enterMeetingText": "متن دعوتنامه را در این قسمت وارد کنید",
  "form.label.placeholder.search": "جستجو...",
  "form.label.placeholder.select": "انتخاب...",

  "form.pagenavigation": "تعداد سطر قابل نمایش",
  "notification.head": "اطلاع‌رسانی‌ها",

  ////////// verify //////////////////
  "form.verify.login.phone": "شماره تماس  خود را وارد کنید",
  "form.verify.login.password": "رمز عبور خود را وارد کنید",
  "form.verify.login.two_password": "تکرار رمز عبور خود را وارد کنید",
  "form.verify.login.authpassword": "تکرار رمز عبور نادرست است",
  "form.verify.login.auth": "شماره موبایل یا رمزعبور نادرست است.",
  "form.verify.register.name": "نام خود را وارد کنید",
  "form.verify.register.lastname": "نام خانوادگی خود را وارد کنید",
  "form.verify.register.phone": "شماره موبایل خود را وارد کنید",
  "form.verify.register.code": "کد کشور خود را وارد کنید",
  "form.verify.register.email": "ایمیل خود را وارد کنید",
  "form.verify.register.auth": "کد وارد شده نادرست است! دوباره امتحان کنید",
  "form.verify.register.authpassword": "رمز عبور با فیلد تکرار مطابقت ندارد",
  "form.verify.register.authStepThree": "ثبت نام با مشکل مواجه شد، لطفا دوباره اقدام کنید",
  "form.verify.register.email-valid": "ایمیل وارد شده معتبر نمی باشد",


  ////////// login //////////////////
  "login.message": 'عزیز به نمالاین خوش‌ آمدی ',
  "login.panel.head.title": "یک بازدید کننده حرفه ای باشید!",
  "login.panel.head.text": "جدیدترین محصولات را ببینید، در همایش های تخصصی شرکت کنید، جلسات ویدئویی ترتیب بدهید، به گفتگوهای جمعی بپیوندید و از چرخیدن در فضای غرفه ها لذت ببرید.",
  "login.panel.head.welcome": "به نمایشگاه آنلاین ایران خوش آمدید",

  ////////// register //////////////////
  "register.panel.head.title": "خودت را به همه معرفی کن !",
  "register.panel.head.text": "جدیدترین محصولات را ببینید، در همایش های تخصصی شرکت کنید، جلسات ویدئویی ترتیب بدهید، به گفتگوهای جمعی بپیوندید و از چرخیدن در فضای غرفه ها لذت ببرید.",
  "register.panel.head.form_head": "در کمتر از یک دقیقه ثبت نام کنید",
  "register.panel.btn.loginHall": "ورود به نمایشگاه",

  ///////// forgetpass /////////////////
  "forgetpass.panel.head.title": "رمز عبور را فراموش کردید ؟",
  "forgetpass.panel.head.subtext1": "کافیه شماره موبایلی که با آن ثبت‌نام کردید را وارد کنید!",
  "forgetpass.panel.head.subtext2": "کافیه ایمیلی که با آن ثبت‌نام کردید را وارد کنید!",
  "forgetpass.sendcode.alert": "کد جدید با موفقیت ارسال شد.",

  //////// DashboardLayout /////////////////
  //header
  "DashboardLayout.header.logout.confirm": 'آیا می‌خواهید از این حساب خارج شوید؟',
  "DashboardLayout.header.logout.agree": 'بله، خارج می‌شوم',
  "DashboardLayout.header.logout.disagree": 'خیر',

  "DashboardLayout.header.products": "محصولات و خدمات",
  "DashboardLayout.header.meetings": "جلسات",
  "DashboardLayout.header.exhibition": "نمایشگاه",
  "DashboardLayout.header.events": "رویدادها",
  "DashboardLayout.header.events2": "لیست نمایشگاه‌ها",
  "DashboardLayout.header.events2.btn": "مشاهده‌ی بیشتر",
  "DashboardLayout.header.conversations": "گفتگوها",
  "DashboardLayout.header.booths": "غرفه‌ها",
  "DashboardLayout.header.conference_hall": "سالن همایش",
  "DashboardLayout.header.user_page": "صفحه‌ی کاربر",
  "DashboardLayout.header.pearl_petrochemical": "پتروشیمی مروارید",
  "DashboardLayout.header.jobs": "فرصت‌های شغلی",
  "DashboardLayout.header.investment": "فرصت‌های سرمایه گذاری",
  "DashboardLayout.header.profile": "حساب من",
  "DashboardLayout.header.logout": "خروج",
  "DashboardLayout.header.investment.opportunities": "بازار سرمایه",
  "DashboardLayout.header.job.opportunities": "بازار کار",
  "DashboardLayout.header.logout.alert": "شما با موفقیت از حساب کاربری خارج شدید",


  //////// events /////////////////

  //EventDashboard
  "events.EventDashboard.tabnav.lectures": "سخنرانی ها",
  "events.EventDashboard.tabnav.workshops": "کارگاه ها",
  //LectureList
  "events.LectureList.nothing.lectures": "در حال حاضر هیچ سخنرانی ای وجود ندارد",
  "events.LectureList.table.clock": "ساعت",
  "events.LectureList.table.speaker": "سخنران",
  "events.LectureList.table.topic": "موضوع",
  //WorkshopList
  "events.LectureList.nothing.WorkshopList": "در حال حاضر هیچ کارگاهی وجود ندارد",
  "events.LectureList.table.teacher": "مدرس",
  //////// stands /////////////////
  //StandDashboard
  "stands.StandDashboard.text.company": "شرکت ها",
  "stands.StandDashboard.text.people": "غرفه داران",
  "stands.StandDashboard.Modal.invitation": "دعوتنامه جلسه",
  "stands.StandDashboard.Modal.invitation_sub": "پس از ارسال دعوتنامه، باید منتظر تایید جلسه از سوی طرف مقابل باشید",
  "stands.StandDashboard.add-meeting.setting": "تنظیم جلسه",
  "stands.StandDashboard.add-meeting.conversation": "شروع گفتگو",
  "stands.StandDashboard.filter.company": "فیلتر کردن بین شرکت های نمایشگاه",
  "stands.StandDashboard.filter.parts": "فیلتر کردن بین اعضا نمایشگاه",
  // CompanyList
  "stands.CompanyList.Table.company": "مشخصات شرکت",
  "stands.CompanyList.Table.activity": "زمینه فعالیت",
  "stands.CompanyList.Table.country": "کشور",
  "stands.CompanyList.Table.counter": "تعداد کارمندان",
  "stands.CompanyList.TableCell.people": "نفر",
  "stands.CompanyList.TableCell.rate": "امتیاز بازدید کنندگان",
  // MemberFilter
  "stands.MemberFilter.form.company": "نام شرکت",
  "stands.MemberFilter.form.position": "جایگاه شغلی",
  "stands.MemberFilter.form.country": "کشور",
  "stands.MemberFilter.form.ability": "توانایی",
  "stands.MemberFilter.form.interest": "علاقمندی تخصصی",
  "stands.MemberFilter.form.family": "نام و نام خانوادگی",
  "stands.MemberFilter.form.spec": "مشخصات",
  "stands.MemberFilter.form.categories": "دسته بندی ها",
  //stand
  "stand.getfile": 'دانلود فایل',
  //details
  "stands.details.StandDetails.morebtn": "بیشتر",
  "stands.details.phonenumber-title": "تلفن های تماس",
  // Outlook
  "stands.details.Outlook.title": "دورنمای غرفه",
  "stands.details.Outlook.hall": "سالن",
  "stands.details.Outlook.opportunity": "فرصت ها",
  "stands.details.Outlook.member": "غرفه دار",
  "stands.details.Outlook.product": "محصول",
  "stands.details.Tab.1": "غرفه",
  "stands.details.Tab.2": "آنلاین‌ها",
  "stands.details.Tab.3": "اخبار",
 

  // Statistic
  "stands.details.Statistic.title": "بازدید کننده ها",
  "stands.details.Statistic.visit": "بازدید",
  "stands.details.Statistic.score": "امتیاز",
  "stands.details.Statistic.conversation": "گفتگو",
  "stands.details.Statistic.meeting": "ملاقات",
  // Online
  "stands.details.Online.title": " غرفه دارها ",

  // StandDetails
  "stands.details.StandDetails.introduction": "معرفی",
  "stands.details.StandDetails.comments": "نظرات",
  "stands.details.StandDetails.people": "غرفه داران",
  "stands.details.StandDetails.products": "محصولات و خدمات",
  "stands.details.StandDetails.conversations": "گفتگوها",
  "stands.details.StandDetails.cooperations": "فرصت‏‏‏‌ها",
  //info
  "stands.info.introduction.call.tooltip": "برای مشاهده کلیک کنید",
  "stands.info.introduction.call": "تلفن تماس",
  "stands.info.introduction.site": "وب سایت",
  "stands.info.introduction.instagram": "اینستاگرام",
  "stands.info.introduction.linkedin": "لینکدین",
  "stands.info.comment.nothings1": "در حال حاضر هیچ گفت و گویی وجود ندارد !",
  "stands.info.comment.nothings2": "شما شروع کننده گفت و گو باشید",
  "stands.info.comment.placeholder.enterText": "برای پیوستن به گفتگو، اینجا نظرت را بنویس",
  "stands.info.comment.placeholder.enterText-minsize": "به گفتگو بپیوند",
  "stands.info.products.nothings": "در حال حاضر هیچ محصول وجود ندارد !",
  "stands.info.cooperation.SpecificationsUser": "مشخصات همکاری",
  "stands.info.cooperation.SpecificationsColleague": "نوع همکاری",

  //product
  "product.productList.filter.products": "فیلتر کردن بین محصولات نمایشگاه",
  "product.productList.page": "صفحه ",
  "product.productList.page.of": " از ",
  "product.productList.infoprice": "تومان",
  "product.detailedcomments.vote.title": "به این محصول امتیاز هم بده",
  "product.detailedcomments.vote.subtitle": "امتیاز و نظر شما را همه می توانند ببینند",
  "product.detailedcomments.alert.nothing": " در حال حاضر نظری ثبت نشده است !",
  "product.filter.alphabetical": "مرتب‌سازی براساس حروف الفبا:",
  "product.filter.mostvisited": "مرتب‌سازی براساس بالاترین امتیاز:",
  "product.comment.sendmsg": "امتیاز و نظر شما با موفقیت ارسال شد!",

  //Chat
  "chat.chatHeader.setVideoMeeting": "تنظیم جلسه ویدیویی",
  "chat.sendBox.placeholder": "پیام خود را اینجا بنویسید",
  "chat.chattitle__subtitle.lastVisit": "آخرین بازدید",

  //Exhibition
  "exhibition.coments.title": "گفت و گوی عمومی",
  "exhibition.comments.subtitle": "اینجا مکانی برای گفت و گو درباره این شرکت است همین حالا به گفت و گو بپیوندید",
  "exhibition.comments.subtitle-small": "همین حالا به گفت و گو بپیوندید",
  "exhibition.comments.vote.comment1": "براساس رای",
  // "exhibition.comments.vote.comment2": "رای",
  "exhibition.comments.toastr": "پیام شما با موفقیت ارسال شد",
  "exhibition.coopeationItem.opportunities": "فرصت شغلی",
  "exhibition.coopeationItem.opportunitiestwo": "فرصت سرمایه گذاری",
  "exhibition.coopeationItem.create": "برای ارسال پیشنهاد، توضیحات را اینجا بنویسید",
  "exhibition.cooperationItem.loading": "قبلا درخواست همکاری ارسال کرده اید",
  "exhibition.cooperationItem.loadingtwo": "ارسال درخواست همکاری",
  "exhibition.cooperationItem.loadingThere": "آیا می خواهید این پیشنهاد را لغو کنید ؟",
  "exhibition.cooperationItem.loadingYes": "بله لغو می کنم",
  "exhibition.cooperationItem.loadingNo": "خیر",
  "exhibition.cooperationItem.tehrann": "تهران",
  "exhibition.exhibitionlist.title": "لیست نمایشگاه ها",
  "exhibition.exhibition.tab1": "معرفی",
  "exhibition.exhibition.tab2": "اعضا",
  "exhibition.exhibition.tab3": "محصولات و خدمات",
  "exhibition.exhibition.tab4": "گفت و گو ها",
  "exhibition.exhibition.tab5": "همکاری ها",
  "exhibition.exhibitionlist.title": "لیست نمایشگاه ها",
  "exhibition.members.TableCell1": "نام و نام خانوادگی",
  "exhibition.members.TableCell2": "جایگاه شغلی",
  "exhibition.members.TableCell3": "تلفن",
  "exhibition.members.TableCell4": " کشور",
  "exhibition.members.TableCell5": "",
  "exhibition.members.TableCell6": "مشخصات عضو",
  "exhibition.rightSide.lable1": "همه",
  "exhibition.rightSide.lable2": "فرصت شغلی",
  "exhibition.rightSide.lable3": "فرصت سرمایه گذاری",
  "exhibition.rightSide.switchLable": "مرتب سازی براساس بیشترین درخواست",
  "exhibition.rightSide.selectInput": "نوع همکاری",
  "exhibition.sendBox.placeHolder": "پیام خود را اینجا بنویسید",

  //Meeting
  "Meeting.meetingDashboard.meetingDashboard.tabLable1": "دعوتنامه ها",
  "Meeting.meetingDashboard.meetingDashboard.tabLable2": "تقویم",
  "Meeting.meetingDashboard.meetingDashboard.tabPanel": "دعوتنامه ها",
  "Meeting.meetingDashboard.meetingDashboard.tabPanel23": "دعوت کننده",
  "Meeting.meetingDashboard.meetingDashboard.noInventation": "دعوت نامه ای ارسال نشده است",
  "Meeting.meetingDashboard.meetingDashboard.Today": "امروز",
  "Meeting.meetingDashboard.meetingDashboard.noInventationtoday": "امروز هیچ دعوت نامه ای دریافت نکرده اید",
  "Meeting.meetingDashboard.meetingDashboard.tabLable11": "بخش اعضا",
  "Meeting.meetingDashboard.meetingDashboard.setMeetingText": "برای تنظیم قرار ملاقات، در بخش «اعضا» یک عضو را انتخاب کنید و «درخواست جلسه» را بزنید",
  "Meeting.meetingDashboard.meetingInventation.month1": "فروردین",
  "Meeting.meetingDashboard.meetingInventation.month2": "اردیبهشت",
  "Meeting.meetingDashboard.meetingInventation.month3": "خرداد",
  "Meeting.meetingDashboard.meetingInventation.month4": "تیر",
  "Meeting.meetingDashboard.meetingInventation.month5": "مرداد",
  "Meeting.meetingDashboard.meetingInventation.month6": "شهریور",
  "Meeting.meetingDashboard.meetingInventation.month7": "مهر",
  "Meeting.meetingDashboard.meetingInventation.month8": "آبان",
  "Meeting.meetingDashboard.meetingInventation.month9": "آذر",
  "Meeting.meetingDashboard.meetingInventation.month10": "دی",
  "Meeting.meetingDashboard.meetingInventation.month11": "بهمن",
  "Meeting.meetingDashboard.meetingInventation.month12": "اسفند",
  "Meeting.meetingDashboard.meetingInventation.status": "پذیرفته است",
  "Meeting.meetingDashboard.meetingInventation.selectedPending": "در دست بررسی",
  "Meeting.meetingDashboard.meetingInventation.selectedRejected": "رد شده است",
  "Meeting.meetingDashboard.meetingInventation.selectedAccepted": "پذیرفته است",
  "Meeting.meetingDashboard.meetingInventation.inventationText": "دعوتنامه:",
  "Meeting.meetingDashboard.meetingInventation.boxPlace": "مکان برگزاری:",
  "Meeting.meetingDashboard.meetingInventation.boxContent": "ویدیویی",
  "Meeting.meetingDashboard.meetingInventation.boxHeading": "شرکت کنندگان",
  "Meeting.meetingDashboard.meetingInventation.selectedMeeting": "تعیین نشده",
  "Meeting.meetingDashboard.meetingInventation.selectedDate": "تاریخ",
  "Meeting.meetingDashboard.meetingInventation.loadingAccept": "ارسال زمان جدید",
  "Meeting.meetingDashboard.meetingInventation.loadingAccepttwo": "پذیرفتن",
  "Meeting.meetingDashboard.meetingInventation.send_confirm.title": "آیا می‌خواهید این دعوتنامه را ارسال کنید؟",
  "Meeting.meetingDashboard.meetingInventation.send_confirm.agree": "بله، ارسال می‌کنم",
  "Meeting.meetingDashboard.meetingInventation.send_confirm.disagree": "خیر",
  "Meeting.meetingDashboard.meetingInventation.accept_confirm.title": "آیا می‌خواهید این جلسه را بپذیرید؟",
  "Meeting.meetingDashboard.meetingInventation.accept_confirm.agree": "بله، می‌پذیرم",
  "Meeting.meetingDashboard.meetingInventation.accept_confirm.disagree": "خیر",
  "Meeting.meetingDashboard.meetingInventation.accept_confirm.alert": "این جلسه برای شما تنظیم شد! ۱۵ دقیقه قبل از شروع جلسه، به شما خبر خواهیم داد.",
  "Meeting.meetingDashboard.meetingInventation.reject_confirm.title": "آیا از رد کردن این جلسه مطمئن هستید؟",
  "Meeting.meetingDashboard.meetingInventation.reject_confirm.agree": "بله، رد می‌کنم",
  "Meeting.meetingDashboard.meetingInventation.reject_confirm.disagree": "خیر",
  "Meeting.meetingDashboard.meetingInventation.reject_confirm.alert": "شما این جلسه را رد کردید!",
  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.title": "آیا می‌خواهید این زمان جدید را برای جلسه پیشنهاد دهید؟",
  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.agree": "بله، تایید می‌کنم",
  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.disagree": "خیر",
  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.alert": "پیشنهاد شما برای زمان جدید جلسه، با موفقیت ارسال شد.",
  "Meeting.meetingDashboard.meetingInventation.sendInvitation.emptymsg.alert": "متن دعوتنامه فراموش شده است! لطفا متن دعوتنامه را پر کنید",
  "Meeting.meetingDashboard.meetingInventation.sendInvitation": "ارسال دعوتنامه برای جلسه",
  "Meeting.meetingDashboard.meetingInventation.changeTime": "تغییر زمان",
  "Meeting.meetingDashboard.meetingInventation.changeTimetwo": "لغو تغییرات",
  "Meeting.meetingDashboard.meetingInventation.cancellRequest": "لغو درخواست",
  "Meeting.meetingDashboard.meetingInventation.alertOne": "قبلا درخواست همکاری ارسال کرده اید",
  "Meeting.meetingDashboard.meetingInventation.changetimeReject": "رد کردن",
  "Meeting.meetingDashboard.meetingInventation.statusInvitor": "دعوت کننده",
  "Meeting.meetingDashboard.meetingInventation.cancelInvitation": "لغو ارسال",
  "Meeting.meetingDashboard.meetingInventation.statusPendingReceive": "در انتظار دریافت",
  "Meeting.meetingDashboard.meetingInventation.timeNotSet": "تعیین نشده است",
  "Meeting.meetingDashboard.meetingList.tableTitle": "دعوتنامه جلسه",
  "Meeting.meetingDashboard.meetingList.subtitle": "پس از ارسال دعوتنامه، باید منتظر تایید جلسه از سوی طرف مقابل باشید",
  "Meeting.meetingDashboard.workSheet.item": "جلسه ویدیویی با",
  "Meeting.meetingDashboard.meetingInventation.link": "لینک ورود به جلسه تصویری",
  "Meeting.meetingDashboard.meetingInventationCreate.send-alert": "این دعوتنامه با موفقیت ارسال شد! لطفا منتظر پاسخ طرف مقابل باشید",
  "Meeting.meetingDashboard.meetingInventationCreate.checkcalender.currentUser": "زمان انتخاب شده برای شما مسدود میباشد! زمان دیگری را انتخاب نمایید",
  "Meeting.meetingDashboard.meetingInventationCreate.checkcalender": "در زمان تعیین شده، مخاطب قادر به برگزاری جلسه نمیباشد",

  //Opportunity
  "Oppotunity.OppotunityDashboard.searchtitle.job": "فیلتر کردن بین فرصت های شغلی",
  "Oppotunity.OppotunityDashboard.searchtitle.investment": "فیلتر کردن بین فرصت های سرمایه گذاری",
  "Oppotunity.allOportunities.jobPosition": "جایگاه شغلی",
  "Oppotunity.allOportunities.investment": "پیشنهاد های سرمایه گذاری",
  "Oppotunity.allOportunities.company": "شرکت",
  "Oppotunity.allOportunities.City": "شهر",
  "Oppotunity.allOportunities.Category": "دسته بندی",
  "Oppotunity.companyFilter.category": "زمینه فعالیت",
  "Oppotunity.companyFilter.alphabetical": "مرتب سازی براساس حروف الفبا",
  "Oppotunity.companyFilter.subcategory": "دسته بندی ها",
  "Oppotunity.companyFilter.country": "کشور",
  "Oppotunity.memberFilter.subcategory": "دسته بندی ها",
  "Oppotunity.memberFilter.country": "کشور",
  "Oppotunity.memberFilter.ability": "توانایی",
  "Oppotunity.memberFilter.interest": "علاقه مندی تخصصی",
  "Oppotunity.opportunityDashboard.tab1": "همه ی فرصت ها",
  "Oppotunity.opportunityDashboard.tab2": "پیشنهادهای من",
  "Oppotunity.allOportunities.sendrequest_investment": 'فرصت سرمایه گذاری بین المللی',
  "Oppotunity.allOportunities.sendrequest_job": 'همکار بازاریاب و فروش',
  "Oppotunity.opportunityItem.description": "در اینجا توضیحات دقیقتری از یک فرصت سرمایه گذاری قرار میگیرد و جنبه های مختلف  فرصت سرمایه گذاری درخواست شده نمایش داده میشوند.",
  "Oppotunity.opportunityItem.createDescription": "برای ارسال پیشنهادات توضیحات خود را اینجا بنویسید :",
  "Oppotunity.opportunityDashboard.tabLable1": "همه ی فرصت ها",
  "Oppotunity.opportunityDashboard.tabLable2": "پیشنهادهای من",
  "Oppotunity.opportunityDashboard.company": "شرکت",
  "Oppotunity.opportunityDashboard.location": "موقعیت مکانی",
  "Oppotunity.opportunityDashboard.location.tehran": "تهران",
  "Oppotunity.opportunityDashboard.switch": "مرتب سازی براساس بیشترین پیشنهاد",
  "Oppotunity.allOportunities.alert": "در حال حاضر پیشنهادی ندارید !",
  "Oppotunity.sendrequest.requestbox": "برای ارسال پیشنهاد، توضیحات را اینجا بنویسید",
  "Oppotunity.item.btn-cancele": "لغو درخواست",
  "Oppotunity.item.btn-sendrequest": "درخواست ارسال شد",
  "Oppotunity.item.delete-btn.alert": "پیشنهاد همکاری شما با موفقیت لغو شد!",

  "Oppotunity.item.btn-sendrequest-alert_title": "عنوان",
  "Oppotunity.item.btn-sendrequest-alert_desc_job": "برای ایجاد بخش «فرصت‌های شغلی» در غرفه نمایشگاه، لطفا فایل روبرو را دانلود کنید، آن را به صورت کامل پر کرده و فایل نهایی را در قسمت بعدی آپلود کنید.",
  "Oppotunity.item.btn-sendrequest-alert_desc_invesment": "برای ایجاد بخش «فرصت سرمایه‌ گذاری» در غرفه نمایشگاه، لطفا فایل روبرو را دانلود کنید، آن را به صورت کامل پر کرده و فایل نهایی را در قسمت بعدی آپلود کنید.",
  "Oppotunity.item.btn-sendrequest-alert_download": " دانلود فایل",
  "Oppotunity.item.btn-sendrequest-upload": "اینجا را لمس کنید و فایل را آپلود کنید",
  "Oppotunity.item.btn-sendrequest-upload_alertformat": "فرمت فایل آپلود شده مورد تایید نمیباشد.",

  "Oppotunity.sendrequest.confirm": "آیا می‌خواهید این پیشنهاد را ارسال کنید؟",
  "Oppotunity.sendrequest.agree": "بله، ارسال می‌کنم",
  "Oppotunity.sendrequest.disagree": "خیر",


  //Profile
  "Profile.profileAvatar.title1": "تصویر پروفایل",
  "Profile.profileDetails.profileInfo.h2": "اطلاعات پایه",
  "Profile.profileDetails.profileInfo.h2.hint": "این اطلاعات برای همه نمایش داده میشوند ",
  "Profile.profileDetails.profileInfo.general.offer.h3": "چه میتوانم پیشنهاد دهم",
  "Profile.profileDetails.profileInfo.general.need.h3": "به دنبال چه میگردم",
  "Profile.profileDetails.profileInfo.general.h3": "چه میتوانم پیشنهاد دهم",
  "Profile.profileDetails.profileInfo.companyName": "نام شرکت",
  "Profile.profileDetails.profileInfo.jobPosition": "موقعیت شغلی",
  "Profile.profileEditExtra.textInput.lable1": "شهر",
  "Profile.profileEditExtra.textInput.lable2": "ایمیل",
  "Profile.profileEditExtra.textInput.lable3": "لینکدین",
  "Profile.profileEditExtra.textInput.lable4": "اینستاگرام",
  "Profile.profileEditExtra.textInput.loading": "ذخیره اطلاعات",
  "Profile.profileEditExtra.modal.title": "اطلاعات پایه",
  "Profile.profileEditExtra.modal.subTitle": "دیگران میتوانند این اطلاعات را ببینند",
  "Profile.profileEditExtra.generalDrawer.title": "",
  "Profile.profileEditExtra.generalDrawer.subTitle": "دیگران میتوانند این اطلاعات را ببینند",
  "Profile.profileEditExtra.addalert": "اطلاعات پایه‌ی شما با موفقیت به روز شد.",
  "Profile.profileChangePassword.Modal.title": 'اصلاح صفحه کاربری',
  "Profile.profileChangePassword.Modal.subtitle": 'اگر رمزعبور فعلی را فراموش کرده‌اید فراموشی رمز عبور را بزنید',
  "Profile.profileChangePassword.length": 'رمزعبور معتبر حداقل 6 کارکتر میباشد',
  "Profile.profileChangePassword.alert.lastpassword": 'کلمه عبور قدیمی اشتباه است',
  "Profile.profileEditInfo.loading": "ذخیره اطلاعات",
  "Profile.profileEditInfo.Modal.title": "ویرایش اطلاعات کاربری",
  "Profile.profileEditInfo.addalert": "اطلاعات کاربری شما با موفقیت به روز شد.",
  "Profile.profileHeader.button1": "اصلاح اطلاعات",
  "Profile.profileHeader.button2": "تغییر رمز عبور",
  "Profile.profilePassword.Modal.subtitle": "اگر رمز عبور فعلی را فراموش کرده اید، از بخش ورود فراموشی رمز را بزنید",
  "Profile.profileuploader.title": "تصویر را به این قسمت کشیده و رها کنید",
  "Profile.profileuploader.upload": 'آپلود',
  "Profile.profileuploader.delete": 'حذف',
  "Profile.profileuploader.uploadimg.alert": 'عکس کاربری با موفقیت به روز شد.',
  "Profile.profileuploader.uploadimg.deletealert": 'عکس کاربری با موفقیت حذف شد',
  "Profile.profileuploader.confirm.deletavatar": 'آیا می‌خواهید عکس کاربری خود را حذف کنید؟',
  "Profile.profileuploader.confirm.deletavatar.agree": 'بله، حذف می‌کنم',
  "Profile.profileuploader.confirm": 'آیا می‌خواهید این عکس را به عنوان عکس کاربر خود انتخاب کنید؟',
  "Profile.profileuploader.agree": 'بله، تایید می‌کنم',
  "Profile.profileuploader.disagree": 'خیر',
  "Profile.profileuploader.save": 'ذخیره',
  // Common Pages
  "Common.notFound.title": "صفحه مورد نظر یافت نشد",

  // Notification
  "Notification.Meeting.Submit.success": "این دعوتنامه با موفقیت ارسال شد! لطفا منتظر پاسخ طرف مقابل باشید",

  //Vote
  "vote.btn-submit": "ثبت نظر و امتیاز",
  "vote.btn-cancel": "لغو",

  //Landing
  "Landing.firstsection.bar.btn-Register": "ثبت نام",
  "Landing.firstsection.bar.btn-login": "ورود",
  "Landing.firstsection.bar.btn-exhibition": "لیست نمایشگاه ها",
  "Landing.firstsection.bar.btn-online-demo": "درخواست دمو",
  "Landing.firstsection.bar.btn-lang": "EN",
  "Landing.firstsection.rightside.title": "نمالاین، بستر برگزاری نمایشگاه آنلاین",
  "Landing.firstsection.rightside1.desc":"به نسل جدید نمایشگاه‌ها خوش آمدید. ",
  "Landing.firstsection.rightside2.desc":"شما می‌توانید به کمک نمالاین، نمایشگاه‌های فیزیکی و مجازی خود را هوشمند برگزار کنید و یا در نمایشگاه‌های هوشمندی که در حال برگزاری هستند، شرکت کنید.",

  "Landing.protocol.title": 'مراحل کار با نمالاین ',
  "Landing.protocol.btngroup.sec1": "بازدید کنندگان",
  "Landing.protocol.btngroup.sec2": "برگزار کنندگان",
  "Landing.protocol.organizer.sec1.title": "ساختن نمایشگاه",
  "Landing.protocol.organizer.sec1.desc": "در اولین گام، با تعیین مشخصات کلی نمایشگاه آنلاین خود را ساخته و زمان شروع و پایان آن را مشخص کنید.",
  "Landing.protocol.organizer.sec2.title": "ثبت‌نام غرفه‌ها",
  "Landing.protocol.organizer.sec2.desc": "در مرحله دوم تعرفه هر یک از غرفه‌ها را مشخص کنید تا ثبت نام غرفه‌داران شروع شود.",
  "Landing.protocol.organizer.sec3.title": "تایید غرفه‌ها",
  "Landing.protocol.organizer.sec3.desc": "پس از ثبت نام، غرفه‌داران ظاهر غرفه‌ها را انتخاب کرده و مشخصات آن را تعیین می‌کنند و پرداخت صورت می‌گیرد.",
  "Landing.protocol.organizer.sec4.title": "افتتاح نمایشگاه",
  "Landing.protocol.organizer.sec4.desc": "حالا زمان آن رسیده که نمایشگاه آنلاین خود را افتتاح کنید و همه بتوانند از آن بازدید کنند.",
  "Landing.protocol.view.sec1.title": "جستجوی نمایشگاه",
  "Landing.protocol.view.sec1.desc": "در مرحله اول، نمایشگاه مورد نظر خود را از بین نمایشگاه‌های در حال برگزاری انتخاب کنید.",
  "Landing.protocol.view.sec2.title": "ثبت‌نام و ورود",
  "Landing.protocol.view.sec2.desc": " با ثبت نام یا ورود به حساب کاربری، می‌توانید از نمایشگاه بازدید کنید.",
  "Landing.protocol.view.sec3.title": "جستجوی غرفه",
  "Landing.protocol.view.sec3.desc": "از غرفه‌ها بازدید کنید یا از قسمت جستجو در نمایشگاه، غرفه مورد نظر خود را پیدا کنید.",
  "Landing.protocol.view.sec4.title": "تعامل و ارتباط",
  "Landing.protocol.view.sec4.desc": " با صاحبان غرفه به صورت آنلاین تعامل کرده و ارتباطات خود را گسترش دهید.",
  "Landing.protocol.footer.title-view": 'اگر بازدید کننده هستید، از اینجا ثبت نام کنید',
  "Landing.protocol.footer.title-organazer": 'اگر برگزار کننده هستید، از اینجا ثبت نام کنید',

  "Landing.Pavilions.title": 'لیست نمایشگاه ها',
  "Landing.Pavilions.title.login": 'پیش از ورود، نمایشگاه مورد نظر خود را انتخاب کنید.',
  "Landing.Pavilions.more": 'مشاهده همه',
  "Landing.Pavilions.moree": 'مشاهده',
  "Landing.Pavilions.cardbtn": 'مشاهده نمایشگاه',
  "Landing.Pavilions.cardbtn.onregister": 'ثبت نام غرفه در نمایشگاه',
  "Landing.pavilioncard.title.oncoming": 'به زودی',
  "Landing.pavilioncard.title.onregister": 'درحال ثبت نام',
  "Landing.pavilioncard.title.onperforming": 'درحال برگزاری',
  "Landing.pavilioncard.title.onpast": 'نمایشگاه پایان یافته',

  "Landing.about.title": 'مشخصات محصول',
  "Landing.about.sec1.title": 'هویت سازی بصری',
  "Landing.about.sec1.desc": "شما می‌توانید غرفه، سالن و حتی شهر نمایشگاهی خود را از نظر رنگ، شکل و اندازه، همانطور که می‌خواهید طراحی کنید.",
  "Landing.about.sec2.title": 'فرصت های همکاری',
  "Landing.about.sec2.desc": "در غرفه بازار کار، فرصت‌های شغلی غرفه‌ها نمایش داده می‌شوند تا بتوانید راحت‌تر استخدام کنید یا شغل مورد نظر خود را پیدا کنید.",
  "Landing.about.sec3.title": 'کارگاه و سخنرانی',
  "Landing.about.sec3.desc": "مکان برگزاری جلسات ویدیویی، کارگاه، سمینار و هر رویداد آنلاین دیگر، به راحتی در نمالاین برای شما فراهم شده است.",

  "Landing.appdescription.title": "اپلیکیشن نمالاین؛ نزدیک‌ترین نمایشگاه آنلاین به شما",
  "Landing.appdescription.desc": "با دانلود و نصب نرم افزار نمالاین، نمایشگاه‌های آنلاین همیشه در دسترس شما هستند و می‌توانید از تمام ویژگی‌های آن‌ها به راحتی و در هر دستگاهی استفاده کنید.",
  "Landing.appdescription.btn": 'نصب نرم افزار',

  "Landing.video-help.title": "شرکت در نمایشگاه، هوشمند‌تر از همیشه!",
  "Landing..video-help.desc": "می‌دانید نمایشگاه‌های آینده چه شکلی هستند و چطور تجربه‌ی شرکت در نمایشگاه را متحول می‌کنند؟ این ویدیو را ببینید تا با نسل جدید نمایشگاه‌ها آشنا شوید.",

  "Landing.statistics.title": 'آمار نمایشگاه ها',
  "Landing.statistics.sec1.title": 'بازدیدکنندگان',
  "Landing.statistics.sec2.title": 'نمایشگاه',
  "Landing.statistics.sec3.title": 'غرفه دار',
  "Landing.statistics.sec4.title": 'جلسه کاری',

  "Landing.Customers.title": 'مشتریان ما',

  "Landing.footer.logo": 'نمایشگاه آنلاین ایران',
  "Landing.footer.help": 'راهنمای استفاده',
  "Landing.footer.about": 'درباره ما',
  "Landing.footer.contact": 'تماس با ما',
  "Landing.footer.term": 'قوانین و شرایط',
  "Landing.footer.namaline": 'تمامی حقوق مادی و معنوی این اثر برای نمالاین محفوظ است',

  "Landing.registerform.sec1": 'ثبت نام بازدید کننده',
  "Landing.registerform.sec2": 'ثبت نام غرفه دار',
  "Landing.registerform.sec3": 'ثبت نام برگزار کننده',
  "Landing.registerform.sec4": 'ثبت نام اسپانسر',
  "Landing.loginform.sec1": 'ورود بازدیدکننده/غرفه‌دار',
  "Landing.loginform.sec2": 'ورود مدیر غرفه',
  "Landing.loginform.sec3": 'ورود برگزار کننده',
  "Landing.loginform.sec4": 'ورود اسپانسر',
  

  //Meeting
  "Meeting.alert.pending": 'در انتظار تایید',
  "Meeting.alert.rejected": 'جلسه رد شده است',
  "Meeting.alert.accepted": 'جلسه تایید شد',
  "Meeting.toast.success": 'این دعوتنامه با موفقیت ارسال شد! لطفا منتظر پاسخ طرف مقابل باشید',
  //general
  "General.person": 'نفر',
  "General.page": 'صفحه',
  "General.from": 'از',
  "General.alert.toast.errorServer": 'مشکل در ارتباط با سرور',
  "General.loading.component": 'خواندن اطلاعات از سرور ...',

  "About.header.title": 'درباره‌ی ما',
  "About.header.button": 'به تیم ما بپیوندید',
  "About.header.text": 'هر آن چه که باید درباره‌ی تیم ما بدانید!',
  "About.tabBox.label1": 'راهنمای استفاده',
  "About.tabBox.label2": 'درباره ما',
  "About.tabBox.label3": 'تماس با ما',
  "About.tabBox.label4": 'قوانین و شرایط',
  "About.sectionOne.title1": 'نمایشگاه و بیشتر از نمایشگاه !',
  "About.sectionOne.title2": 'شبکه سازی کنید، ملاقات های کاری ترتیب دهید',
  "About.sectionOne.title3": 'و گزارش های عملکرد دریافت کنید.',
  "About.sectionOne.text": `نمالاین پلتفرمی برای برگزاری نمایشگاه آنلاین است که در آن علاوه بر نمایش کسب و کار و محصولاتتان به افراد مرتبط در حوزه خود، می‌توانید با بازدیدکنندگان جلسه کاری ترتیب دهید، در بخش فرصت‌های شغلی به دنبال افراد مناسب برای پیوستن به تیم بگردید و در انتها نیز گزارش هوشمندی درباره بازدیدکنندگان و عملکرد خود در طی زمان برگزاری نمایشگاه آنلاین دریافت کنید.
  `,
  "About.sectionOne.signup.btn": 'ثبت نام کنید',

  "About.jobBackground.title": 'همه چیز از اینجا شروع شد!',
  "About.jobBackground.text": `همه‌چیز از فروردین سال ۹۹ شروع شد؛ زمانی که در میان بحبوحه شیوع ویروس کرونا و آنلاین شدن بخش زیادی از فعالیت‌های روزمره، ایده ایجاد زیرساختی برای برگزاری آنلاین نمایشگاه‌های کشور، افرادی را دور هم جمع کرد تا بتوانند در شهریور ۹۹ استارتاپی نوین را پایه‌گذاری کنند. هدف از ایجاد نمالاین، چیزی فراتر از برگزاری نمایشگاه آنلاین است؛ اینکه علاقه‌مندان در سر تا سر کشور و همچنین افرادی که در خارج از ایران زندگی می‌کنند، بتوانند حضور در بهترین نمایشگاه‌های بین‌المللی کشور را تجربه کنند و همه افراد، فارغ از مرزهای جغرافیایی، امکان بازدید از نمایشگاه و برگزاری جلسات کاری با سازمان‌های حاضر را داشته باشند.
  `,
  "About.team.title": 'با تیم ما آشنا شوید!',
  "About.team.text": 'افرادی که برای برگزاری نمایشگاه های آنلاین و همچنین رسیدن نمالاین به اهداف خود، در کنار یکدیگر کار می‌کنند:',
  "About.team.name.navid": 'نوید امیدیان',
  "About.team.name.milad": 'میلاد جعفری',
  "About.team.name.mohammadreza": 'محمدرضا مرادی',
  "About.team.name.venus": 'ونوس جلیلی',
  "About.team.name.majid": 'مجید تقدسی‌ نیا',
  "About.team.name.mohammad": 'محمد گرویی',

  "About.team.position.navid": 'هم‌بنیانگذار و مدیر اجرایی',
  "About.team.position.milad": 'هم‌بنیانگذار و شبکه های اجتماعی',
  "About.team.position.mohammadreza": 'هم‌بنیانگذار و ارشد محصول',
  "About.team.position.venus": 'هم‌بنیانگذار و ارشد طراحی',
  "About.team.position.majid": 'ارشد اجرایی',
  "About.team.position.mohammad": 'هم‌بنیانگذار و ارشد توسعه بازار',

  //faq
  "Faq.header.title": 'آیا سوالی دارید؟',
  "Faq.header.text": 'یا راهنمای استفاده از محصول را ببینید',
  "Faq.header.input": 'کلید واژه را اینجا بنویسید',
  "Faq.body.title": 'سوالات پرتکرار',
  "Faq.body.subtitle": 'ممکن است جواب شما اینجا باشد!',

  //contact
  "Contact.header.title": 'تماس با ما',
  "Contact.header.text": 'ارتباط سریع با تیم پشتیبانی ',
  "Contact.header.chatbtn": 'گفتگوی آنلاین',
  "Contact.header.callbtn": 'تماس تلفنی',
  "Contact.body.address": 'تهران - میدان آزادی - بزرگراه لشکری - بعد از ایستگاه مترو بیمه - پلاک 31 - کارخانه نوآوری آزادی - سوله ی کارا ',



  //terms
  "Terms.header.title": 'دنبال موضوع خاصی می‌گردید؟',
  "Terms.header.text": 'یا در قوانین پایین جستجو کن',
  "Terms.header.input": 'کلید واژه را اینجا بنویسید',
  "Terms.body.title": 'قوانین و شرایط',
  "Terms.body.subtitle": 'برای استفاده از نمالاین',


  //tour guide
  "TourGuide.halls.title": 'سالن ها',
  "TourGuide.halls.desc": 'در این صفحه، شما میتوانید تمامی سالن های حاضر در نمایشگاه را مشاهده کنید. برای نمایش داخل هرسالن، کافیست بر روی سالن دلخواه کلیک کنید.',
  "TourGuide.pavillions.title": 'غرفه‌ها',
  "TourGuide.pavillions.desc": 'در این صفحه، لیست تمامی غرفه های سالن وجود دارد و شما میتوانید با انتخاب هرکدام، جزییات و محصولات غرفه را مشاهده کنید.',
  "TourGuide.products.title": 'محصولات',
  "TourGuide.products.desc": 'هر غرفه در نمالاین، محصولاتی را برای ارائه دارد. در این صفحه، میتوانید لیست تمامی محصولات را مشاهده و با کلیک بر روی آنها، جزییات بیشتری را ببینید.',
  "TourGuide.seminar.title": 'سمینارها',
  "TourGuide.seminar.desc": 'در یک نمایشگاه، سمینار و کارگاه های متفاوتی برگزار میشود. در این صفحه، شما میتوانید نسبت به مشاهده تمامی رویدادهای زنده اقدام کنید.',
  "TourGuide.meeting.title": 'جلسه ها',
  "TourGuide.meeting.desc": 'در نمالاین، شما میتوانید نسبت به برگزاری جلسه با یک نفر اقدام کنید. کافیست از منوی سمت راست، ایجاد جلسه را انتخاب و زمان مدنظر را ست کنید. پس از تایید نفر مقابل، در روز و ساعت مقرر، امکان برگزاری جلسه را خواهید داشت.',
  "TourGuide.Button.Ok": 'متوجه شدم'
}


