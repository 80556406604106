import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './reducers/rootReducer'
import { createBrowserHistory } from 'history'
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'yldasmt',
    storage,
    whitelist: ['auth', 'lang', 'persist', 'exhibition'],
    // migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG })
}

export const history = createBrowserHistory();

export const configureStore = () => {
    const middlewares = [thunk];

    const composedEnhancer = composeWithDevTools(
        applyMiddleware(...middlewares)
    )

    const store = createStore(
        persistReducer(persistConfig, rootReducer(history)),
        composedEnhancer
    )

    if (process.env.NODE_ENV !== 'production') {
        if (module.hot) {
            module.hot.accept('./reducers/rootReducer.js', () => {
                const newRootReducer = require('./reducers/rootReducer').default;
                store.replaceReducer(newRootReducer)
            })
        }
    }

    const persistor = persistStore(store);

    return { store, persistor };
};
