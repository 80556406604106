import React from 'react'
import { Dots, TextInput, Button } from '../../component'
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { requestCodeFp } from '../../app/store/actions/authActions.js';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useStyles } from './_auth.js'
import SelectLanguage from './SelectLanguage'
//phone
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
/*************change lang ***************/
import MessageLang from '../../lang/';
/*************change lang ***************/

const Phone = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { loading } = useSelector((state) => state.async);
    const lang = useSelector((state) => state.lang.lang);
    return (
        <>
            <Formik
                initialValues={
                    {
                        phone_number: '',
                        email: '',
                        state: lang
                    }}
                validationSchema={lang === 'fa' ?
                    Yup.object({
                        phone_number: Yup.string().required(<MessageLang id="form.verify.register.phone" />),
                    })
                    :
                    Yup.object({
                        email: Yup.string().required(<MessageLang id="form.verify.register.email" />).email(<MessageLang id="form.verify.register.email-valid" />),
                    })
                }
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        await dispatch(requestCodeFp(values))
                        setSubmitting(false);
                    } catch (error) {
                        setErrors({ auth: error.data.messages[0] });
                        setSubmitting(false);
                    }
                }}
            >
                {({ submitForm,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values, isValid, dirty }) => (
                    <Form className='ui form countrycode'>
                        <Typography className="auth__subtitle" >
                            <MessageLang id="forgetpass.panel.head.title" />
                        </Typography>
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="subtitle2"
                            className={'helptxt'}
                        >
                            {lang === "fa" ? <MessageLang id="forgetpass.panel.head.subtext1" /> : <MessageLang id="forgetpass.panel.head.subtext2" />}
                        </Typography>
                        {errors.auth && <span className={'error-alert'}>{errors.auth}</span>}
                        {lang === "fa" ?
                            <PhoneInput
                                error={Boolean(touched.phone_number && errors.phone_number)}
                                helperText={touched.phone_number && errors.phone_number}
                                className={classes.labelText}
                                margin="normal"
                                name="phone_number"
                                onBlur={handleBlur}
                                onChange={handleChange('phone_number')}
                                type="number"
                                value={values.phone_number}
                                variant="outlined"
                                country='ir'
                                autoFormat={true}
                                enableSearch={true}
                                disableSearchIcon={false}
                                autocompleteSearch={true}
                                countryCodeEditable={false}
                                specialLabel={<MessageLang id="form.label.login.phone" />}
                                inputProps={{
                                    required: true,
                                    autoFocus: true,
                                }}
                            // isValid={(inputNumber, country, countries) => {
                            //     setcode(country.dialCode)
                            // }}
                            />
                            :
                            <TextInput
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                                className={classes.labelText}
                                label={<MessageLang id="Profile.profileEditExtra.textInput.lable2" />}
                                margin="normal"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange('email')}
                                type="text"
                                value={values.email}
                            />
                        }
                        <Box my={2}>
                            <Button
                                fullWidth
                                size="large"
                                variant="contained"
                                onClick={submitForm}
                                disabled={loading}
                                label={loading ? <CircularProgress style={{ color: '#fff' }} size={26} /> : <MessageLang id="form.label.forgetpass.btn" />}
                            />
                        </Box>
                        <Dots stepOne />
                    </Form>
                )}
            </Formik>
            <SelectLanguage />
        </>
    )
}

export default Phone