import {
    SET_EXHIBITION,
    SET_EXHIBITIONS
} from '../constants/Constants'

export function setExhibition(data) {
    return {
        type: SET_EXHIBITION,
        payload: data
    }
}

export function setExhibitions(data) {
    return {
        type: SET_EXHIBITIONS,
        payload: data
    }
}