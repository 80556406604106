import {
    SET_CATEGORIES,
    SET_COMPANIES,
    SET_DATES,
    SET_ABILITIES,
    SET_INTERESTS,
    SET_COUNTRIES,
    SET_POSITIONS,
    SET_OFFERS,
    SET_EXPOS,
    SET_NotificationCounter,
    SET_NotificationList,
    SET_AddNotificationList,
    SET_STANDS
} from "../constants/Constants"


import agent from '../../api/agent'

export function setCategories(categories) {
    return {
        type: SET_CATEGORIES,
        payload: categories
    }
}

export function setCountries(countries) {
    return {
        type: SET_COUNTRIES,
        payload: countries
    }
}

export function setCompanies(companies) {
    return {
        type: SET_COMPANIES,
        payload: companies
    }
}

export function setPositions(positions) {
    return {
        type: SET_POSITIONS,
        payload: positions
    }
}

export function setAbilities(abilities) {
    return {
        type: SET_ABILITIES,
        payload: abilities
    }
}

export function setInterests(interests) {
    return {
        type: SET_INTERESTS,
        payload: interests
    }
}

export function setOffers(offers) {
    return {
        type: SET_OFFERS,
        payload: offers
    }
}

export function setDates(dates) {
    return {
        type: SET_DATES,
        payload: dates
    }
}

export function setExpos(expos) {
    return {
        type: SET_EXPOS,
        payload: expos
    }
}
export function setNotificationCounter(data) {
    return {
        type: SET_NotificationCounter,
        payload: data
    }
}
export function setNotificationList(data) {
    return {
        type: SET_NotificationList,
        payload: data
    }
}
export function addNotificationList(data) {
    return {
        type: SET_AddNotificationList,
        payload: data
    }
}


export const loadCategories = () => {
    return async function (dispatch) {
        try {
            const request = await agent.Filters.categories()
            dispatch(setCategories(request.result))
        } catch (error) {
            console.log(error)
        }
    }
}

export const loadCompanies = () => {
    return async function (dispatch) {
        try {
            const request = await agent.Filters.companies()
            dispatch(setCompanies(request.result))
        } catch (error) {
            console.log(error)
        }
    }
}

export const loadPositions = () => {
    return async function (dispatch) {
        try {
            const request = await agent.Filters.positions()
            dispatch(setPositions(request.result))
        } catch (error) {
            console.log(error)
        }
    }
}

export const loadAbilities = () => {
    return async function (dispatch) {
        try {
            const request = await agent.Filters.abilities()
            dispatch(setAbilities(request.result))
        } catch (error) {
            console.log(error)
        }
    }
}

export const loadInterests = () => {
    return async function (dispatch) {
        try {
            const request = await agent.Filters.interests()
            dispatch(setInterests(request.result))
        } catch (error) {
            console.log(error)
        }
    }
}

export const loadCountries = () => {
    return async function (dispatch) {
        try {
            const request = await agent.Filters.countries()
            dispatch(setCountries(request.result))
        } catch (error) {
            console.log(error)
        }
    }
}

export const loadStands = () => {
    return async function (dispatch, getState) {
        try {
            const response = await agent.Stands.companyList()
            const stands = response.result.data
            const total = response.result.total
            dispatch({
                type: SET_STANDS,
                payload: {
                    stands,
                    total
                }
            });
        } catch (error) {
        }
    };
}


export const loadOffers = () => {
    return async function (dispatch) {
        try {
            const request = await agent.Filters.offers()
            dispatch(setOffers(request.result))
        } catch (error) {
            console.log(error)
        }
    }
}

export const loadDates = () => {
    return async function (dispatch) {
        try {
            const request = await agent.Events.dates()
            dispatch(setDates(request.result.dates))
        } catch (error) {
            console.log(error)
        }
    }
}

export const loadExpos = () => {
    return async function (dispatch) {
        try {
            const request = await agent.Expo.pavilions()
            dispatch(setExpos(request.result))
        } catch (error) {
            console.log(error)
        }
    }
}
export const loadNotificationCounter = () => {
    return async function (dispatch) {
        try {
            const request = await agent.Notification.counter()
            dispatch(setNotificationCounter(request.result))
        } catch (error) {
            console.log(error)
        }
    }
}
export const loadNotificationList = () => {
    return async function (dispatch) {
        try {
            const request = await agent.Notification.signboardsLast()
            dispatch(setNotificationList(request.result))
        } catch (error) {
            console.log(error)
        }
    }
}