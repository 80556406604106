import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    Typography,
    IconButton,
    InputAdornment,
    TextField,
    CircularProgress,
    Button
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { updatePassFp } from '../../app/store/actions/authActions.js';
import { Dots, } from '../../component'
/*************change lang ***************/
import MessageLang from '../../lang/';
/*************change lang ***************/

const Pass = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.async);
    const lang = useSelector((state) => state.lang.lang);
    const [label, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({ ...label, showPassword: !label.showPassword });
    };

    return (
        <Formik
            initialValues={
                {
                    password: '',
                    password_confirmation: '',
                    state: lang
                }}
            validationSchema={Yup.object({
                password: Yup.string().required(<MessageLang id="form.verify.login.password" />),
                password_confirmation: Yup.string().required(<MessageLang id="form.verify.login.two_password" />)
                    .oneOf([Yup.ref('password'), null], <MessageLang id="form.verify.register.authpassword" />)
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                    await dispatch(updatePassFp(values))
                    setSubmitting(false);
                } catch (error) {
                    setErrors({ auth: <MessageLang id="form.verify.register.authStepThree" /> });
                    setSubmitting(false);
                }
            }}
        >
            {({
                submitForm,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values, isValid, dirty
            }) => (
                <Form className='ui form'>
                    <Box mb={3}>
                        <Typography
                            color="textSecondary"
                            variant="h4"
                            className="auth__subtitle"
                        >
                            <MessageLang id="form.label.register.stepThreePassword" />
                        </Typography>
                    </Box>
                    {errors.auth && <span className={'error-alert'}>{errors.auth}</span>}
                    <Field
                        component={TextField}
                        id="outlined-adornment-password"
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label={<MessageLang id="form.label.login.password" />}
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange('password')}
                        variant="outlined"
                        type={label.showPassword ? 'text' : 'password'}
                        value={values.password}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword}>
                                        {label.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Field
                        component={TextField}
                        id="outlined-adornment-password"
                        error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                        fullWidth
                        helperText={touched.password_confirmation && errors.password_confirmation}
                        label={<MessageLang id="form.label.register.password_replay" />}
                        margin="normal"
                        name="password_confirmation"
                        onBlur={handleBlur}
                        onChange={handleChange('password_confirmation')}
                        // className={classes.textField}
                        variant="outlined"
                        type={label.showPassword ? 'text' : 'password'}
                        value={values.password_confirmation}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword}>
                                        {label.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box my={2}>
                        <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            className="bluebtn"
                            onClick={submitForm}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress style={{ color: '#fff' }} size={26} /> : <MessageLang id="form.label.login.login" />}
                        </Button>
                    </Box>
                    <Dots stepThree />
                </Form>
            )}
        </Formik >
    );
};

export default Pass

