////////// general //////////////////

////////// company //////////////////

//////////form //////////////////
//////////label //////////////////
//////////verify //////////////////

////////// login //////////////////

////////// register //////////////////


export default {

  ////////// جنرال لواء //////////////////

  "general.copyright": "Gogo React ©2018 جميع الحقوق محفوظة.",



  ///////// / شركة ////////////////////

  "company.name": "namaline",



  ////////// شكل //////////////////



  ////////// ضع الكلمة المناسبة //////////////////

  "form.label.login.withPhone": "رقم التليفون",

  "form.label.login.codenumber": "الرقم الدولي",

  "form.label.login.phone": "رقم التليفون",

  "form.label.login.password": "كلمة المرور",

  "form.label.login.login": "تسجيل الدخول إلى namaline",

  "form.label.login.forgetpass": "نسيت كلمة المرور",

  "form.label.login.signup": "مستخدم جديد؟ سجل",

  "form.label.register.name": "الاسم",

  "form.label.register.family": "اسم العائلة",

  "form.label.register.company": "اسم الشركة",

  "form.label.register.btn_signup": "تسجيل",

  "form.label.register.btn_verify_phone": "تأكيد رقم الهاتف المحمول",

  "form.label.register.code_one": "تم إرسال الرمز إلى",

  "form.label.register.code_two": "أدخل هنا",

  "form.label.register.trycode": "إعادة إرسال الرمز",

  "form.label.register.stepThreePassword": "أدخل كلمة المرور الخاصة بك",

  "form.label.register.lastpassword": "كلمة المرور الحالية",

  "form.label.register.password": "كلمة مرور جديدة",

  "form.label.register.password_replay": "كرر كلمة المرور الجديدة",

  "form.label.register.terms": "لقد قرأت",

  "form.label.register.terms2": ", أوافق عليه",

  "form.label.forgetpass.btn": "أرسل الرمز",

  "form.label.forgetpass.btn_verify_code": "تأكيد الرمز",

  "form.label.placeholder.enterText": "اكتب رسالتك هنا",

  "form.label.placeholder.enterMeetingText": "وصف الاختراع",

  "form.label.placeholder.search": "بحث...",

  "form.label.placeholder.select": "حدد...",



  "form.pagenavigation": "صفوف لكل صفحة",

  "notification.head": "الاعلان",



  ////////// تحقق //////////////////

  "form.verify.login.phone": "أدخل رقم الاتصال الخاص بك",

  "form.verify.login.password": "أدخل كلمة المرور الخاصة بك",

  "form.verify.login.two_password": "كرر كلمة المرور",

  "form.verify.login.authpassword": "تكرار كلمة المرور غير صحيحة",

  "form.verify.login.auth": "رقم الجوال أو البريد الإلكتروني غير صحيح.",

  "form.verify.register.name": "أدخل اسمك",

  "form.verify.register.lastname": "أدخل اسمك الأخير",

  "form.verify.register.phone": "أدخل رقم هاتفك المحمول",

  "form.verify.register.code": "أدخل رمز بلدك",

  "form.verify.register.email": "أدخل بريدك الإلكتروني",

  "form.verify.register.auth": "الرمز غير صحيح! حاول مرة أخرى",

  "form.verify.register.authpassword": "كلمة المرور لا تتطابق مع الحقل الثاني",

  "form.verify.register.authStepThree": "واجه التسجيل مشكلة , يرجى المحاولة مرة أخرى",

  "form.verify.register.email-valid": "البريد الإلكتروني المدخل غير صالح",



  ////////// تسجيل الدخول //////////////////

  "login.message": "عزيزي , مرحبا بكم في Namalaine",

  "login.panel.head.title": "الكثير من الميزات للزوار!",

  "login.panel.head.text": "اطلع على أحدث المنتجات , واحضر المؤتمرات المتخصصة , ورتب مؤتمرات الفيديو , وانضم إلى محادثات المجموعة واستمتع بمساحة الكشك.",

  "login.panel.head.welcome": "مرحبًا بك في Namaline",



  ////////// تسجيل //////////////////

  "register.panel.head.title": "قدم نفسك للجميع!",

  "register.panel.head.text": "اطلع على أحدث المنتجات , واحضر المؤتمرات المتخصصة , ورتب مؤتمرات الفيديو , وانضم إلى محادثات المجموعة واستمتع بمساحة الكشك.",

  "register.panel.head.form_head": "سجل في أقل من دقيقة",

  "register.panel.btn.loginHall": "أدخل المعرض",



  ///////// forgetpass /////////////////

  "forgetpass.panel.head.title": "هل نسيت كلمة المرور؟",

  "forgetpass.panel.head.subtext1": "أدخل رقم الهاتف المحمول الذي سجلت به!",

  "forgetpass.panel.head.subtext2": "أدخل البريد الإلكتروني الذي سجلت به!",

  "forgetpass.sendcode.alert": "تم إرسال الرمز الجديد بنجاح.",



  //////// لوحة القيادة تخطيط /////////////////

  // رأس

  "DashboardLayout.header.logout.confirm": 'هل تريد تسجيل الخروج؟',

  "DashboardLayout.header.logout.agree": "نعم , سأغادر",

  "DashboardLayout.header.logout.disagree": "لا",

  "DashboardLayout.header.logout.alert": "لقد نجحت في تسجيل الخروج",



  "DashboardLayout.header.products": "المنتجات والخدمات",

  "DashboardLayout.header.meetings": "الاجتماعات",

  "DashboardLayout.header.exhibition": "معرض",

  "DashboardLayout.header.products": "المنتجات والخدمات",

  "DashboardLayout.header.events": "الأحداث",

  "DashboardLayout.header.events2": "قائمة المعارض",

  "DashboardLayout.header.events2.btn": "شاهد المزيد",

  "DashboardLayout.header.conversations": "المحادثات",

  "DashboardLayout.header.booths": "أكشاك",

  "DashboardLayout.header.conference_hall": "قاعة المؤتمرات",

  "DashboardLayout.header.user_page": "صفحة المستخدم",

  "DashboardLayout.header.pearl_petrochemical": "اللؤلؤة للبتروكيماويات",

  "DashboardLayout.header.jobs": "الوظائف",

  "DashboardLayout.header.profile": "الملف الشخصي",

  "DashboardLayout.header.logout": "تسجيل الخروج",
  "DashboardLayout.header.investment.opportunities": "فرص الاستثمار",
  "DashboardLayout.header.job.opportunities": "فرص عمل",

  //////// الأحداث ///////////////////

  // EventDashboard

  "events.EventDashboard.tabnav.lectures": "محاضرات",

  "events.EventDashboard.tabnav.workshops": "ورش العمل",

  // قائمة المحاضرات

  "events.LectureList.nothing.lectures": "لا توجد خطابات حاليًا",

  "events.LectureList.table.clock": "ساعة",

  "events.LectureList.table.speaker": "مكبر الصوت",

  "events.LectureList.table.topic": "لقب",

  // ورشة العمل

  "events.LectureList.nothing.WorkshopList": "لا يوجد حاليا أي عمل",

  "events.LectureList.table.teacher": "معلم",

  //////// مواقف /////////////////

  // StandDashboard

  "stands.StandDashboard.text.الشركة": "الشركات",

  "stands.StandDashboard.text.people": "العارضين",

  "stands.StandDashboard.Modal.invitation": "دعوة للاجتماع",

  "stands.StandDashboard.Modal.invitation_sub": "بعد إرسال الدعوة , يجب عليك انتظار الموافقة على الاجتماع من قبل الطرف الآخر",

  "stands.StandDashboard.add-meeting.setting": "إعداد اجتماع",

  "stands.StandDashboard.add-meeting.conversation": "بدء محادثة",

  "stands.StandDashboard.filter.الشركة": "تصفية بين شركات المعارض",

  "stand.StandDashboard.filter.parts": "التصفية بين أعضاء المعرض",

  // قائمة الشركات

  "stands.CompanyList.Table.الشركة": "نبذة عن الشركة",

  "stands.CompanyList.Table.activity": "مجال النشاط",

  "stands.CompanyList.Table.country": "دولة",

  "stands.CompanyList.Table.count": "عدد الموظفين",

  "stands.CompanyList.TableCell.people": "اشخاص",

  "stands.CompanyList.TableCell.rate": "تصنيف الزوار",

  // MemberFilter

  "stands.MemberFilter.form.الشركة": "اسم الشركة",

  "stands.MemberFilter.form.position": "موضع",

  "stands.MemberFilter.form.country": "البلد",

  "stands.MemberFilter.form.ability": "القدرة",

  "stands.MemberFilter.form.interest": "الفائدة",

  "stands.MemberFilter.form.family": "اسم العائلة",

  "stands.MemberFilter.form.spec": "المواصفات",

  "stands.MemberFilter.form.categories": "فئات",

  //يقف

  "stand.getfile": "الملف تحميل",

  //تفاصيل

  "stands.details.StandDetails.morebtn": "أكثر",

  "stands.details.phonenumber-title": "هواتف الاتصال",

  //الآفاق

  "stands.details.Outlook.title": "كشك منظور",

  "stands.details.Outlook.hall": "صالة",

  "stands.details.Outlook.opportunity": "فرصة",

  "stands.details.Outlook.member": "عضو",

  "stands.details.Outlook.product": "منتج",
  "stands.details.Tab.1": "كشك",
  "stands.details.Tab.2": "الحاضرين",
  "stands.details.Tab.3": "أخبار",

  // إحصائية

  "stands.details.Statistic.title": "آخر",

  "stands.details.Statistic.visit": "زيارة",

  "stands.details.Statistic.score": "النتيجة",

  "stands.details.Statistic.conversation": "المحادثة",

  "stands.details.Statistic.meeting": "الاجتماع",

  // متصل

  "stands.details.Online.title": "العارضون",

  // StandDetails

  "stands.details.StandDetails.introduction": "مقدمة",

  "stands.details.StandDetails.comments": "التعليقات",

  "stands.details.StandDetails.people": "العارضون",

  "stands.details.StandDetails.products": "المنتجات والخدمات",

  "stands.details.StandDetails.conversations": "المحادثات",

  "stands.details.StandDetails.cooperations": "التعاون",

  //معلومات

  "stands.info.introduction.call.tooltip": "انقر للعرض",

  "stands.info.introduction.call": "نداء",

  "stands.info.introduction.site": "موقع الكتروني",

  "stands.info.introduction.instagram": "انستغرام",

  "stands.info.introduction.linkedin": "ينكدين",

  "stands.info.comment.nothings1": "لا يوجد حوار حاليًا!",

  "stands.info.comment.nothings2": "تبدأ محادثة",

  "stands.info.comment.placeholder.enterText": "للانضمام إلى المحادثة , اكتب تعليقك هنا",

  "stands.info.comment.placeholder.enterText-minsize": "انضم إلى المحادثة",

  "stands.info.products.nothings": "لا توجد منتجات حاليًا!",

  "stands.info.cooperation.nothings": "لا يوجد تعاون حاليًا!",

  "stands.info.cooperation.SpecificationsUser": "لمحة التعاون",

  "stands.info.cooperation.SpecificationsColleague": "نوع من التعاون",

  //منتج

  "product.productList.filter.products": "التصفية بين منتجات المعرض",

  "product.productList.page": "الصفحة",

  "product.productList.page.of": "ل",

  "product.productList.infoprice": "",

  "product.detailedcomments.vote.title": "قيم هذا المنتج أيضًا",

  "product.detailedcomments.vote.subtitle": "يمكن رؤية تقييمك وآرائك من قبل الجميع",

  "product.detailedcomments.alert.nothing": "لم يتم نشر أي تعليقات بعد!",

  "product.filter.alphabetical": "الترتيب الأبجدي :",

  "product.filter.mostvisited": "الترتيب حسب أعلى الدرجات:",

  "product.comment.sendmsg": "تم إرسال تقييمك وتعليقاتك بنجاح!",



  //محادثة

  "chat.chatHeader.setVideoMeeting": "تعيين اجتماع الفيديو",

  "chat.sendBox.placeholder": "اكتب رسالتك هنا",

  "chat.chattitle__subtitle.lastVisit": "آخر زيارة",

  //معرض

  "exhibition.coments.title": "محادثة عامة",

  "exhibition.comments.subtitle": "هذا مكان للحديث عن هذه الشركة.انضم إلى المحادثة الآن",

  "exhibition.comments.subtitle-small": "انضم إلى المحادثة الآن",

  "exhibition.comments.vote.comment1": "على أساس الأصوات",

  //"exhibition.comments.vote.comment2":"Vote",

  "exhibition.comments.toastr": "تم إرسال رسالتك بنجاح",

  "exhibition.coopeationItem.opportunities": "فرصة عمل",

  "exhibition.coopeationItem.opportunitiestwo": "فرصة استثمارية",

  "exhibition.coopeationItem.create": "لتقديم عرض , اكتب وصفًا هنا",

  "exhibition.cooperationItem.loading": "لقد قدمت طلبًا للتعاون",

  "exhibition.cooperationItem.loadingtwo": "تقديم طلب تعاون",

  "exhibition.cooperationItem.loadingThere": "هل تريد إلغاء هذا العرض؟",

  "exhibition.cooperationItem.loadingYes": "نعم , ألغي",

  "exhibition.cooperationItem.loadingNo": "لا",

  "exhibition.cooperationItem.tehrann": "طهران",

  "exhibition.exhibitionlist.title": "قائمة المعارض",

  "exhibition.exhibition.tab1": "مقدمة",

  "exhibition.exhibition.tab2": "أفراد",

  "exhibition.exhibition.tab3": "منتجات",

  "exhibition.exhibition.tab4": "المحادثات",

  "exhibition.exhibition.tab5": "التعاون",

  "exhibition.exhibitionlist.title": "قائمة المعارض",

  "exhibition.members.TableCell1": "الاسم الأول واسم العائلة",

  "exhibition.members.TableCell2": "موقف الوظيفة",

  "exhibition.members.TableCell3": "رقم التليفون",

  "exhibition.members.TableCell4": "البلد",

  "exhibition.members.TableCell6": "الملف الأعضاء",

  "exhibition.members.TableCell5": "",

  "exhibition.rightSide.lable1": "الجميع",

  "exhibition.rightSide.lable2": "فرصة عمل",

  "exhibition.rightSide.lable3": "فرصة استثمارية",

  "exhibition.rightSide.lable3": "فرصة استثمارية",

  "exhibition.rightSide.switchLable": "فرز حسب معظم الطلبات",

  "exhibition.rightSide.selectInput": "نوع التعاون",

  "exhibition.sendBox.placeHolder": "اكتب رسالتك هنا",



  //مقابلة

  "Meeting.meetingDashboard.meetingDashboard.tabLable1": "دعوات",

  "Meeting.meetingDashboard.meetingDashboard.tabLable2": "التقويم",

  "Meeting.meetingDashboard.meetingDashboard.tabPanel": "دعوات",

  "Meeting.meetingDashboard.meetingDashboard.tabPanel23": "Caller",

  "Meeting.meetingDashboard.meetingDashboard.noInventation": "لم يتم إرسال دعوة",

  "Meeting.meetingDashboard.meetingDashboard.Today": "اليوم",

  "Meeting.meetingDashboard.meetingDashboard.noInventationtoday": "لم تتلق أي دعوات اليوم",

  "Meeting.meetingDashboard.meetingDashboard.tabLable11": "قسم الأعضاء",

  "Meeting.meetingDashboard.meetingDashboard.setMeetingText": "لتعيين موعد , حدد عضوًا في قسم الأعضاء 'وانقر على' طلب اجتماع",

  "Meeting.meetingDashboard.meetingInventation.month1": "يناير",

  "Meeting.meetingDashboard.meetingInventation.month2": "فبراير",

  "Meeting.meetingDashboard.meetingInventation.month3": "مارس",

  "Meeting.meetingDashboard.meetingInventation.month4": "أبريل",

  "Meeting.meetingDashboard.meetingInventation.month5": "مايو",

  "Meeting.meetingDashboard.meetingInventation.month 6": "يونيو",

  "Meeting.meetingDashboard.meetingInventation.month 7": "يوليو",

  "Meeting.meetingDashboard.meetingInventation.month 8": "آب",

  "Meeting.meetingDashboard.meetingInventation.month 9": "سبتمبر",

  "Meeting.meetingDashboard.meetingInventation.month10": "أكتوبر",

  "Meeting.meetingDashboard.meetingInventation.month11": "تشرين الثاني (نوفمبر)",

  "Meeting.meetingDashboard.meetingInventation.month12": "ديسمبر",

  "Meeting.meetingDashboard.meetingInventation.status": "مقبول",

  "Meeting.meetingDashboard.meetingInventation.selectedPending": "قيد التحقيق",

  "Meeting.meetingDashboard.meetingInventation.selectedRejected": "مرفوض",

  "Meeting.meetingDashboard.meetingInventation.selectedAccepted": "وافقت",

  "Meeting.meetingDashboard.meetingInventation.inventationText": "دعوة:",

  "Meeting.meetingDashboard.meetingInventation.boxPlace": "مكان:",

  "Meeting.meetingDashboard.meetingInventation.boxContent": "فيديو",

  "Meeting.meetingDashboard.meetingInventation.boxHeading": "المشاركون",

  "Meeting.meetingDashboard.meetingInventation.selectedMeeting": "غير محدد",

  "Meeting.meetingDashboard.meetingInventation.selectedMeeting": "غير محدد",

  "Meeting.meetingDashboard.meetingInventation.selectedDate": "تاريخ",

  "Meeting.meetingDashboard.meetingInventation.loadingAccept": "تعيين وقت جديد",

  "Meeting.meetingDashboard.meetingInventation.loadingAccepttwo": "قبول",

  "Meeting.meetingDashboard.meetingInventation.send_confirm.title": "هل تريد إرسال هذه الدعوة",

  "Meeting.meetingDashboard.meetingInventation.send_confirm.agree": "نعم, وأنا",

  "Meeting.meetingDashboard.meetingInventation.send_confirm.disagree": "لا",

  "Meeting.meetingDashboard.meetingInventation.accept_confirm.title": "هل ترغب في قبول هذه الدورة",

  "Meeting.meetingDashboard.meetingInventation.accept_confirm.agree": "نعم, وأنا أقبل",

  "Meeting.meetingDashboard.meetingInventation.accept_confirm.disagree": "لا",

  "Meeting.meetingDashboard.meetingInventation.reject_confirm.title": "هل أنت متأكد أنك تريد حذف هذه الدورة",

  "Meeting.meetingDashboard.meetingInventation.reject_confirm.agree": "نعم, وأنا نرفض ذلك",

  "Meeting.meetingDashboard.meetingInventation.reject_confirm.disagree": "لا",

  "Meeting.meetingDashboard.meetingInventation.reject_confirm.alert": "أنت غاب عن هذا الاجتماع!",

  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.title": "هل تريد أن تشير إلى هذا الوقت الجديد للاجتماع",

  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.agree": "نعم, أؤكد",

  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.disagree": "لا",

  "Meeting.meetingDashboard.meetingInventation.accept_confirm.alert": "!.يتم تعيين هذه الجلسة بالنسبة لك نحن سوف تتيح لك معرفة قبل بدء الاجتماع 15 دقيقة",

  "Meeting.meetingDashboard.meetingInventation.sendInvitation.emptymsg.alert": "قد نسي نص الدعوة يرجى ملء في نص الدعوة",

  "Meeting.meetingDashboard.meetingInventation.sendInvitation": "إرسال دعوة لاجتماع",

  "Meeting.meetingDashboard.meetingInventation.changetime_confirm.alert": "اقتراحكم لفترة اجتماع جديد تم تقديمه بنجاح.",

  "Meeting.meetingDashboard.meetingInventation.changeTime": "تغيير الوقت",

  "Meeting.meetingDashboard.meetingInventation.changeTimetwo": "إلغاء التغييرات",

  "Meeting.meetingDashboard.meetingInventation.cancellRequest": "إلغاء الطلب",

  "Meeting.meetingDashboard.meetingInventation.alertOne": "لقد قدمت بالفعل طلبًا للتعاون",

  "Meeting.meetingDashboard.meetingInventation.changetimeReject": "رفض",

  "Meeting.meetingDashboard.meetingInventation.statusInvitor": "المتصل",

  "Meeting.meetingDashboard.meetingInventation.cancelInvitation": "إلغاء النشر",

  "Meeting.meetingDashboard.meetingInventation.statusPendingReceive": "في انتظار الاستلام",

  "Meeting.meetingDashboard.meetingInventation.timeNotSet": "غير محدد",

  "Meeting.meetingDashboard.meetingList.tableTitle": "دعوة اجتماع",

  "Meeting.meetingDashboard.meetingList.subtitle": "بعد إرسال الدعوة , يجب الانتظار حتى تتم الموافقة على الاجتماع من قبل الطرف الآخر",

  "Meeting.meetingDashboard.workSheet.item": "جلسة فيديو مع",

  "Meeting.meetingDashboard.meetingInventation.link": "رابط تسجيل الدخول إلى جلسة الفيديو",

  "Meeting.meetingDashboard.meetingInventationCreate.send-alert": "تم إرسال هذه الدعوة بنجاح الرجاء الانتظار للطرف الآخر للرد",

  "Meeting.meetingDashboard.meetingInventationCreate.checkcalender.currentUser": "تم حظر زمنية محددة بالنسبة لك اختيار وقت آخر",

  "Meeting.meetingDashboard.meetingInventationCreate.checkcalender": "الجمهور غير قادر على عقد الاجتماع في الوقت المحدد",



  //فرصة

  "Oppotunity.OppotunityDashboard.searchtitle.job": "التصفية بين فرص العمل",

  "Oppotunity.OppotunityDashboard.searchtitle.investment": "تصفية بين فرص الاستثمار",

  "Oppotunity.allOportunities.jobPosition": "الوظيفة",

  "Oppotunity.allOportunities.investment": "عروض الاستثمار",

  "Oppotunity.allOportunities.company": "الشركة",

  "Oppotunity.allOportunities.City": "المدينة",

  "Oppotunity.allOportunities.Category": "فئة",

  "Oppotunity.companyFilter.category": "مجال النشاط",

  "Oppotunity.companyFilter.alphabetical": "ترتيب أبجدي",

  "Oppotunity.companyFilter.subcategory": "الفئات",

  "Oppotunity.companyFilter.country": "البلد",

  "Oppotunity.memberFilter.subcategory": "الفئات",

  "Oppotunity.companyFilter.country": "البلد",

  "Oppotunity.memberFilter.subcategory": "الفئات",

  "Oppotunity.memberFilter.country": "البلد",

  "Oppotunity.memberFilter.ability": "القدرة",

  "Oppotunity.memberFilter.interest": "الاهتمام المتخصص",

  "Oppotunity.opportunityDashboard.tab1": "كل الفرص",

  "Oppotunity.opportunityDashboard.tab2": "اقتراحاتي",

  "Oppotunity.allOportunities.sendrequest_investment": "فرصة استثمار دولية",

  "Oppotunity.allOportunities.sendrequest_job": "شريك التسويق",

  "Oppotunity.opportunityItem.description": "فيما يلي وصف أكثر تفصيلاً لفرصة الاستثمار ويتم عرض الجوانب المختلفة لفرصة الاستثمار المطلوبة.",

  "Oppotunity.opportunityItem.createDescription": "اكتب وصفك هنا لإرسال اقتراحات",

  "Oppotunity.opportunityDashboard.tabLable1": "كل الفرص",

  "Oppotunity.opportunityDashboard.tabLable2": "اقتراحاتي",

  "Oppotunity.opportunityDashboard.company": "الشركة",

  "Oppotunity.opportunityDashboard.location": "الموقع",

  "Oppotunity.opportunityDashboard.location.tehran": "طهران",

  "Oppotunity.opportunityDashboard.switch": "فرز حسب معظم الاقتراحات",

  "Oppotunity.allOportunities.alert": "ليس لديك اقتراحات الآن!",

  "Oppotunity.sendrequest.requestbox": "لتقديم عرض , اكتب وصفًا هنا",

  "Oppotunity.item.btn-cancelle": "إلغاء الطلب",

  "Oppotunity.item.btn-sendrequest": "تم إرسال الطلب",

  "Oppotunity.item.delete-btn.alert": "تم إلغاء عرضك بنجاح!",

  "Oppotunity.item.btn-sendrequest-alert_title": "",

  "Oppotunity.item.btn-sendrequest-alert_desc_job": 'لإنشاء قسم"فرص عمل"في الكشك , يرجى تنزيل الملف المقابل , وملؤه بالكامل , وتحميل الملف النهائي في القسم التالي."',

  "Oppotunity.item.btn-sendrequest-alert_desc_invesment": 'لإنشاء قسم فرصة الاستثمار في المعرض , يرجى تنزيل الملف المقابل , وتجهيزه بالكامل , وتحميل الملف النهائي في القسم التالي.',

  "Oppotunity.item.btn-sendrequest-alert_download": "تنزيل الملف",

  "Oppotunity.item.btn-sendrequest-upload": "المس هنا وحمل الملف",

  "Oppotunity.item.btn-sendrequest-upload_alertformat": "تنسيق الملف الذي تم تحميله غير معتمد.",

  "Oppotunity.sendrequest.confirm": "هل تريد إرسال هذا العرض؟",

  "Oppotunity.sendrequest.agree": "نعم , سأفعل",

  "Oppotunity.sendrequest.disagree": "لا",





  //الملف الشخصي

  "Profile.profileAvatar.title1": "صورة الملف الشخصي",

  "Profile.profileDetails.profileInfo.h2": "المعلومات الأساسية",

  "Profile.profileDetails.profileInfo.h2.hint": "يتم عرض هذه المعلومات للجميع",

  "Profile.profileDetails.profileInfo.general.offer.h3": "ماذا يمكنني أن أقترح",

  "Profile.profileDetails.profileInfo.general.need.h3": "ما أنا أبحث عن",

  "Profile.profileDetails.profileInfo.general.H3": "ماذا يمكنني أن أقترح",

  "Profile.profileDetails.profileInfo.companyName": "اسم الشركة",

  "Profile.profileDetails.profileInfo.jobPosition": "الوظيفة",

  "Profile.profileEditExtra.textInput.lable1": "المدينة",

  "Profile.profileEditExtra.textInput.lable2": "البريد الإلكتروني",

  "Profile.profileEditExtra.textInput.lable3": "ينكدين",

  "Profile.profileEditExtra.textInput.lable4": "انستغرام",

  "Profile.profileEditExtra.textInput.loading": "حفظ المعلومات",

  "Profile.profileEditExtra.modal.title": "معلومات أساسية",

  "Profile.profileEditExtra.modal.subTitle": "أخرى يمكن أن يرى هذه المعلومات",

  "Profile.profileEditExtra.generalDrawer.title": "",

  "Profile.profileEditExtra.generalDrawer.subTitle": "يمكن للآخرين رؤية هذه المعلومات",

  "Profile.profileEditExtra.addalert": "تم تحديث معلوماتك الأساسية بنجاح.",

  "Profile.profileChangePassword.Modal.title": "تعديل صفحة المستخدم",

  "Profile.profileChangePassword.Modal.subtitle": "إذا نسيت كلمة المرور الحالية , نسيت كلمة المرور",

  "Profile.profileChangePassword.length": "كلمة المرور الصالحة تتكون من 6 أحرف على الأقل",

  "Profile.profileChangePassword.alert.lastpassword": "إن كلمة المرور القديمة غير صحيحة",

  "Profile.profileEditInfo.loading": "حفظ المعلومات",

  "Profile.profileEditInfo.Modal.title": "تحرير معلومات المستخدم",

  "Profile.profileEditInfo.addalert": "تم تحديث معلومات المستخدم الخاصة بك بنجاح.",

  "Profile.profileHeader.button1": "تحرير المعلومات",

  "Profile.profileHeader.button2": "تغيير كلمة المرور",

  "Profile.profilePassword.Modal.subtitle": "إذا نسيت كلمة المرور الحالية , أدخلها في قسم تسجيل الدخول المنسي",

  "Profile.profileuploader.title": "اسحب الصورة وأفلتها في هذه المنطقة",

  "Profile.profileuploader.upload": "تحميل",

  "Profile.profileuploader.delete": "حذف",

  "Profile.profileuploader.uploadimg.alert": "صورة العضو تجديد بنجاح.",

  "Profile.profileuploader.uploadimg.deletealert": "صورة العضو حذف بنجاح",

  "Profile.profileuploader.confirm.deletavatar": "هل تريد حذف بك سيصدره الصورة",

  "Profile.profileuploader.confirm.deletavatar.agree": "نعم, وسوف حذف",

  "Profile.profileuploader.confirm": "هل تريد تحديد هذه الصورة كصورة المستخدم الخاصة بك؟",

  "Profile.profileuploader.agree": "نعم , أؤكد",

  "Profile.profileuploader.disagree": "لا",

  "Profile.profileuploader.save": "حفظ",



  //تصويت

  "votes.btn-submit": "إرسال التعليقات والنقاط",

  "votes.btn-cancel": "إلغاء",



  // الصفحات المشتركة

  "Common.notFound.title": "لا يمكن العثور على الصفحة التي تبحث عنها",





  // الهبوط

  "Landing.firstsection.bar.btn-Register": "يسجل",

  "Landing.firstsection.bar.btn-login": "تسجيل الدخول",

  "Landing.firstsection.bar.btn-exhibition": "عرض قائمة المعارض",
  "Landing.firstsection.bar.btn-online-demo": "طلب تجريبي",

  "Landing.firstsection.bar.btn-lang": "فا",

  "Landing.firstsection.rightside.title": "Namaline ،منصة المعرض على الإنترنت",

  "Landing.firstsection.rightside.desc":"مرحبًا بكم في الجيل الجديد من المعارض. بمساعدة Namaline ، يمكنك إقامة معارضك المادية والافتراضية بذكاء أو المشاركة في المعارض الذكية التي تقام.",
  "Landing.firstsection.rightside1.desc":"مرحبًا بكم في الجيل الجديد من المعارض.",
  "Landing.firstsection.rightside2.desc":"بمساعدة Namaline ، يمكنك إقامة معارضك المادية والافتراضية بذكاء أو المشاركة في المعارض الذكية التي تقام.",



  "Landing.protocol.title": "كيف تعمل؟",

  "Landing.protocol.btngroup.sec1": "الزوار",

  "Landing.protocol.btngroup.sec2": "المنظمون",

  "Landing.protocol.organizer.sec1.title": "إنشاء معرض",

  "Landing.protocol.organizer.sec1.desc": "للبدء , يجب على المنظم إنشاء المعرض. وهو يحدد المعرض وأوقات البدء والانتهاء",

  "Landing.protocol.organizer.sec2.title": "تسجيل الأكشاك",

  "Landing.protocol.organizer.sec2.desc": "يسجل العارض العينة ويدخلها. ثم يحدد التعرفة المرغوبة للمشاركة في المعرض.",

  "Landing.protocol.organizer.sec3.title": "تأكيد الأكشاك",

  "Landing.protocol.organizer.sec3.desc": "ثم يختار مظهر الكابينة الخاصة به ويدخل تفاصيل المقصورة والأعضاء والمنتجات ويدفع الفاتورة.",

  "Landing.protocol.organizer.sec4.title": "افتتاح المعرض",

  "Landing.protocol.organizer.sec4.desc": "عند وقت الافتتاح , سيكون المعرض متاحًا للجميع ويمكن للزوار زيارة المعرض",

  "Landing.protocol.view.sec1.title": "بحث في المعرض",

  "Landing.protocol.view.sec1.desc": "للبدء , يجب على المنظم إنشاء المعرض. وهو يحدد المعرض وأوقات البدء والانتهاء",

  "Landing.protocol.view.sec2.title": "التسجيل وتسجيل الدخول",

  "Landing.protocol.view.sec2.desc": "يسجل العارض العينة ويدخلها. ثم يحدد التعرفة المرغوبة للمشاركة في المعرض.",

  "Landing.protocol.view.sec3.title": "بحث كشك",

  "Landing.protocol.view.sec3.desc": "ثم يختار مظهر الكابينة الخاصة به , ويدخل تفاصيل الكشك , والأعضاء والمنتجات , ويدفع الفاتورة.",

  "Landing.protocol.view.sec4.title": "التفاعل والتواصل",

  "Landing.protocol.view.sec4.desc": "عند الافتتاح , سيكون المعرض متاحًا للجميع ويمكن للزوار زيارة المعرض",

  "Landing.protocol.footer.title-view": "إذا كنت زائرًا , فقم بالتسجيل هنا",

  "Landing.protocol.footer.title-organazer": "إذا كنت منظمًا , فقم بالتسجيل هنا",





  "Landing.Pavilions.title": "قائمة المعارض",
  "Landing.Pavilions.title.login": 'حدد المعرض الذي تريده.',
  "Landing.Pavilions.more": "عرض الكل",

  "Landing.Pavilions.moree": "See",

  "Landing.Pavilions.cardbtn": "شاهد المعرض",

  "Landing.pavilioncard.title.oncoming": "قريبًا",

  "Landing.pavilioncard.title.onregister": "تسجيل",
  "Landing.Pavilions.cardbtn.onregister": 'تسجيل كشك',

  "Landing.pavilioncard.title.onperforming": "عند الأداء",

  "Landing.pavilioncard.title.onpast": "انتهى المعرض",





  "Landing.about.title": "مواصفات المنتج",

  "Landing.about.sec1.title": "تحديد مرئي",

  "Landing.about.sec1.desc": "يمكنك تصميم الكابينة والقاعة وحتى مدينة المعارض من حيث اللون والشكل والحجم , كما يحلو لك.",

  "Landing.about.sec2.title": "فرص التعاون",

  "Landing.about.sec2.desc": "في كشك سوق العمل , يتم عرض فرص عمل الكابينة بحيث يمكنك بسهولة تعيين أو العثور على الوظيفة التي تبحث عنها.",

  "Landing.about.sec3.title": "ورشة عمل ومحاضرة",

  "Landing.about.sec3.desc": "يتم توفير مكان مؤتمرات الفيديو وورش العمل والندوات وأي حدث آخر عبر الإنترنت بسهولة في Namaline.",





  "Landing.appdescription.title": "تجربة جديدة للمشاركة في معرض افتراضي",

  "Landing.appdescription.desc": "ستحصل على تجربة جديدة للمشاركة في المعرض مع Namaline , ويمكن بسهولة قبول وإنهاء استخدام هذا البرنامج على جميع الأجهزة.",

  "Landing.appdescription.btn": "تثبيت البرامج",

  "Landing.video-help.title": "شارك في المعرض ، أذكى من أي وقت مضى!",
  "Landing..video-help.desc": "هل تعلم كيف تبدو المعارض المستقبلية وكيف ستغير تجربة المشاركة في المعرض؟ شاهد هذا الفيديو لتتعرف على الجيل الجديد من المعارض.",

  "Landing.statistics.title": "إحصائيات المعرض",

  "Landing.statistics.sec1.title": "زوار",

  "Landing.statistics.sec2.title": "معرض",

  "Landing.statistics.sec3.title": "عارض",

  "Landing.statistics.sec4.title": "اجتماع",



  "Landing.Customers.title": "عملائنا",



  "Landing.footer.logo": "معرض إيران على الإنترنت",

  "Landing.footer.help": "دليل المستخدم",

  "Landing.footer.about": "نبذة عنا",

  "Landing.footer.contact": "اتصل بنا",

  "Landing.footer.term": "الشروط والأحكام",

  "Landing.footer.namaline": "جميع الحقوق المادية والفكرية لهذا العمل محفوظة لشركة Namaline",



  "Landing.registerform.sec1": "تسجيل الزوار",

  "Landing.registerform.sec2": "تسجيل العارضين",

  "Landing.registerform.sec3": "تسجيل المنظم",

  "Landing.registerform.sec4": "تسجيل الراعي",

  "Landing.loginform.sec1": "تسجيل دخول الزائر / العارض",

  "Landing.loginform.sec2": "مدير كشك تسجيل الدخول",

  "Landing.loginform.sec3": "تسجيل دخول المنظم",

  "Landing.loginform.sec4": "إدخال الراعي",



  //مقابلة

  "Meeting.alert.pending": "معلقة",

  "Meeting.alert.rejected": "مرفوضة",

  "Meeting.alert.accepted": "مقبولة",

  "Meeting.toast.success": "تم إرسال هذه الدعوة بنجاح! الرجاء انتظار رد الطرف الآخر",

  //جنرال لواء

  "General.person": "شخص",

  "General.page": "الصفحة",

  "General.from": 'من',

  "General.alert.toast.errorServer": "مشكلة في الخادم",

  "General.loading.component": "قراءة المعلومات من الخادم...",



  //حول

  "About.header.title": "نبذة عنا",

  "About.header.button": "انضم إلينا",

  "About.header.text": "كل ما تريد معرفته عن فريقنا!",

  "About.tabBox.label1": "دليل المستخدم",

  "About.tabBox.label2": "نبذة عنا",

  "About.tabBox.label3": "اتصل بنا",

  "About.tabBox.label4": "القواعد والشروط",

  "About.sectionOne.title1": "معرض والمزيد من المعرض!",

  "About.sectionOne.title2": "التواصل وتنظيم اجتماعات العمل",

  "About.sectionOne.title3": "والحصول على تقارير الأداء",

  "About.sectionOne.text": "Namaline عبارة عن منصة للمعارض عبر الإنترنت حيث يمكنك ، بالإضافة إلى عرض عملك ومنتجاتك على الأشخاص المعنيين في مجالك ، ترتيب اجتماع عمل مع الزوار ، في قسم فرص العمل ، ابحث عن الأشخاص المناسبين للانضمام إلى الفريق وتقديم تقرير في النهاية احصل على تقرير ذكي عن الزوار وأدائك أثناء المعرض عبر الإنترنت.",

  "About.sectionOne.signup.btn": "تسجيل",



  "About.jobBackground.title": "بدأ كل شيء هنا!",

  "About.jobBackground.text": "بدأ كل شيء في أبريل من عام 1999 ؛ عندما تكون في خضم تفشي المرض  من فيروس كورونا والدخول على الإنترنت للعديد من الأنشطة اليومية والأفكار ، جمع إنشاء البنية التحتية لعقد معارض الدولة عبر الإنترنت زوجين معًا لبدء شركة ناشئة جديدة في سبتمبر 99 لتأسيسها. الغرض من إنشاء هذا هو شيء يتجاوز عقد المعرض عبر الإنترنت ؛ يمكن للمتحمسين في جميع أنحاء البلاد وكذلك الأشخاص الذين يعيشون خارج إيران تجربة أفضل المعارض الدولية في البلاد ، ويتمتع الجميع ، بغض النظر عن الحدود الجغرافية ، بإمكانية زيارة المعرض وعقد اجتماعات عمل مع المنظمات الحالية.",

  "About.team.title": "تعرف على فريقنا!",

  "About.team.text": "الأشخاص الذين يعملون معًا لإقامة معارض عبر الإنترنت بالإضافة إلى تحقيق أهدافهم:",

  "About.team.name.navid": "نوید امیدیان",

  "About.team.name.milad": "ميلاد جعفري",

  "About.team.name.mohammadreza": "محمدرضا مرادی",

  "About.team.name.venus": "ونوس جلالی",

  "About.team.name.majid": 'مجید تقدسی نیا',

  "About.team.name.mohammad": "محمد گروئی",



  "About.team.position.navid": "الشريك المؤسس والرئيس التنفيذي",

  "About.team.position.milad": "مؤسس مشارك وكبير تقني",

  "About.team.position.محمد رضا": "الشريك المؤسس وكبير المنتجين",

  "About.team.position.venus": "مؤسس مشارك وكبير مصممي الجرافيك",

  "About.team.position.majid": "مسؤول العمليات",

  "About.team.position.mohamad": "شريك مؤسس وكبير الأعمال",




  // التعليمات

  "Faq.header.title": "هل لديك سؤال؟",

  "Faq.header.text": "أو شاهد دليل استخدام المنتج",

  "Faq.header.input": "أدخل الكلمة الأساسية هنا",

  "Faq.body.title": "أسئلة مكررة",

  "Faq.body.subtitle": "قد تكون إجابتك هنا!",









  //اتصل

  "Contact.header.title": "اتصل بنا",

  "Contact.header.text": "تواصل سريع مع فريق الدعم",

  "Contact.header.chatbtn": "دردشة عبر الإنترنت",

  "Contact.header.callbtn": 'مكالمة',

  "Contact.body.address": "طهران- شارع كارجار الشمالي- زاوية طريق جلال الأحمد السريع- كلية الهندسة , جامعة طهران",



  //مصطلحات

  "Terms.header.title": "هل تبحث عن موضوع معين؟",

  "Terms.header.text": "أو ابحث في القواعد أدناه",

  "Terms.header.input": "أدخل الكلمة الأساسية هنا",

  "Terms.body.title": "الشروط والأحكام",

  "Terms.body.subtitle": "لاستخدام Namaline",







}