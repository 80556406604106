import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from '../../app/store/actions/asyncActions'
import agent from '../../../src/app/api/agent'

import { delay } from '../../app/common/util/util'

export const INCREMENT_COUNTER = 'INCREMENT_COUNTER';
export const DECREMENT_COUNTER = 'DECREMENT_COUNTER';
export const LOAD_COMPANIES = "LOAD_COMPANIES"

export function increment(amount) {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    try {
      await delay(0);
      dispatch({ type: INCREMENT_COUNTER, payload: amount });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error))
    }
  }
}

export function decrement(amount) {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    try {
      await delay(0);
      dispatch({ type: DECREMENT_COUNTER, payload: amount });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
      console.log(error)
    }
  }
}

export function fetchCompanies() {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    try {
      const response = await agent.Companies.list();

      if (response.status === true)
        dispatch({ type: LOAD_COMPANIES, payload: response.result });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}


const initialState = {
  data: 42,
  companies: []
};

export default function testReducer(state = initialState, { type, payload }) {
  switch (type) {
    case INCREMENT_COUNTER:
      return {
        ...state,
        data: state.data + payload,
      };
    case DECREMENT_COUNTER:
      return {
        ...state,
        data: state.data - payload,
      };
    case LOAD_COMPANIES:
      return {
        ...state,
        companies: [...state.companies, ...payload],
      };
    default:
      return state;
  }
}
