import React from 'react'
import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { changeLanguage } from '../../app/store/actions/changelanguage.js';

function SelectLanguage() {
    const dispatch = useDispatch()
    const { lang } = useSelector((state) => state.lang);

    return (
        <Box mt={5} className="lang">
            <Button
                className={`${lang === "fa" && "active"}`}
                onClick={() => dispatch(changeLanguage("fa"))}
            >فارسی</Button>
            <Typography
                align="center"
                color="textPrimary"
                variant="span"
            >|</Typography>
            <Button
                className={`${lang === "en" && "active"}`}
                onClick={() => dispatch(changeLanguage("en"))}
            >English</Button>
        </Box>
    )
}

export default SelectLanguage
