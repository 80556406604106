import {
    FETCH_CONVERSATION,
    FETCH_MESSAGES,
    ADD_NEW_MESSAGE,
    UPDATE_CONVERSATIONS,
    LOADING_MESSAGE,
    UPDATE_MESSAGES
} from '../constants/Constants';

const initialState = {
    conversations: [],
    messages: [],
    loadingConversation: true,
    loadingMessages: true
};

export default function chatReducer(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_CONVERSATION:
            return {
                ...state,
                conversations: payload,
                loadingConversation: false
            };
        case UPDATE_CONVERSATIONS:
            return {
                conversations: payload,
            };
        case UPDATE_MESSAGES:
            let conversation_megs=[];
            if (state.messages && state.messages.data.length>0){
                let dataMessages = state.messages.data;
                dataMessages[dataMessages.length - 1].messages = (dataMessages[dataMessages.length - 1].messages).concat(payload.data[0].messages);
                conversation_megs={
                    audience: state.messages.audience,
                    data: dataMessages
                }

            }
            else{
                conversation_megs = payload
            }
            return {
                ...state,
                messages: conversation_megs,
            };
            
        case FETCH_MESSAGES:
            return {
                ...state,
                messages: payload,
                loadingMessages: false
            };
        case LOADING_MESSAGE:
            return {
                ...state,
                loadingMessages: true
            };
        case ADD_NEW_MESSAGE:
            let msg = state.messages;
            if (msg.length > 0) {
                let date = msg.data;
                let end = date[date.length - 1];

                end.messages.push(payload)
                return {
                    ...state,
                    messages: msg
                };
            }

        default:
            return state;
    }
}