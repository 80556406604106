import React, { useState } from 'react';
import clsx from 'clsx';
import { fade, makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Drawer, Button, Grid, Typography } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { KeyboardArrowDown, TramRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Popper from '@material-ui/core/Popper';
import { useDispatch } from 'react-redux'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { signoutUser } from '../../app/store/actions/authActions'
import { loadNotificationCounter } from '../../app/store/actions/persistActions';
import agent from '../../app/api/agent'
import ConfirmModal from '../../component/ConfirmModal/ConfirmModal'
import { Modal } from '../../component';
import { setExhibition } from '../../app/store/actions/exhibitionAction';
import VoiceChatIcon from '@material-ui/icons/VoiceChat';
import Message from '@material-ui/icons/Message';
//modal
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
/*************change lang ***************/
import MessageLang, { messageLang } from '../../lang/';
/*************change lang ***************/
import Notification from './Notification'
import Fade from '@material-ui/core/Fade';
import LoginOverlay from '../../component/LoginOverlay/LoginOverlay';


const drawerWidth = 342;

export const useStyles = makeStyles((theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        appBar: {
            height: 56,
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: '#fff',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            boxShadow: 'none',
            color: '#111',
            [theme.breakpoints.up('md')]: {
                '& .MuiToolbar-regular': {
                    minHeight: 56
                }
            },
        },
        menuButton: {
            color: 'white'
        },
        appBarLocation: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: '#2d323e',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            boxShadow: 'none',
            color: '#111',
            [theme.breakpoints.up('md')]: {
                '& .MuiToolbar-regular': {
                    Height: 56
                }
            },
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            height: 56,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },

        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            fontSize: 18,
            fontWeight: 900,
            color: '#2d323e'
        },
        profile: {
            cursor: 'pointer'
        },
        profileMenu: {
            borderRadius: '0 0 4px 4px'
        },
        popper: {
            zIndex: '9999',
            top: '6px!important'
        },
        profileList: {
            width: '175px',
            textAlign: 'center'
        },
        Img: {
            width: 40,
            height: 40,
            borderRadius: '50%',
            marginRight: 8,
            backgroundColor: '#fff',
            [theme.breakpoints.up('sm')]: {
                marginRight: 16,
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        headerstyle: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0px 12px'
        },
        Title: {
            display: 'flex',
            alignItems: 'flex-end',
            // fontSize: '16px',
            fontWeight: 'bold',
            padding: '15px 15px 15px 0px',
            transition: 'all 0.1s ease-in-out',
            cursor: 'pointer',
            // color:'#2D323E',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                transition: 'all 0.3s ease-in-out !important',
            },
            "@media (max-width: 768px)": {
                width: '100%'
            }
        },
        Icon: {
            marginLeft: '5px',
        },
        /*------------------*/
        hide: {
            display: 'none',
        },
        name: {
            paddingLeft: '10px',
            fontSize: '14px',
            fontWeight: 300,
        },
        drawer: {
            width: drawerWidth + 60,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            // width: drawerWidth,
            [theme.breakpoints.up('xs')]: {
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth + 60,
            },
            zIndex: 9999,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            // backgroundColor: theme.palette.primary.main,
            // color: '#fff'
        },
        drawerClose: {
            [theme.breakpoints.up('xs')]: {
                width: 1,
            },
            [theme.breakpoints.up('sm')]: {
                width: 1,
            },
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(0) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(7) + 1,
            },
            // backgroundColor: theme.palette.primary.main,
            // color: '#fff'
        },
        headerActions: {
            registerButton: {
                color: 'gray',
            },
            loginButton: {
            },
            callLink: {
                color: 'gray'
            }
        },
        clearIcon: {
            color: 'white',
            fontSize: '35px',
            margin: '4px 35px 0px 12px',
            cursor: 'pointer'
        },
    }),
);

const Transition = React.forwardRef(function Transition(props, ref) {
    // return <Slide direction="up" ref={ref} {...props} />;
    return <Fade in={true} timeout={1000} ref={ref} {...props} />;
});

const StyledListItemIcon = withStyles({
    root: {
        color: '#rgba(0, 0, 0, 0.87)',
        minWidth: '40px'
    },
})(ListItemIcon);

const Header = ({ open, onOpenChange, title, location, headerIcon, notification, route, short_name, headerBackground }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [placement, setPlacement] = React.useState();
    const { currentUser: user } = useSelector(state => state.auth)

    const isMenuOpen = Boolean(anchorEl);
    const desk = window.innerWidth > 600 ? true : false

    const [open_MiniMenu, setOpen_MiniMenu] = React.useState(false);
    const anchorRef = React.useRef(null);
    const anchorRef_Mobile = React.useRef(null);
    const anchorRef_Exhibition = React.useRef(null);

    const [logoutconfirm, setlogoutconfirm] = useState(false)
    const [loginModalOpen, setLoginModalOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const { lang } = useSelector((state) => state.lang);
    const { list: exlist, data } = useSelector(state => state.exhibition);
    const [openExhibitionList, setOpenExhibitionList] = useState(false);
    const [label, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });
    const { loading } = useSelector((state) => state.async);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleClickShowPassword = () => {
        setValues({ ...label, showPassword: !label.showPassword });
    };
    const dispatch = useDispatch()
    const history = useHistory()

    const logout_func = () => {
        setlogoutconfirm(true)
    }
    const close = () => {
        setlogoutconfirm(exlist)
    }
    const handleClickListener = () => {
        setOpenExhibitionList((prev) => !prev);
    };
    const handleClickAway = () => {
        setOpenExhibitionList(false);
    };
    const changeExhibition = (ex) => {
        dispatch(setExhibition(ex));
        setTimeout(() => {
            history.push(`/${ex.short_name}/home`)
        }, 10);
    }
    const PoperProfile = (
        <MenuList
            role="menu"
            className={`${classes.profileList} minimenu_header`}>
            <MenuItem
                onClick={() => history.push(`/${route}/profile/${user.id}`)}
                className={classes.dropdownItem}
            >
                <StyledListItemIcon>
                    <AccountCircle />
                </StyledListItemIcon>
                <ListItemText primary={<MessageLang id="DashboardLayout.header.profile" />} />
            </MenuItem>
            <MenuItem
                onClick={() => history.push(`/${route}/meeting`)}
                className={classes.dropdownItem}
            >
                <StyledListItemIcon>
                    <VoiceChatIcon />
                </StyledListItemIcon>
                <ListItemText primary={<MessageLang id="DashboardLayout.header.meetings" />} />
            </MenuItem>
            <MenuItem
                onClick={() => history.push(`/${route}/chat`)}
                className={classes.dropdownItem}
            >
                <StyledListItemIcon>
                    <Message />
                </StyledListItemIcon>
                <ListItemText primary={<MessageLang id="DashboardLayout.header.conversations" />} />
            </MenuItem>
            <MenuItem
                // onClick={() => dispatch(signoutUser())}
                onClick={() => logout_func()}
                className={classes.dropdownItem}
            >
                <StyledListItemIcon>
                    <ArrowBackIcon />
                </StyledListItemIcon>
                <ListItemText primary={<MessageLang id="DashboardLayout.header.logout" />} />
            </MenuItem>
        </MenuList>
    )


    const handleToggle = () => {
        setOpen_MiniMenu((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        if (anchorRef_Mobile.current && anchorRef_Mobile.current.contains(event.target)) {
            return;
        }
        setOpen_MiniMenu(false);
    };

    const MiniMenu = () => {
        return (
            <Popper
                className={`$classes.popper MiniMenu`}
                open={open_MiniMenu} anchorEl={anchorRef_Mobile.current}
                transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        id="profile-menu-list-grow"
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper className={classes.profileMenu}>
                            <ClickAwayListener onClickAway={handleClose}>
                                {PoperProfile}
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        )
    }

    const [isOpenNoti, setIsOpenNoti] = useState(false);

    const handelSelectNotification = () => {
        dispatch(loadNotificationCounter)
        agent.Notification.update()
        setIsOpenNoti(val => !val)
    }

    return (
        <div>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar className='header-sticky'>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => onOpenChange()}
                        edge="start"
                        className={`${clsx({ [classes.hide]: open, })} HeaderIcons`}
                    >
                        {/* <img src="/static/images/logo/logoMini.svg" alt="logo" width="30" height="30" /> */}
                        {short_name === 'wevex' ?
                            <img alt="Wevex" src="/static/images/logo/wevexMini.png" width="30" height="30" /> :
                            <img src="/static/images/logo/logoMini.svg" alt="logo" width="30" height="30" />}
                    </IconButton>
                    <Divider orientation="vertical" flexItem className={"Divider_showstate"} />
                    <div className={classes.grow} />
                    <div className={classes.headerstyle}>
                        {location.includes('home') ?
                            <>
                                <Box
                                    data-tut="reactour__iso_b"
                                    alignItems="center"
                                    display="flex"
                                    flexDirection="row"
                                    aria-haspopup="true"
                                    className={`${classes.Title}`}
                                    //
                                    ref={anchorRef_Exhibition}
                                    aria-controls={openExhibitionList ? 'menu-list-grow' : undefined}
                                    // aria-haspopup="true"
                                    onClick={handleClickListener}
                                >
                                    <span className={`${classes.Icon} header_title`}>
                                        <IconButton className={'exhibitionListIcon'}>
                                            <KeyboardArrowDown />
                                        </IconButton>
                                        {title}
                                    </span>
                                </Box>
                                <Popper open={openExhibitionList} anchorEl={anchorRef_Exhibition.current} role={undefined} transition disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClickAway}>
                                                    <div onClick={handleClickListener}>
                                                        {exlist.map((x) =>
                                                            <MenuList id="menu-list-grow">
                                                                <MenuItem onClick={() => changeExhibition(x)} className={'exhibition-list'}>{x.title}</MenuItem>
                                                            </MenuList>
                                                        )}
                                                    </div>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </>
                            :
                            <>
                                <span className={`${classes.Icon} header_title`}>
                                    {title}
                                </span>
                            </>
                        }
                        {!!user || <>
                            <Grid item xs={5} className="landing-header__action">
                                <Button variant="outlined" color="secondary" className='register' onClick={() => history.push('/auth/register')}>
                                    <MessageLang id="Landing.firstsection.bar.btn-Register" />
                                </Button>
                                <Button variant="contained" color="primary" className='login' onClick={() => setOpenDialog(true)}>
                                    <MessageLang id="Landing.firstsection.bar.btn-login" />
                                </Button>
                                <Dialog
                                    open={openDialog}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleCloseDialog}
                                    className={'video-modal'}
                                >
                                    <MuiDialogTitle disableTypography className='modal-title'>
                                        <Typography variant="h6">{ }</Typography>
                                        <IconButton aria-label="close" onClick={handleCloseDialog}>
                                            <CloseIcon />
                                        </IconButton>
                                    </MuiDialogTitle >
                                    <DialogContent>
                                        <LoginOverlay location={location} />
                                    </DialogContent>
                                </Dialog>
                                <IconButton aria-label="" className='menu' onClick={handleToggle} ref={anchorRef}>
                                    <MenuIcon />
                                </IconButton>
                                <Popper open={open_MiniMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={'spacetop_minimenu'}>
                                    {({ TransitionProps, placement }) => (
                                        <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList autoFocusItem={open_MiniMenu} id="menu-list-grow">
                                                        <MenuItem onClick={() => history.push('/auth/login')}><MessageLang id="Landing.firstsection.bar.btn-login" /></MenuItem>
                                                        <MenuItem onClick={() => history.push('/auth/register')}><MessageLang id="Landing.firstsection.bar.btn-Register" /></MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                                </Grid>
                            </>}

                        {user && desk &&
                            <Box
                                data-tut="reactour__iso_b"
                                alignItems="center"
                                display="flex"
                                flexDirection="row"
                                aria-haspopup="true"
                                className={classes.profile}
                                //
                                ref={anchorRef}
                                aria-controls={open_MiniMenu ? 'menu-list-grow' : undefined}
                                // aria-haspopup="true"
                                onClick={handleToggle}
                            >
                                {user.avatar == "" || user.avatar == null ?
                                    <AccountCircle color='action' />
                                    :
                                    <Avatar
                                        className={classes.avatar}
                                        src={user.avatar}
                                    />
                                }

                                <Typography
                                    className={classes.name}
                                    color="textPrimary"
                                >
                                    {user.first_name + ' ' + user.last_name}
                                </Typography>
                                <IconButton>
                                    <KeyboardArrowDown />
                                </IconButton>
                            </Box>
                        }
                        {user && !desk &&
                            <>
                                {user.avatar === "" || user.avatar === null ?
                                    <AccountCircle color='action'
                                        ref={anchorRef_Mobile}
                                        aria-controls={open_MiniMenu ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleToggle} />
                                    :
                                    <Avatar
                                        className={classes.avatar}
                                        src={user.avatar}
                                        // onClick={handleMobileMenuOpen}
                                        ref={anchorRef_Mobile}
                                        aria-controls={open_MiniMenu ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleToggle}
                                    />
                                }
                            </>
                        }
                    </div>
                    {!user ? <></> : <>
                        <Divider orientation="vertical" flexItem />
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            // aria-controls={menuId}
                            aria-haspopup="true"
                            // style={{ marginLeft: '-22px' }}
                            onClick={handelSelectNotification}>
                            <Badge badgeContent={notification.counter.not_seen_signs} max={20} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={isOpenNoti}
                            onClose={() => setIsOpenNoti(false)}
                            className={`${clsx(classes.drawer, classes.root, {
                                [classes.drawerOpen]: isOpenNoti,
                                [classes.drawerClose]: !isOpenNoti,
                            })} dashboard-drawer`}
                            classes={{
                                paper: clsx({
                                    [classes.drawerOpen]: isOpenNoti,
                                    [classes.drawerClose]: !isOpenNoti,
                                }),
                            }}
                        >
                            <Notification data={notification.list} close={() => setIsOpenNoti(false)} />
                        </Drawer>
                    </>}
                </Toolbar>
            </AppBar>
            {logoutconfirm ?
                <ConfirmModal title={messageLang("DashboardLayout.header.logout.confirm")}
                    agree={messageLang("DashboardLayout.header.logout.agree")}
                    disagree={messageLang("DashboardLayout.header.logout.disagree")}
                    close={close}
                    accept={() => dispatch(signoutUser())}
                />
                : null}
            {!!user && open_MiniMenu ? <MiniMenu /> : null}
        </div >
    );
}

export default Header
