import React from 'react'
import Page from '../../component/Page.jsx';
import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core';
import { useStyles } from './_auth.js'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Route, Switch, Redirect } from 'react-router-dom';
import Phone from './ForgetPassStepOne'
import Code from './ForgetPassStepTwo'
import Pass from './ForgetPassStepThree.jsx';
import { useDispatch, useSelector } from 'react-redux'
/*************change lang ***************/
import MessageLang from '../../lang/';
/*************change lang ***************/

export const ForgetPass = ({ match, location }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { lang } = useSelector((state) => state.lang);
    return (
        <Page className={classes.root} title="forget password" >
            <Grid className={classes.authContainer} >
                <Hidden only={['sm', 'xs']}>
                    <Grid item lg={7} md={7} className={'auth-panel'}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            height="100%"
                            justifyContent="center"
                        >
                            <Container maxWidth="md">
                                <Box textAlign="center">
                                    <img
                                        alt="forget password"
                                        className={classes.image}
                                        src="/static/images/backgrounds/SVG_Auth/forget_pass.svg"
                                    />
                                </Box>
                                <Typography
                                    align="center"
                                    color="textPrimary"
                                    variant="h1"
                                    className={'title'}
                                >
                                    <MessageLang id="login.panel.head.title" />
                                </Typography>
                                <Typography
                                    align="center"
                                    color="textPrimary"
                                    variant="subtitle2"
                                    className={'description'}
                                >
                                    <MessageLang id="login.panel.head.text" />
                                </Typography>
                                <Typography
                                    align="center"
                                    color="textPrimary"
                                    variant="subtitle2"
                                    className={'description'}
                                >
                                    <MessageLang id="login.panel.head.welcome" /> ...
                                </Typography>
                            </Container>
                        </Box>
                    </Grid>
                </Hidden>
                <Grid item lg={5} md={5} xs={12} className={classes.LoginPanel} >
                    <TransitionGroup className="transition-group">
                        <CSSTransition
                            key={location.key}
                            // timeout={{ enter: 900, exit: 900 }}
                            enterAnimation="bounceIn"
                            enterDuration="200ms"
                            enterTimingFunction="ease"
                            exitAnimation="fadeOut"
                            exitDuration="150ms"
                            exitTimingFunction="linear"
                            classNames={'fade'}
                        >
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                <Container className='authbox'>
                                    <section className={classes.routeSection}>
                                        <Route
                                            path={`/auth/forgetpass/(.+)`}
                                            render={() => (
                                                <Switch location={location}>
                                                    <Route path={`${match.path}/phone`} component={Phone} />
                                                    <Route path={`${match.path}/code`} component={Code} />
                                                    <Route path={`${match.path}/pass`} component={Pass} />
                                                    <Redirect to={`${match.path}/phone`} />
                                                </Switch>
                                            )} />
                                    </section>
                                </Container>
                            </Box>
                        </CSSTransition>
                    </TransitionGroup>
                </Grid>
            </Grid >
        </Page >
    )
}


