import {
    SET_EXHIBITION,
    SET_EXHIBITIONS
} from '../constants/Constants';

const initialState = {
    data: {
        short_name: "print_package",
        id: "11"
    },
    list: []
};

export default function exhibitionReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_EXHIBITION:
            return {
                ...state,
                data: payload,
            };
        case SET_EXHIBITIONS:
            return {
                ...state,
                list: payload,
            };
        default:
            return state;
    }
}