import React from 'react'

const Dots = ({ stepOne, stepTwo,stepThree}) => {
    return (
        <div className="dots">
            <ul>
                <li className={stepOne ? 'dots__active' : ''}></li>
                <li className={stepTwo ? 'dots__active' : ''}></li>
                <li className={stepThree ? 'dots__active' : ''}></li>
            </ul>

        </div>
    )
}

export default Dots
