import {
    SET_CATEGORIES,
    SET_COMPANIES,
    SET_STANDS,
    SET_POSITIONS,
    SET_COUNTRIES,
    SET_ABILITIES,
    SET_INTERESTS,
    SET_OFFERS,
    SET_DATES,
    SET_EXPOS,
    SET_NotificationCounter,
    SET_NotificationList,
    SET_AddNotificationList
} from "../constants/Constants"

const initialState = {
    categories: [],
    companies: [],
    stands: [],
    positions: [],
    countries: [],
    abilities: [],
    interests: [],
    offers: [],
    dates: [],
    expos: [],
    notification: {
        list: [],
        counter: {
            not_seen_signs: 0,
            new_meetings: 0,
            new_messages: 0
        }
    }
};

export const migrations = {
    1: previousVersionState => ({
        categories: [],
        companies: [],
        stands: [],
        positions: [],
        countries: [],
        abilities: [],
        interests: [],
        offers: [],
        dates: [],
        expos: [],
        notification: {
            list: [],
            counter: {
                not_seen_signs: 0,
                new_meetings: 0,
                new_messages: 0
            }
        }
    })
};

export function persistReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_CATEGORIES:
            return {
                ...state,
                categories: payload
            };
        case SET_COMPANIES:
            return {
                ...state,
                companies: payload
            };
        case SET_STANDS:
            return {
                ...state,
                stands: payload.stands
            }
        case SET_ABILITIES:
            return {
                ...state,
                abilities: payload
            };
        case SET_INTERESTS:
            return {
                ...state,
                interests: payload
            };
        case SET_OFFERS:
            return {
                ...state,
                offers: payload
            }
        case SET_COUNTRIES:
            return {
                ...state,
                countries: payload
            };
        case SET_POSITIONS:
            return {
                ...state,
                positions: payload
            }
        case SET_DATES:
            return {
                ...state,
                dates: payload,
                today: payload.find(date => date.today)
            };
        case SET_EXPOS:
            return {
                ...state,
                expos: payload
            }
        case SET_NotificationCounter:
            return {
                ...state,
                notification: Object.assign(state.notification, { counter: payload })
            }
        case SET_NotificationList:
            return {
                ...state,
                notification: Object.assign(state.notification, { list: payload })
            }
        case SET_AddNotificationList:
            return {
                ...state,
                notification: Object.assign(state.notification, { list: [...state.notification.limit, payload] })
            }
        default:
            return state;
    }
}
