import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardHeader, Typography, Avatar,
  ListItemAvatar, ListItemText, Divider, ListItem, List, IconButton
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import NotificationsIcon from '@material-ui/icons/Notifications';
import CancelIcon from '@material-ui/icons/Cancel';

/*************change lang ***************/
import { messageLang } from '../../lang/';
/*************change lang ***************/

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: `0 ${theme.spacing(2)}px`,
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  content: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
  },
  li: {
    paddingBottom: `${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px 0`,
    "&:last-child": {
      marginBottom: 0,
    }
  },
  inline: {
    display: 'block',
    color: theme.palette.primary.text,
    fontSize: '12px',
    whiteSpace: 'pre-wrap',
    marginBottom: 0,
  },
}));
const Notification = ({ data, close }) => {
  const classes = useStyles();
  let history = useHistory();

  const handelSelect = (e, seen, type) => {
    e.stopPropagation();
    if (!seen) {
      if (type === "Meeting") {
        history.push({
          pathname: "meeting"
        })
      }
      else if (type === "Event") {
        history.push({
          pathname: "events"
        });
      }
      else return;
    }
  }
  return (
    <>
      <CardHeader
        className={classes.head}
        avatar={<NotificationsIcon />}
        title={
          <Typography className={classes.content}>
            <Typography>{messageLang('notification.head')}</Typography>
            <IconButton size="small" color="inherit" onClick={close}><CancelIcon /></IconButton>
          </Typography>
        }
      />
      <List className={classes.root}>
        {data.map((item, key) =>
          <>
            <ListItem key={key} disabled={(item.seen && item.seen === true)} onClick={(e) => handelSelect(e, item.seen, item.signboards)} className={classes.li} alignItems="flex-start" mb={2} width="100%">
              <ListItemAvatar>
                <Avatar src={item?.avatar} />
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <>
                    <Typography
                      component="p"
                      paragraph={true}
                      className={classes.inline}
                    >
                      {item?.message}
                    </Typography>
                    <Typography
                      component="span"
                      variant=""
                      className={classes.inline}
                      align="right"
                    //   color="textPrimary"
                    >
                      {item?.created_at}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            {(data.length - 1) !== key && <Divider component="li" mt={2} />}
          </>
        )}
      </List>
    </>
  )
}
export default Notification;