import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Header from './Header';
import { Route, Switch, Redirect } from 'react-router-dom';
import Register from '../../views/user/Register';
import LoginView from '../../views/user/Login';
import { ForgetPass } from '../../views/user/ForgetPass'
import NotFound from '../../views/common/NotFound';
import TestComponent from '../../views/testarea/TestComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const MainLayout = ({ match, location }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header match={match} location={location} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Route
              path={'/auth/(.+)'}
              render={() => (
                <Switch location={location}>
                  <Route path={`${match.path}/login`} exact component={LoginView} />
                  <Route path={`${match.path}/forgetpass`} component={ForgetPass} />
                  <Route path={`${match.path}/register`} component={Register} />
                  <Route path={`${match.path}/testarea`} exact component={TestComponent} />

                  <Route component={NotFound} />
                </Switch>
              )} />

          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
