import {
    SIGN_IN_USER,
    SIGN_OUT_USER,
    REGISTER_STEP_ONE,
    REGISTER_STEP_TWO,
    SET_AVATAR,
    SET_PROFILE_INFO,
    SET_TOKEN,
    SET_TOUR_GUIDE
} from '../constants/Constants';

const initialState = {
    authenticated: false,
    currentUser: null,
    guestUser: null,
    token: window.localStorage.getItem('jwt'),
    test: null
};

export default function authReducer(state = initialState, { type, payload }) {
    if (!payload && -1 != [SIGN_IN_USER, SET_PROFILE_INFO, REGISTER_STEP_ONE, REGISTER_STEP_TWO].indexOf(type)) return state;
    switch (type) {
        case SIGN_IN_USER:
            return {
                ...state,
                authenticated: true,
                guestUser: null,
                currentUser: {
                    id: payload.id,
                    first_name: payload.first_name,
                    last_name: payload.last_name,
                    phone_number: payload.phone_number,
                    email: payload.email,
                    gender: payload.gender,
                    company_name: payload.company_name,
                    offer_keys: payload.offer_keys,
                    interest_keys: payload.interest_keys,
                    avatar: payload.avatar,
                    tour_guide: payload.tour_guide
                },
            };
        case SET_PROFILE_INFO:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    id: payload.id,
                    first_name: payload.first_name,
                    last_name: payload.last_name,
                    phone_number: payload.phone_number,
                    email: payload.email,
                    gender: payload.gender,
                    company_name: payload.company_name,
                    offer_keys: payload.offer_keys,
                    interest_keys: payload.interest_keys,
                    country: payload.country,
                    state: payload.state,
                    city: payload.city,
                    resume: payload.resume,
                    description: payload.description,
                    job_position: payload.job_position,
                    birth_date: payload.birth_date,
                    social_networks: payload.social_networks,
                    cv_link: payload.cv_link,
                    tour_guide: payload.tour_guide
                },
            }
        case SET_AVATAR:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    avatar: payload
                }
            }
        case SIGN_OUT_USER:
            return {
                ...state,
                authenticated: false,
                currentUser: null,
            };
        case REGISTER_STEP_ONE:
            return {
                ...state,
                guestUser: {
                    first_name: payload.first_name,
                    last_name: payload.last_name,
                    phone_number: payload.phone_number,
                    email: payload.email,
                    state: payload.state,
                }
            }
        case REGISTER_STEP_TWO:
            return {
                ...state,
                guestUser: { ...state.guestUser, code: payload }
            }
        case SET_TOKEN:
            return {
                ...state,
                token: payload
            }
        case SET_TOUR_GUIDE:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    tour_guide: payload.tour_guide
                },
            }
        default:
            return state;
    }
}
